import React from "react";
import {
  Row,
  Col

} from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Modal, Button } from 'react-bootstrap';


class VideoComposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  setShowModal = (showModal) => {
    this.setState({ showModal });
  };


  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });
    return services;
  }

  render() {
    const videoComposition = this.props.data;
    const videoLogo = videoComposition.mcu_logo;
    const videoLabel = videoComposition?.mcu_label?.unique_labels;
    const videoFaces = videoComposition.mcu_face;
    const videoPerson = videoComposition.mcu_people;



    const { showModal } = this.state;

    const ref = React.createRef(null);
    const scroll = (scrollOffset) => {
      ref.current.scrollLeft += scrollOffset;
    };
    return (
      <>
        <Row id="composition" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">COMPOSITION</div>
            </Row>

      

            {/* Logos */}

            <Row>
              <Col className="py-2 text-title" xs="4">Logo</Col>
              <Col className="py-2 text-title dark-background" xs="4">
                SIZE
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                TRACKS
              </Col>
            </Row>
            {videoLogo?.map(obj =>
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>

                    <Col xs="10">{obj.name}

                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {
                    Math.round(obj.size)
                  } %
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {obj.tracks.length}
                </Col>
              </Row>
            )}
            {videoLogo.length == 0 && (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="10">No Logo Detected
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  NA
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  NA
                </Col>
              </Row>
            )}

            {/* Label */}

            <Row>
              <Col className="py-2 text-title" xs="4">Label</Col>
              <Col className="py-2 text-title dark-background" xs="4">
                COUNT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                CONFIDENCE
              </Col>
            </Row>
            {videoLabel && Object.keys(videoLabel)?.map(label =>
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>

                    <Col xs="10">{label}

                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {
                    videoLabel[label].count
                  }
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {Math.round(videoLabel[label].average_confidence * 100)} %
                </Col>
              </Row>
            )}

            <br />
            {/* Faces */}

            <Row>
              <Col className="py-2 text-title" xs="3">Face</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                FACE TOTAL TIME
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                FACE TIME PERCENTAGE
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                AVERAGE CONFIDENCE
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="3" className="py-3">
                <Row>

                  <Col xs="10">{
                    (videoFaces?.total_faces_detected > 0) ? (videoFaces.total_faces_detected) : 'No Face Detected'
                  }
                    {videoFaces?.total_faces_detected > 0 && (
                      <a
                        onClick={() => this.setShowModal(true)}
                      > (More Info) </a>
                    )}
                    <ReactTooltip
                      id={`image-ratio-tooltip-${videoFaces?.total_faces_detected}`}
                      place="bottom"
                      variant="info"
                    >
                      Click for detailed face data
                    </ReactTooltip>

                    {/* Modal for displaying face details */}
                    <Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Face Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h5>Attribute</h5>

                        {videoFaces?.face_data?.map((face, index) => (
                          <div key={index}>
                            <h4>Face {index + 1}</h4>
                            <ul>
              
                              <ul>
                                {Object.entries(face.attributes_average)?.map(([key, value]) => (
                                  <li key={key}>{`${key}: ${Math.round(value * 100)}`}%</li>
                                ))}
                              </ul>
                            </ul>
                          </div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setShowModal(false)}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {
                  (videoFaces?.face_total_time.toFixed(2) > 0) ? (`${videoFaces?.face_total_time.toFixed(2)} secs`) : 'NA'
                }
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {
                  (Math.round(videoFaces?.face_time_percentage) > 0) ? (`${Math.round(videoFaces?.face_time_percentage)} %`) : 'NA'
                }
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {
                  (Math.round(videoFaces?.average_confidence * 100) > 0) ? (`${Math.round(videoFaces?.average_confidence * 100)} %`) : 'NA'
                }
              </Col>
            </Row>
            <br />
            {/* Person */}

            <Row>
              <Col className="py-2 text-title" xs="4">People</Col>
              <Col className="py-2 text-title dark-background" xs="4">
                % Avg Time / Total Time
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                CONFIDENCE
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="10">{
                    (videoPerson?.persons_detected > 0) ? (videoPerson?.persons_detected) : 'No Person Detected'
                  }
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  (Math.round(videoPerson?.person_time_percentage) > 0) ? (`${Math.round(videoPerson?.person_time_percentage)} %`) : 'NA'


                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  (Math.round(videoPerson?.average_confidence * 100) > 0) ? (`${Math.round(videoPerson?.average_confidence * 100)} %`) : 'NA'

                }
              </Col>
            </Row>




          </Col>
        </Row>




      </>
    );
  }
}

export default VideoComposition;

