import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class DomCTA extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    let total_score = 0;
    if (this.props.data.results.CTA_presence?.results?.cta_presence) {
      if (this.props.data.results?.CTA_bold?.results) {
        total_score = total_score + 1;
        if (this.props.data.results.CTA_bold?.results?.correct_cta_bold) {
          score = score + 1;
        }
      }
      if (this.props.data.results?.CTA_number?.results) {
        total_score = total_score + 1;
        if (this.props.data.results.CTA_number?.results?.correct_cta_number) {
          score = score + 1;
        }
      }
      if (this.props.data.results?.CTA_textual?.results) {
        total_score = total_score + 1;
        if (this.props.data.results.CTA_textual?.results?.correct_cta_char) {
          score = score + 1;
        }
      }
      if (this.props.data.results?.CTA_duplicate?.results) {
        total_score = total_score + 1;
        if (
          this.props.data.results.CTA_duplicate?.results?.correct_cta_duplicate
        ) {
          score = score + 1;
        }
      }
      if (this.props.data.results?.CTA_fold?.results) {
        total_score = total_score + 1;
        if (
          this.props.data.results.CTA_fold?.results?.correct_cta_fold
        ) {
          score = score + 1;
        }
      }
      if (this.props.data.results?.CTA_descriptive?.results) {
        total_score = total_score + 1;
        if (
          this.props.data.results.CTA_descriptive?.results?.correct_cta_descriptive
        ) {
          score = score + 1;
        }
      }
    }
    let scorefinal = (total_score !== 0 ? (score / total_score) : 0) * 100;
    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        <InfoModal
          ref={(modal) => {
            this.infoModalRef = modal;
          }}
        ></InfoModal>
        {!this.props.data.results.CTA_presence?.results?.cta_presence ? (
          <>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>
            <Row className="border-row">
              <Col className=" py-3" xs="4">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.CTA_presence?.results
                          ?.cta_presence
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">CTA analysis
                    {this.props.data.results.CTA_presence?.results?.reco_neg?.trim() &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="cta-presence-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="cta-presence-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.CTA_presence?.results?.reco_neg}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3 dark-background" xs="4">
                No CTA detected in the page
              </Col>
              <Col className="py-3 dark-background" xs="4"></Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>

            {this.props.data.results?.CTA_presence?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results?.CTA_presence?.results
                            ?.cta_presence
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA Presence
                      {(this.props.data.results?.CTA_presence?.results?.reco_pos?.trim() || this.props.data.results?.CTA_presence?.results?.reco_neg?.trim()) &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-presenc-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-presenc-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results?.CTA_presence?.results?.cta_presence ?
                        this.props.data.results?.CTA_presence?.results?.reco_pos :
                        this.props.data.results?.CTA_presence?.results?.reco_neg}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                {
                  this.props.data.results?.CTA_presence?.results?.cta_presence
                    ? <Col xs="4" className="py-3 dark-background">
                      CTAs are detected in the page.
                    </Col>
                    : <Col xs="4" className="py-3 dark-background">
                      CTAs are not detected in the page.
                    </Col>
                }
                {
                  this.props.data.results?.CTA_presence?.results?.cta_presence
                    ? <Col xs="4" className="py-3 dark-background">
                    </Col>
                    : <Col xs="4" className="py-3 dark-background">
                      CTAs should be present
                    </Col>
                }
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_bold?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results?.CTA_bold?.results
                            ?.correct_cta_bold
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA boldness
                      {(this.props.data.results?.CTA_bold?.results?.reco_pos_bold?.trim() || this.props.data.results?.CTA_bold?.results?.reco_neg_bold?.trim()) &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-bold-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-bold-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results?.CTA_bold?.results?.correct_cta_bold ?
                        this.props.data.results?.CTA_bold?.results?.reco_pos_bold :
                        this.props.data.results?.CTA_bold?.results?.reco_neg_bold}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                {
                  this.props.data.results?.CTA_bold?.results?.correct_cta_bold
                    ? <Col xs="4" className="py-3 dark-background">
                      All CTAs are bold
                    </Col>
                    :
                    <Col xs="4" className="py-3 dark-background">
                      {(this.props.data.results?.CTA_bold?.results?.not_bold_cta > 1) ?
                        `${this.props.data.results?.CTA_bold?.results?.not_bold_cta} CTAs are not bold` :
                        `1 CTA is not bold`}
                      <div>
                        <a onClick={() => this.infoModalRef.toggle(this.props.data.results.CTA_bold.results.show_not_bold_cta)} className="cta-view-button">
                          View More &gt;&gt;
                        </a>
                      </div>
                    </Col>
                }
                {
                  <Col xs="4" className="py-3 dark-background">
                    CTAs should be bold
                  </Col>
                }
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_number?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_number.results
                            .correct_cta_number
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA Number
                      {(this.props.data.results.CTA_number.results.reco_pos_min?.trim() ||
                        this.props.data.results.CTA_number.results.reco_neg_min?.trim()) &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-number-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-number-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results.CTA_number.results.correct_cta_number ?
                        this.props.data.results.CTA_number.results.reco_pos_min + this.props.data.results.CTA_number.results.reco_pos_max :
                        this.props.data.results.CTA_number.results.reco_neg_min + this.props.data.results.CTA_number.results.reco_neg_max}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {(this.props.data.results.CTA_number.results.number_cta > 1) ?
                    `${this.props.data.results.CTA_number.results.number_cta} CTAs` :
                    `1 CTA`}
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min number of CTAs:{" "}
                      {
                        this.props.data.results.CTA_number.results
                          .min_expected_number
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max number of CTAs:{" "}
                      {
                        this.props.data.results.CTA_number.results
                          .max_expected_number
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_textual?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_textual.results
                            .correct_cta_char
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Textual CTA
                      {!this.props.data.results.CTA_textual.results.correct_cta_char &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-textual-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-textual-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results.CTA_textual.results.correct_cta_char ?
                        this.props.data.results.CTA_textual.results.reco_pos_min + this.props.data.results.CTA_textual.results.reco_pos_max :
                        this.props.data.results.CTA_textual.results.reco_neg_min + this.props.data.results.CTA_textual.results.reco_neg_max}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {this.props.data.results.CTA_textual?.results.too_long_cta ? (
                    <>
                      <Row>
                        <Col>
                          Too long CTAs:{" "}
                          {Math.round(
                            this.props.data.results.CTA_textual.results
                              ?.too_long_cta.length
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Too short CTAs:{" "}
                          {Math.round(
                            this.props.data.results.CTA_textual.results
                              ?.too_short_cta.length
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min number of characters :{" "}
                      {
                        this.props.data.results.CTA_textual.results
                          .min_expected_char
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max number of characters:{" "}
                      {
                        this.props.data.results.CTA_textual.results
                          .max_expected_char
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_duplicate?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_duplicate.results
                            .correct_cta_duplicate
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Duplicate CTA
                      {(this.props.data.results.CTA_duplicate.results.reco_pos_duplicate?.trim() ||
                        this.props.data.results.CTA_duplicate.results.reco_neg_duplicate?.trim()) &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-duplicate-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-duplicate-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results.CTA_duplicate.results.correct_cta_duplicate ?
                        this.props.data.results.CTA_duplicate.results.reco_pos_duplicate :
                        this.props.data.results.CTA_duplicate.results.reco_neg_duplicate}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {(this.props.data.results.CTA_duplicate.results.number_duplicate > 0) ?
                    `${this.props.data.results.CTA_duplicate.results.number_duplicate} duplicated CTAs` :
                    `No duplicated CTA`}
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  Good to have duplicated CTAs
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results.CTA_fold?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_fold.results
                            .correct_cta_fold
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA Above the fold</Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {this.props.data.results.CTA_fold?.results.number_cta_fold ? (
                    <>
                      <Row>
                        <Col>
                          Number of CTAs above the fold:{" "}
                          {Math.round(
                            this.props.data.results.CTA_fold.results
                              ?.number_cta_fold
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col>
                        No CTAs above the fold detected
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  <Row>
                    <Col>There should be a CTA above the fold</Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results.CTA_descriptive?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_descriptive.results
                            .correct_cta_descriptive
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Descriptive CTA
                      {(this.props.data.results?.CTA_descriptive?.results?.reco_pos_descriptive?.trim() ||
                        this.props.data.results?.CTA_descriptive?.results?.reco_neg_descriptive?.trim()) &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id="cta-descriptive-tooltip"
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId="cta-descriptive-tooltip"
                        place="bottom"
                        variant="info"
                      >{this.props.data.results.CTA_descriptive.results?.correct_cta_descriptive ?
                        this.props.data.results?.CTA_descriptive?.results?.reco_pos_descriptive :
                        this.props.data.results?.CTA_descriptive?.results?.reco_neg_descriptive}
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  {this.props.data.results.CTA_descriptive?.results
                    .number_non_descriptive > 0 ? (
                    <>
                      <Row>
                        <Col>
                          Number of non descriptive CTAs:{" "}
                          {Math.round(
                            this.props.data.results.CTA_descriptive.results
                              ?.number_non_descriptive
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col>
                        All CTAs are descriptive
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs="4" className="py-3 dark-background">
                  <Row>
                    <Col>CTAs should be descriptive</Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
}

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      modal: false,
      data: ''
    };
  }
  toggle(data) {
    this.setState({ modal: !this.state.modal, data: data });
  }
  render() {
    const temp = this.state.data;
    return (
      <div>
        {
          temp?.length > 0 &&
          <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle} className="cta-modal">
            <ModalHeader toggle={this.toggle}>
              CTA Boldness
            </ModalHeader>
            <ModalBody>
              <div className="cta-modal-header row">
                <div className="col-4 cta-modal-first"><b>Text</b></div>
                <div className="col-6 cta-modal-second"><b>Link</b></div>
                <div className="col-2"><b>Location</b></div>
              </div>
              {
                temp.map((each) => {
                  return (
                    <div className="cta-modal-data row">
                      <div className="col-4 cta-modal-first">{each.text}</div>
                      <div className="col-6 cta-modal-second">{each.href}</div>
                      <div className="col-2">x: {Math.round(each.location.x)}<br />y: {Math.round(each.location.y)}</div>
                    </div>
                  )
                })
              }
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    );
  }
}

export default DomCTA;