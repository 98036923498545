import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class VideoMessage extends React.Component {
  constructor(props) {
    super(props);
    // this.generateScore();
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  generateScore() {

    let scorefinal =
      // ((this.props.data.correct_colorspace.results.score +
      //   this.props.data.correct_file_extension.results.score +
      //   this.props.data.correct_image_resolution.results.score +
      //   this.props.data.correct_aspect_ratio.results.score) /
      //   4) *
      // 100;

      ((this.props.data.correct_colorspace.results.score +
        this.props.data.correct_image_resolution.results.score +
        (this.props.data.correct_aspect_ratio?.results?.score | 0)) /
        3) *
      100;

    this.props.generateScore(scorefinal);
  }

  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });
    return services;
  }

  render() {
    const videoMessage = this.props.data;
    const transcript = videoMessage.mcu_transcription;
    const copywriting = videoMessage.mcu_copywriting;
    const combinedTranscription = transcript.ParsedData.reduce((acc, curr) => {
      if (curr.transcription.trim() !== "") {
        acc.transcription += (acc.transcription !== "" ? " " : "") + curr.transcription;
        acc.totalConfidence += curr.confidence;
      }
      return acc;
    }, { transcription: "", totalConfidence: 0 });



    console.log("transcript", transcript)
    return (
      <>
        <Row id="message" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">MESSAGE</div>
            </Row>




            {/* Transict */}

            <Row>
              <Col className="py-2 text-title" xs="9">Transcription</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                CONFIDENCE SCORE
              </Col>

            </Row>

            <Row className="border-row">
              <Col xs="9" className="py-3">
                <Row>
                  <Col xs="12">{combinedTranscription.transcription ? combinedTranscription.transcription : 'No Message'}
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {
                  (Math.round((combinedTranscription.totalConfidence / transcript.ParsedData.length) * 100)) ? `${Math.round((combinedTranscription.totalConfidence / transcript.ParsedData.length) * 100)} %` : 'NA'
                }
              </Col>
            </Row>

            {/* copywriting */}

            <Row>
              <Col className="py-2 text-title" style={{ gridRow: 'span 2' }} xs="3">Copywriting</Col>
              <Col className="py-2 text-title dark-background" xs="6">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDATION
              </Col>

            </Row>



            <Row className="border-row">
              <Col xs="3" className="py-3">
                Wordliness
              </Col>
              <Col xs="6" className="py-3 dark-background">
                <Row>
                  <Col xs="6">Too Short: {Math.round(copywriting?.metrics_data.average_too_short.averageTooShort * 100)} %
                  </Col>
                  <Col xs="6">Normal: {Math.round(100 - copywriting?.metrics_data.average_too_short.averageTooShort * 100)} %
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">

              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="3" className="py-3">
                Conciseness
              </Col>
              <Col xs="6" className="py-3 dark-background">
                <Row>
                  <Col xs="6">Too Long: {Math.round(copywriting?.metrics_data.average_too_long.averageTooLong * 100)} %
                  </Col>
                  <Col xs="6">Normal: {Math.round(100 - copywriting?.metrics_data.average_too_long.averageTooLong * 100)} %
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">

              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="3" className="py-3">
                Text Duration
              </Col>
              <Col xs="6" className="py-3 dark-background">
                <Row>
                  <Col xs="6">Average: {Math.round(copywriting?.metrics_data.average_duration.averageTextDuration * 100)} %
                  </Col>
                  <Col xs="6">Normal: {Math.round(100 - copywriting?.metrics_data.average_duration.averageTextDuration * 100)} %
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">

              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="3" className="py-3">
                Mistakes
              </Col>
              <Col xs="6" className="py-3 dark-background">
                <Row>
                  <Col xs="6">Average: {Math.round(copywriting?.metrics_data.average_mistake.averageMistakes * 100)} %
                  </Col>
                  <Col xs="6">Normal: {Math.round(100 - copywriting?.metrics_data.average_mistake.averageMistakes * 100)} %
                  </Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">

              </Col>
            </Row>


          </Col>
        </Row>
      </>
    );
  }
}

export default VideoMessage;

