const channels = [
  {
    pretty_name: "Facebook",
    name: "facebook",
    available_for: ["social", "banner"]
  },
  {
    pretty_name: "Instagram",
    name: "instagram",
    available_for: ["social"]
  },
  {
    pretty_name: "TikTok",
    name: "tiktok",
    available_for: ["social"]
  },
  {
    pretty_name: "Twitter",
    name: "twitter",
    available_for: ["social"]
  },
  {
    pretty_name: "B2B",
    name: "B2B",
    available_for: ["webpage", "email"]
  },
  {
    pretty_name: "B2C",
    name: "B2C",
    available_for: ["webpage", "email"]
  },
  {
    pretty_name: "Google",
    name: "google",
    available_for: ["social", "banner"]
  },
  {
    pretty_name: "Amazon",
    name: "amazon",
    available_for: ["shelf"]
  },
  {
    pretty_name: "Ebay",
    name: "ebay",
    available_for: ["shelf"]
  },
  {
    pretty_name: "Taobao",
    name: "taobao",
    available_for: ["shelf"]
  },
  {
    pretty_name: "Walmart",
    name: "walmart",
    available_for: ["shelf"]
  },
  {
    pretty_name: "Carrefour",
    name: "carrefour",
    available_for: ["shelf"]
  },
];

export default channels;
