import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Edit from "../task/Edit";
import Share from "../task/Share";
import Delete from "../task/Delete";
import {
    TabContent,
    TabPane,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    Button,
    CardText,
} from "reactstrap";
import { Link } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import ContentLoader from "react-content-loader";
import TaskCard from "../task/TaskCard";
import { ROOT_URL } from "../../constants";
import NewLoader from "../loader/NewLoader";
class TeamsView extends React.Component {
    constructor(props) {
        super(props);
        //this.public = this.props.public;
        this.public = window.location.pathname.split(/[/]+/).pop()
        if (this.public !== 'public') {
            // this.project = this.props.match.params.id;
            this.project = window.location.pathname.split(/[/]+/).pop();
            this.public = false;
        } else {
            this.public = true;
        }

        this.state = {
            teams: false,
            tasks: false,
            getTaskStatus: true,
            offset: 1,
            project: { team: { _id: null }, name: "" },
        };
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);

        clearTimeout(this.timeout);
    }

    componentDidMount() {

        const { dispatch } = this.props;

        axios
            .get(`${ROOT_URL}/api/teams/getforcurrentuser`)
            .then((res) => {
                this.setState({ teams: res.data.teams });
            })
            .catch((err) => {
                this.props.history.push("/admin/teams");
            });
        dispatch(
            setPageTitle(
                "My Teams"
            )
        );
    }
    handleScroll() {
        const windowHeight =
            "innerHeight" in window
                ? window.innerHeight
                : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
        const totalCount = this.state.project.dom_cnt + this.state.project.image_cnt + this.state.project.video_cnt
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= 0.9 * docHeight) {
            if (totalCount > this.state.tasks.length && this.state.getTaskStatus == true) {
                this.setState((prevState) => ({
                    offset: prevState.offset + 1,
                    getTaskStatus: false
                }), this.getMore);
            }
        }
    }

    async getMore() {
        var project = this.project;
        if (this.state.tasks != false) {
            var url = this.public
                ? `${ROOT_URL}/api/tasks/getforpublic`
                : `${ROOT_URL}/api/tasks/getperproject`;

            axios
                .get(url, {
                    params: {
                        id: project,
                        offset: this.state.offset - 1,
                    },
                })
                .then((res) => {
                    this.setState((prevState) => ({

                        // offset: prevState.offset + 1,
                        tasks: [...prevState.tasks, ...res.data.tasks],
                        getTaskStatus: true,
                    }));
                })
                .catch((err) => {
                    this.props.history.push("/home");
                });
        }


    }

    render() {

        return (
            <div className="content">

                <Helmet>
                    <title>My Teams</title>
                </Helmet>
                {/* <FontAwesomeIcon
                    icon="arrow-left"
                    className="prev clickable"
                    onClick={() => window.location.replace("/teams/" + this.state.project.team._id)}
                    title="Back"
                ></FontAwesomeIcon> */}
                <Edit
                    ref={(modal) => {
                        this.editRef = modal;
                    }}
                    history={this.props.history}
                    access={this.props.access}
                    team={this.state.project.team._id}
                ></Edit>
                <Share
                    ref={(modal) => {
                        this.shareRef = modal;
                    }}
                ></Share>

                <Delete
                    ref={(modal) => {
                        this.deleteRef = modal;
                    }}
                ></Delete>

                {this.state.teams === false ? (
                    <NewLoader />
                ) : this.state.teams.length === 0 ? (
                    <h3 className="text-center">There is no Teams here.</h3>
                ) : (
                    <div>
                        <Row>
                            {this.state.teams.map((task) => {
                                return (
                                    <Col xs="19"
                                        tag={Link} to={`/teams/${task.team._id}`}
                                    >
                                        <Card className={"teamCard task-"}>
                                            <CardBody>
                                                {/* <div className=" mt-3">
                                                    <span className="task-title">
                                                        <a className="quiet-a" href="/projects/">Name</a>{" "}
                                                </span>
                                            </div> */}
                                                <div>
                                                    <img className="teamsICon" src='./img/teams.png' />
                                                </div>
                                                <div>
                                                    <span className="bold task-title">
                                                        {task.team.name}
                                                    </span>
                                                </div>
                                                <div className="mt-2 analyzed-url-label2">
                                                    <span key={1} className="teamDescription truncated-url" title={task.team.description} >
                                                        {task.team.description} 
                                                    </span>
                                                </div>
                                                <div className="date-card mt-3 teamDescription">
                                                    {task.team.projects.length} Projects
                                                </div>
                                            </CardBody>
                                            {/* <div style={{ position: "relative" }}>
                                            <div>
                                                <div className="info-task">
                                                    <FontAwesomeIcon icon="info"></FontAwesomeIcon>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <span className="title-info-task"> Type</span> <br></br>
                                                            <span className="not-specified">Not specified</span>
                                                            <hr>
                                                            </hr>
                                                            <span className="title-info-task">Industry</span>
                                                            <br></br>
                                                            <span className="not-specified"> Not specified</span>
                                                        </Col>
                                                        <Col xs={6} className="text-right bordered-col">
                                                            <span className="title-info-task"> Geography</span>
                                                            <br></br>
                                                            <span className="not-specified"> Not specified</span>
                                                            <hr>
                                                            </hr>
                                                            <span className="title-info-task"> Channel</span> <br></br>
                                                            <span className="not-specified"> Not specified</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <a className={"cardimg-task-container"} onClick={() => {
                                                }}
                                                    href={"/tasks/"}
                                                >
                                                    <LazyLoadImage
                                                        placeholderSrc={"data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                                                        } onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = "/img/loading.gif";
                                                        }}
                                                        effect="blur"
                                                        className="card-img-results"
                                                    />
                                                </a>
                                            </div>
                                        </div> */}
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                )
                }
            </div>
        );
    }
}

const MyLoader = (props) => (
    <ContentLoader
        width={1500}
        height={800}
        viewBox="0 0 1200 575"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="2" ry="2" width="250" height="250" />
        <rect x="300" y="0" rx="2" ry="2" width="250" height="250" />
        <rect x="600" y="0" rx="2" ry="2" width="250" height="250" />
        <rect x="900" y="0" rx="2" ry="2" width="250" height="250" />

        <rect x="12" y="300" rx="2" ry="2" width="250" height="250" />
        <rect x="300" y="300" rx="2" ry="2" width="250" height="250" />
        <rect x="600" y="300" rx="2" ry="2" width="250" height="250" />
        <rect x="900" y="300" rx="2" ry="2" width="250" height="250" />
    </ContentLoader>
);

const mapStateToProps = (state) => ({
    resource_token: state.resource_token,
    access: state.auth.user.access,
    auth: state.auth,

});


export default connect(mapStateToProps)(TeamsView);
