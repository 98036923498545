import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class VideoFormats extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  generateScore() {
    let scorefinal =
       Math.round(((this.props.data.correctCodec?1:0) +
        (this.props.data.correctDuration?1:0 )+
        (this.props.data.correctRatio?1:0 )+
        (this.props.data.correctResolution?1:0) +
        (this.props.data.correctformat?1:0 )  +
        (this.props.data.correctframerate?1:0) +
        (this.props.data.color_space===this.props.data.recommended_colourspace?1:0)
        ) * 100/7) ;

    this.props.generateScore(scorefinal);
  }

  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });

    return services;
  }

  render() {
    return (
      <>
        <Row id="format" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">FORMAT</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>
           {/* Aspect Ration  */}

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctRatio
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Aspect Ratio
              
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.aspect
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Acceptable aspect ratio are '1:1', '4:5', '2:3', '16:9', '9:16', '1.9:1'
                {/* {this.props.data.recommended_aspectratio ? this.convertAspectRatio(
                  this.props.data.recommended_aspectratio
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                }) : ''} */}
              </Col>
            </Row>
           {/* Dimension  */}

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctResolution
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Dimensions
                  
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
          
                {
                  this.props.data.resolution
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video dimension should have minimum width 600 and minimum height 315(600X315)
               {/* {this.props.data.recommended_dimension} */}
              </Col>
            </Row>
          
          
           {/* Format  */}
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctformat
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Format
              
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.format
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Acceptable video formats are MP4, MKV, MOV, AVI, MPEG & MPG
               {/* {this.props.data.recommended_format} */}
              </Col>
            </Row>

             {/* Colorspace  */}
             <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        (this.props.data.color_space===this.props.data.recommended_colourspace?true:false)
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Colorspace
                 
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.color_space
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Acceptable video colourspace is RGB
               {/* {this.props.data.recommended_colourspace} */}
              </Col>
            </Row>

             {/* Duration  */}
             <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctDuration
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Duration
                  
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.duration
                } Seconds
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video length should be within 30 to 90 seconds
               {/* {this.props.data.recommended_duration} */}
              </Col>
            </Row>

              {/* Codec  */}
              <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctCodec
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Codec
                 
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.codec
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Acceptable video codec are 'h264', 'HEVC', 'Xvid', 'MPEG-1'
                {/* {this.props.data.recommended_codec ? this.convertAspectRatio(
                  this.props.data.recommended_codec
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                }) : ''} */}
              </Col>
            </Row>

             {/* Frame Rate  */}
             <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correctframerate
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Frame Rate
                
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                (this.props.data.framerate).toFixed(2)
                } fps
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video framerate should be within 30fps to 60 fps
               {/* {this.props.data.recommended_framerate} */}
              </Col>
            </Row>
            

          </Col>
        </Row>
      </>
    );
  }
}

export default VideoFormats;

