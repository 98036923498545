/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Row, Col, FormGroup, Label, Input, Tooltip } from "reactstrap";
import striptags from "striptags";
import industries from "../../variables/industries";
import geographies from "../../variables/geographies";
import channels from "../../variables/channels";
import types from "../../variables/types";
import axios from "axios";
import ErrorJSON from "../utils/Error";
import { connect } from "react-redux";
import { ROOT_URL } from "../../constants";
import json_access from "../../variables/access";

class FormCreate extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendFiles = this.sendFiles.bind(this);
    this.state = {
      modal: false,
      teams: [],
      submitted: false,
      tasks: [],
      errors: false,
      industry: "",
      type: "",
      public: false,
    };
  }

  componentDidMount() {
    axios
      .get(`${ROOT_URL}/api/teams/getforcurrentuser`)
      .then((res) => {
        this.setState({ teams: res.data.teams });
      })
      .catch((err) => {
      window.location.href = "/login";

      });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    if (nam === "team") {
      var project = this.state.teams.filter((team) => team.team._id === val)[0]
        .team.projects[0]?._id;

      if (!project) {
        project = "create";
      }
      this.setState({
        [nam]: striptags(val),
        project: project,
        public: this.state.teams.filter((team) => team.team._id === val)[0].team
          .public,
      });
    } else {
      this.setState({ [nam]: striptags(val) });
    }
  }

  handleCheck(value) {
    this.setState((prevState) => {
      const tasks = prevState.tasks;

      if (tasks.indexOf(value) !== -1) {
        tasks.splice(tasks.indexOf(value), 1);
      } else {
        tasks.push(value);
        // if (value == "experience") {
        //   if (!prevState.tasks.includes("memorability")) {
        //     tasks.push("memorability");
        //   }
        //   if (!prevState.tasks.includes("report")) {
        //     tasks.push("report");
        //   }
        // }
        // if (value == "report") {
        //   if (!prevState.tasks.includes("memorability")) {
        //     tasks.push("memorability");
        //   }
        // }
      }

      return { tasks: tasks };
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });

    if (
      this.state.tasks.indexOf("report") !== -1 &&
      (this.state.industry === "" || this.state.type === "")
    ) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    if (this.state.project === "create") {
      axios
        .post(`${ROOT_URL}/api/projects/create`, {
          name: this.state.project_name,
          team: this.state.team,
        })
        .then((res) => {
          this.setState({ project: res.data._id }, this.sendFiles);
        })
        .catch((err) => {
          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    } else {
      this.sendFiles();
    }
  }

  sendFiles() {
    this.props.files.map((file) => {
      let taskArray = this.state.tasks
       taskArray =  (taskArray.includes("report") && file.media == 'video')?(taskArray.map(item => item !== "report" ? item : "videopredictivedesign")):taskArray
      axios
        .post(`${ROOT_URL}/api/tasks/create`, {
          ...this.state,
          tasks: taskArray,
          method: this.props.method,
          extension: file.ext,
          upload_id: file.id,
          url: this.props.method === "url" ? file.name : "",
          label: file.name,
          media: file.media,
        })
        .then((res) => {
          window.location.href = "/projects/" + res.data.project;
        })
        .catch((err) => {
          this.setState({ submitted: false });

          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    });

    this.setState({ errors: false });
  }

  isProjectBBVA(project_id) {
    let isBBVA = false;
    this.state.teams.forEach((team) => {
      if(team.team._id == this.state.team) {
        if(team.team.name == "BBVA"){
          isBBVA = true
        }
        team.team.projects.forEach((project) => {
          if (project._id === project_id) {
            if(project.name == "BBVA")
            isBBVA = true;
          }
        });
      }
    });
    return isBBVA;
  }

  notFinished() {
    var files = this.props.files;
    for (var i = 0; i < files.length; i++) {
      if (files[i].progress !== 100) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <form
        onClick={(e) => e.stopPropagation()}
        className={this.props.className + "form-create p-5"}
        onSubmit={this.handleSubmit}
      >
        {this.state.errors ? (
          <ErrorJSON json={this.state.errors}></ErrorJSON>
        ) : (
          <span></span>
        )}

        <Row>
          <Col>
            <FormGroup>
              <Input
                onChange={this.handleChange}
                required
                type="select"
                name="team"
                id="exampleSelect"
              >
                <option disabled selected value="">
                  Select a team
                </option>
                {this.state.teams.map((team) => {
                  return (
                    <option
                      key={team.team._id}
                      public={(team.team.public).toString()}
                      value={team.team._id}
                    >
                      {team.team.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>

          {this.state.public ? (
            <span></span>
          ) : (
            <Col>
              <FormGroup>
                <Input
                  onChange={this.handleChange}
                  required
                  type="select"
                  name="project"
                  id="exampleSelect"
                >
                  <option disabled selected value="">
                    Select a project
                  </option>

                  <option
                    selected={this.state.project === "create"}
                    value="create"
                  >
                    Create a new project
                  </option>
                  {this.state.teams.map((team) => {
                    if (team.team._id === this.state.team) {
                      return team.team.projects.map((project, idx) => {
                        return (
                          <option
                            key={project._id}
                            selected={idx === 0 ? true : false}
                            value={project._id}
                          >
                            {project.name}
                          </option>
                        );
                      });
                    }
                  })}
                </Input>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col></Col>
          {this.state.project === "create" ? (
            <Col>
              <FormGroup>
                <Input
                  name="project_name"
                  onChange={this.handleChange}
                  placeholder="Project name"
                ></Input>
              </FormGroup>
            </Col>
          ) : (
            <span></span>
          )}
        </Row>
        {/* <p className="mt-3">
          If you choose to generate a full Creativity Intelligence report,
          please specify the following:
        </p> */}
        <hr></hr>
        <Row>
          <Col>
            <FormGroup>
              <Input onChange={this.handleChange} type="select" name="type">
                <option disabled selected value="">
                  Select the type of content
                </option>
                {types.map((type) => {
                  if (
                    type.available_for.indexOf(this.props.files[0]?.media) !==
                    -1
                  ) {
                    return (
                      <option key={type.name} value={type.name}>
                        {type.pretty_name}
                      </option>
                    );
                  }
                })}
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Input onChange={this.handleChange} type="select" name="industry">
                <option disabled selected value="">
                  Select the industry
                </option>

                {industries.map((industry) => {
                  return (
                    <option key={industry.name} value={industry.name}>
                      {industry.pretty_name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Input
                onChange={this.handleChange}
                type="select"
                name="geography"
              >
                <option disabled selected value="">
                  Select the geography
                </option>
                {geographies.map((geo) => {
                  return (
                    <option key={geo.name} value={geo.name}>
                      {geo.pretty_name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Input onChange={this.handleChange} type="select" name="channel">
                <option disabled selected value="">
                  Select the channel
                </option>
                {/* {channels.map((channel) => {
                    return (
                      <option key={channel.name} value={channel.name}>
                        {channel.pretty_name}
                      </option>
                    );
                  })} */}
                {channels.map((channel) => {
                  if (channel.available_for.indexOf(this.state.type) !== -1) {
                    return (
                      <option key={channel.name} value={channel.name}>
                        {channel.pretty_name}
                      </option>
                    );
                  }
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr></hr>
        {/* <small>
          For the moment, only Visual Attention Prediction is available for
          videos.
        </small> */}

        <FormGroup className="form-create-check" check>
          {Object.keys(json_access).map((access) => {
            return (
              <Tooltiped
                id={access}
                text={
                  this.props?.access?.indexOf(access) === -1
                    ? "This feature is only available for upgraded accounts."
                    : "This feature is not available for this type of content."
                }
                disabled={
                  this.props?.access?.indexOf(access) === -1 ||
                  this.props.files.every(
                    (file) => json_access[access].disableType?.includes(file.media)
                  )
                }
              >
                <Label className="mr-5" check>
                  <Input
                    onChange={() => this.handleCheck(access)}
                    type="checkbox"
                    checked={
                      // this.state.tasks.indexOf("report") !== -1 ||
                      this.state.tasks.indexOf(access) !== -1
                      // ||  this.state.tasks.indexOf("experience") !== -1
                    }
                    disabled={
                      // this.state.tasks.indexOf("report") !== -1 ||
                      // this.state.tasks.indexOf("exeperience") !== -1 ||
                      this.props?.access?.indexOf(access) === -1 ||
                      this.props.files.every(
                        (file) => json_access[access].disableType?.includes(file.media)
                      )
                    }
                  />{" "}
                  <span
                    className={
                      "form-check-sign " +
                      //   this.state.tasks.indexOf("report") !== -1 ||
                      // this.state.tasks.indexOf("exeperience") !== -1 ||
                      (this.props?.access?.indexOf(access) === -1 ||
                      this.props.files.every(
                        (file) =>json_access[access].disableType?.includes(file.media)
                      )
                        ? "disabled"
                        : "")
                    }
                  >
                    <span className="check"></span>
                  </span>
                  <span
                    className={
                      this.props?.access?.indexOf(access) === -1 ||
                      this.props.files.every(
                        (file) => json_access[access].disableType?.includes(file.media)
                      )
                        ? "disabled-txt"
                        : ""
                    }
                  >
                    {json_access[access].pretty}
                  </span>
                </Label>
              </Tooltiped>
            );
          })}

          {/* <Tooltiped
            id="visualattention"
            text="This feature is only available for upgraded accounts."
            disabled={this.props?.access?.indexOf("vco") === -1}
          >
            <Label className="mr-5" check>
              <Input
                onChange={() => this.handleCheck("vco")}
                type="checkbox"
                checked={this.state.tasks.indexOf("vco") !== -1}
                disabled={this.props?.access?.indexOf("vco") === -1}
              />{" "}
              <span className="form-check-sign">
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("vco") === -1 ? "disabled-txt" : ""
                }
              >
                Visual Attention Prediction{" "}
              </span>
            </Label>
          </Tooltiped>

          <Tooltiped
            id="memorability"
            text={
              this.props?.access?.indexOf("memorability") === -1
                ? "This feature is only available for upgraded accounts."
                : "This feature is not available for this type of content."
            }
            disabled={
              this.props?.access?.indexOf("memorability") === -1 ||
              this.props.files.every((file) => file.ext === "mp4")
            }
          >
            <Label className="mr-5" check>
              <Input
                onChange={() => this.handleCheck("memorability")}
                type="checkbox"
                checked={
                  // this.state.tasks.indexOf("report") !== -1 ||
                  this.state.tasks.indexOf("memorability") !== -1
                  // ||  this.state.tasks.indexOf("experience") !== -1
                }
                disabled={
                  // this.state.tasks.indexOf("report") !== -1 ||
                  // this.state.tasks.indexOf("exeperience") !== -1 ||
                  this.props?.access?.indexOf("memorability") === -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                }
              />{" "}
              <span
                className={
                  "form-check-sign " +
                  (this.state.tasks.indexOf("report") !== -1 ||
                  this.state.tasks.indexOf("exeperience") !== -1 ||
                  this.props?.access?.indexOf("memorability") === -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                    ? "disabled"
                    : "")
                }
              >
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("memorability") === -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                    ? "disabled-txt"
                    : ""
                }
              >
                Memorability Prediction
              </span>
            </Label>
          </Tooltiped>

          <Tooltiped
            id="report"
            text={
              this.props?.access?.indexOf("report") === -1
                ? "This feature is only available for upgraded accounts."
                : "This feature is not available for this type of content."
            }
            disabled={
              this.props?.access?.indexOf("report") === -1 ||
              this.props.files.every((file) => file.ext === "mp4")
            }
          >
            <Label className="mr-5 mt-3" check>
              <Input
                onChange={() => this.handleCheck("report")}
                type="checkbox"
                disabled={
                  this.props?.access?.indexOf("report") === -1 ||
                  this.state.tasks.indexOf("experience") !== -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                }
                checked={
                  this.state.tasks.indexOf("experience") !== -1 ||
                  this.state.tasks.indexOf("report") !== -1
                }
              />{" "}
              <span
                className={
                  "form-check-sign " +
                  (this.props?.access?.indexOf("report") === -1 ||
                  this.state.tasks.indexOf("experience") !== -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                    ? "disabled"
                    : "")
                }
              >
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("report") === -1 ||
                  this.props.files.every((file) => file.ext === "mp4")
                    ? "disabled-txt"
                    : ""
                }
              >
                Predictive Design
              </span>
            </Label>
          </Tooltiped>

          <Tooltiped
            id="experience"
            text={
              this.props?.access?.indexOf("experience") === -1
                ? "This feature is only available for upgraded accounts."
                : "This feature is not available for this type of content."
            }
            disabled={
              this.props?.access?.indexOf("experience") === -1 ||
              !this.props.files.every((file) => file.media === "dom")
            }
          >
            <Label className="mr-5 mt-3" check>
              <Input
                onChange={() => this.handleCheck("experience")}
                type="checkbox"
                disabled={
                  this.props?.access?.indexOf("experience") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                }
              />{" "}
              <span
                className={
                  "form-check-sign " +
                  (this.props?.access?.indexOf("experience") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                    ? "disabled"
                    : "")
                }
              >
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("experience") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                    ? "disabled-txt"
                    : ""
                }
              >
                Page Experience Report
              </span>
            </Label>
          </Tooltiped>

          <Tooltiped
            id="ibmaccessibility"
            text={
              this.props?.access?.indexOf("ibmaccessibility") === -1
                ? "This feature is only available for upgraded accounts."
                : "This feature is not available for this type of content."
            }
            disabled={
              this.props?.access?.indexOf("ibmaccessibility") === -1 ||
              !this.props.files.every((file) => file.media === "dom")
            }
          >
            <Label className="mr-5 mt-3" check>
              <Input
                onChange={() => this.handleCheck("ibmaccessibility")}
                type="checkbox"
                disabled={
                  this.props?.access?.indexOf("ibmaccessibility") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                }
              />{" "}
              <span
                className={
                  "form-check-sign " +
                  (this.props?.access?.indexOf("ibmaccessibility") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                    ? "disabled"
                    : "")
                }
              >
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("ibmaccessibility") === -1 ||
                  !this.props.files.every((file) => file.media === "dom")
                    ? "disabled-txt"
                    : ""
                }
              >
                Accessibility Report
              </span>
            </Label>
          </Tooltiped>

          <Tooltiped
            id="accessibility"
            text={
              this.props?.access?.indexOf("accessibility") === -1
                ? "This feature is only available for upgraded accounts."
                : "This feature is not available for this type of content."
            }
            disabled={
              this.props?.access?.indexOf("accessibility") === -1 ||
              !(
                this.props.files.every((file) => file.media === "dom") &&
                this.state.type === "email"
              )
            }
          >
            <Label className="mr-5 mt-3" check>
              <Input
                onChange={() => this.handleCheck("accessibility")}
                type="checkbox"
                disabled={
                  this.props?.access?.indexOf("accessibility") === -1 ||
                  !(
                    this.props.files.every((file) => file.media === "dom") &&
                    this.state.type === "email"
                  )
                }
              />{" "}
              <span
                className={
                  "form-check-sign " +
                  (this.props?.access?.indexOf("accessibility") === -1 ||
                  !(
                    this.props.files.every((file) => file.media === "dom") &&
                    this.state.type === "email"
                  )
                    ? "disabled"
                    : "")
                }
              >
                <span className="check"></span>
              </span>
              <span
                className={
                  this.props?.access?.indexOf("accessibility") === -1 ||
                  !(
                    this.props.files.every((file) => file.media === "dom") &&
                    this.state.type === "email"
                  )
                    ? "disabled-txt"
                    : ""
                }
              >
                Email Accessibility Report
              </span>
            </Label>
          </Tooltiped>

          {this.isProjectBBVA(this.state.project) ? (
            <Tooltiped
              id="bbva"
              text={
                this.props?.access?.indexOf("report") === -1
                  ? "This feature is only available for upgraded accounts."
                  : "This feature is not available for this type of content."
              }
              disabled={
                this.props?.access?.indexOf("report") === -1 ||
                !(
                  this.props.files.every((file) => file.media === "dom") &&
                  this.state.type === "webpage"
                )
              }
            >
              <Label className="mr-5 mt-3" check>
                <Input
                  onChange={() => this.handleCheck("bbva")}
                  type="checkbox"
                  disabled={
                    this.props?.access?.indexOf("report") === -1 ||
                    !(
                      this.props.files.every((file) => file.media === "dom") &&
                      this.state.type === "webpage"
                    )
                  }
                />{" "}
                <span
                  className={
                    "form-check-sign " +
                    (this.props?.access?.indexOf("report") === -1 ||
                    !(
                      this.props.files.every((file) => file.media === "dom") &&
                      this.state.type === "webpage"
                    )
                      ? "disabled"
                      : "")
                  }
                >
                  <span className="check"></span>
                </span>
                <span
                  className={
                    this.props?.access?.indexOf("report") === -1 ||
                    !(
                      this.props.files.every((file) => file.media === "dom") &&
                      this.state.type === "webpage"
                    )
                      ? "disabled-txt"
                      : ""
                  }
                >
                  BBVA Report
                </span>
              </Label>
            </Tooltiped>
          ) : (
            <></>
          )} */}
        </FormGroup>

        <div className="text-right mt-4">
          <Button onClick={() => this.props.cancel()} color="secondary">
            Cancel
          </Button>{" "}
          <Button
            disabled={
              this.state.submitted ||
              this.notFinished() ||
              !this.state.tasks.length ||
              this.state.industry === "" ||
              this.state.type === ""  ||
              !this.state.geography ||
              this.state.channel === ""
            }
            color="primary"
            type="submit"
          >
            Submit
          </Button>{" "}
        </div>
      </form>
    );
  }
}

const Tooltiped = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={props.id}>{props.children}</span>
      {props.disabled === true ? (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={props.id}
          toggle={toggle}
        >
          {props.text}
        </Tooltip>
      ) : (
        <span></span>
      )}
    </span>
  );
};

const mapStateToProps = (state) => ({
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(FormCreate);