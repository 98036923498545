import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class VideoSentiment extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    const sentiment = this.props.data.mcu_sentiment;
    return (
      <>
        <Row id="usability" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">SENTIMENT</div>
            </Row>
          
            <Row>
              <Col className="py-2 " xs="6">Positive</Col>
              <Col className="py-2  dark-background" xs="6">
              {Math.round(sentiment?.positive)} %
              </Col>
            </Row>
        
              <Row className="border-row">
                <Col xs="6" className="py-3">
                  <Row>
                    <Col xs="12"> Negative
                    </Col>
                  </Row>
                </Col>
                <Col xs="6" className="py-3  dark-background">
                 {Math.round(sentiment?.negative)} %
                </Col>
              </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default VideoSentiment;

