import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Home extends React.Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      window.location.href = "/home";
    } else {
      window.location.href = "/login";
    }
  }

  render() {
    return "";
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
