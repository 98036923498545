import React from "react";
import {
  Card,
  CardBody
} from "reactstrap";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import { connect } from "react-redux";
import { setCurrentUser } from "../../actions/authActions";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { Box, Button, Snackbar, TextField, Alert } from "@mui/material";
import NavbarPublic from "./Navbar";
import {ROOT_URL} from "../../constants"

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.loginAction = this.loginAction.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      errors: false,
      open: false,
      emailError: false,
      pwdError: false
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      window.location.replace("/");
    }
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  loginAction(event) {
    let tempError = false;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    this.setState({ open: false });
    this.setState({
      errors: false,
      emailError: false,
      pwdError: false
    });
    const { dispatch } = this.props;
    if (!data.get('email')?.trim()) {
      this.setState({ emailError: true });
      tempError = true;
    }
    if (!data.get('password')?.trim()) {
      this.setState({ pwdError: true });
      tempError = true;
    }
    !tempError && axios
      .post(`${ROOT_URL}/api/public/login`, {
        email: data.get('email'),
        password: data.get('password'),
      })
      .then((res) => {
        const { token } = res?.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        window.location.replace("/");
      })
      .catch((err) => {
        this.setState({ open: true });
        this.setState({ errors: err?.response?.data?.message || err.message });
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>CRISP v2</title>
        </Helmet>
        <Layout location={this.props.location}>
          <div className="login-logo-container">
            <img src="/img/Accenture_logo_purple.svg" className="login-logo-style"></img>
          </div>
          <Card className="card-login">
            <CardBody className=" mt-1">
              <div className="text-center">
                <Button onClick={() => (window.location.href = "/connect/azure")} className="center-btn" type="submit" variant="contained">
                  Accenture Login
                </Button>
              </div>
              <hr className="white-hr"></hr>
              <div className="mt-4">
                <span className="login-text">Using non-Accenture email</span>
                <Box component="form" noValidate onSubmit={this.loginAction} sx={{ mt: 1 }} className="form-login">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    autoComplete="off"
                    error={this.state.emailError}
                    helperText={this.state.emailError && "Email is required"}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="pwd"
                    variant="standard"
                    error={this.state.pwdError}
                    helperText={this.state.pwdError && "Password is required"}
                  />
                  <div className="text-center">
                    <Button type="submit" variant="contained" className="center-btn">
                      Login
                    </Button>
                  </div>
                </Box>
              </div>
            </CardBody>
          </Card>
          <NavbarPublic location={this.props.location}></NavbarPublic>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={this.state.open}
            onClose={this.handleClose}
          >
            <Alert
              onClose={this.handleClose}
              severity="error"
              variant="filled"
            >
              {this.state.errors}
            </Alert>
          </Snackbar>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(Login);