import React from "react";

import axios from "axios";
import {
  Badge,
  Card,
  CardBody,
} from "reactstrap";
import SubActions from "../utils"
import DataTable from "react-data-table-component";
import moment from "moment";

import { connect } from "react-redux";
import { setPageTitle } from "../../../actions/titleActions";
import { Helmet } from "react-helmet";
import { ROOT_URL } from "../../../constants";

class IndexUser extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = {
      users: [],
      filtered: [],
    };

    this.columns = [
      {
        name: "Name",
        selector: (row) => row.full_name,
        cell: (row) => <b>{row.full_name}</b>,
        sortable: true,
        center: true,
      },
      {
        name: "Job title",
        cell: (row) => {
          return (<div className=" analyzed-url-label2" >
            <span
              className="truncated-url"
              title={row.job_title}
            >
              {row.job_title}
            </span>
          </div>)
        },
        selector: "job_title",
        sortable: true, 
        center: true,
      },
      {
        name: "Last login",
        selector: (row) => moment(row.last_login).unix(),
        cell: (row) => moment(row.last_login).format("lll"),
        sortable: true,
        center: true,
      },
      {
        name: "Number of tasks",
        selector: "task_cnt",
        sortable: true,
        center: true,
      },
      {
        name: "Membership",
        center: true,
        selector: (row) => {
          var el = [];

          if (row.admin) {
            el.push(
              <Badge className="mr-2" color="info">
                ADMIN
              </Badge>
            );
          }

          if (row.teams.length == 0) {
            el.push(<Badge color="primary">TRIAL</Badge>);
            return el;
          }

          if (
            row.teams.every(function (t) {
              return t.leader == false;
            })
          ) {
            el.push(<Badge color="secondary">TEAM MEMBER</Badge>);
          } else {
            el.push(<Badge color="success">TEAM LEADER</Badge>);
          }

          return el;
        },
        sortable: true,
      },
    ];
  }

  search(event) {
    var keyword = event.target.value;
    var filteredData = this.state.users.filter(function (obj) {
      return (
        obj["full_name"].toLowerCase().includes(keyword.toLowerCase())
      );
    });
    this.setState({ filtered: filteredData });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Users", "user-friends"));
    axios
      .get(`${ROOT_URL}/api/admin/users/getusers`)
      .then((res) => {
        this.setState({ users: res.data, filtered: res.data });
      })
      .catch((err) => {
            window.location.replace("/home");
      });
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>CRISP - Users</title>
        </Helmet>
        <div className="w-100 text-right"></div>
        <Card>
          <CardBody>
            <DataTable
              title="Users"
              pagination={true}
              columns={this.columns}
              data={this.state.filtered}
              subHeader={true}
              striped={true}
              className="clickable-rows"
              onRowClicked={(row) =>
                window.location.replace("/admin/users/" + row._id)
              }
              subHeaderComponent={
                <SubActions value={this.state.value} onChange={this.search} />
              }
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}



export default connect()(IndexUser);
