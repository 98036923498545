import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import industries from "../../../variables/industries";
import geographies from "../../../variables/geographies";
import channels from "../../../variables/channels";
import types from "../../../variables/types";
import jsonReport from '../../../out.json';
import ReportViewer from '../../../lighthouse-viewer/src';
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class LighthouseTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonReport: null,
      error: null,
      isLoading: true
    }
    this.token = this.props.token;
  }

  componentDidUpdate() {
    if (this.token !== this.props.token) {
      this.token = this.props.token;
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    if (this.token) {
      axios.get(`/output/${this.props.task.upload_id}/pageexperience/output.json`,{
          headers: {
            'Cache-Control': 'no-cache'
        }
      })
        .then(res => {
          this.setState({ jsonReport: res.data , isLoading: false })
    setTimeout(()=>{
      const groupSummaryTabs = document.querySelectorAll('.lh-metrics-toggle__input');
      for (const tab of groupSummaryTabs) {
        tab.click(); 
      }
    },2000)
        }).catch(err => {
          this.setState({ error: 'Error fetching JSON file.', isLoading: false  });
        })
    }
  }

  render() {
 

    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    const { isLoading, error, jsonReport } = this.state;

    if (isLoading) {
      return <div>Loading...</div>; // Display a loading indicator
    }
    if (error) {
      return <div>Error: {error}</div>; // Display an error message
    }
    if(jsonReport){
      return (
        <Row>
          <Col sm="12">
            {!task.analysis ? (
              <span></span>
            ) : (
              <div className="mt-4 px-4">
  
                <Row>
  
                  <Col>
                    {(jsonReport.lighthouseVersion) ? (<ReportViewer json={jsonReport}></ReportViewer>) : (<span>Report Is Not Generated {error}</span>)}
  
                  </Col>
                </Row>
  
              </div>
            )}
          </Col>
        </Row>
      );
    }
  
  }
}



export default LighthouseTab;
