import React from "react";
const admin_codes = ({
  code,
  owner,
  user,
  team,
  task,
  creation,
  project,
  feedback,
  info,
}) => {
  const arr = {
    task_share: () => {
      return {
        color: "yellow",
        pretty: "Task sharing",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            shared a task in the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    task_edit: () => {
      return {
        color: "info",
        pretty: "Task edition",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            edited a task in the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    task_editblock: () => {
      return {
        color: "info",
        pretty: "Task block edition",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            edited the block {info} for the task in the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    project_edit: () => {
      return {
        color: "info",
        pretty: "Project edition",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            edited the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    team_edit: () => {
      return {
        color: "info",
        pretty: "Team edition",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            edited the team <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>
            .
          </span>
        ),
      };
    },
    apikey_edit: () => {
      return {
        color: "info",
        pretty: "API key edit",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            edited the API key ({info}).
          </span>
        ),
      };
    },
    task_create: () => {
      return {
        color: "success",
        pretty: "Task creation",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            created a task in the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    project_create: () => {
      return {
        color: "success",
        pretty: "Project creation",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            created a project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    team_create: () => {
      return {
        color: "success",
        pretty: "Team creation",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            created the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ),
      };
    },
    apikey_create: () => {
      return {
        color: "success",
        pretty: "API key creation",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            created an API key ({info}).
          </span>
        ),
      };
    },
    new_feedback: () => {
      return {
        color: "secondary",
        pretty: "Feedback",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            added a feedback.
          </span>
        ),
      };
    },
    task_delete: () => {
      return {
        color: "danger",
        pretty: "Task deletion",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            deleted a task in the project{" "}
            <a href={`/projects/${project?._id}`}>{project?.name}</a>.
          </span>
        ),
      };
    },
    project_delete: () => {
      return {
        color: "danger",
        pretty: "Project deletion",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            deleted the project {project?.name}.
          </span>
        ),
      };
    },
    delete_team: () => {
      return {
        color: "danger",
        pretty: "Team deletion",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            deleted the team {team?.name}.
          </span>
        ),
      };
    },
    team_delete: () => {
      return {
        color: "danger",
        pretty: "Team deletion",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            deleted the team {team?.name}.
          </span>
        ),
      };
    },
    apikey_delete: () => {
      return {
        color: "danger",
        pretty: "API Key deletion",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            deleted an API key ({info}).
          </span>
        ),
      };
    },
    user_activate: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User activation" : "User deactivation",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            {info === "true" ? "activated" : "deactivated"} the account of{" "}
            <a href={`/admin/users/${user?._id}`}>
              {user?.full_name}
            </a>{" "}
            .
          </span>
        ),
      };
    },
    user_promote: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User promote" : "User demote",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            {info === "true" ? "promoted" : "demoted"} the account of{" "}
            <a href={`/admin/users/${user?._id}`}>
              {user?.full_name}
            </a>{" "}
            .
          </span>
        ),
      };
    },
    block_team: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User promote" : "User demote",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            {info === "true" ? "blocked" : "deblocked"} the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a> .
          </span>
        ),
      };
    },
    register_sso: () => {
      return {
        color: "primary",
        pretty: "SSO registration",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            registered through SSO.
          </span>
        ),
      };
    },
    register_request: () => {
      return {
        color: "primary",
        pretty: "Registration request",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            sent a registration request.
          </span>
        ),
      };
    },
    team_add_member: () => {
      return {
        color: "success",
        pretty: "Team new member",
        content: !user ? (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            added {info} to the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ) : (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            added{" "}
            <a href={`/admin/users/${user?._id}`}>
              {user?.full_name}
            </a>{" "}
            {info === "true" ? "(leader)" : ""} to the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ),
      };
    },
    team_remove_member: () => {
      return {
        color: "danger",
        pretty: "Team member removal",
        content: user ? (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            removed{" "}
            <a href={`/admin/users/${user?._id}`}>
              {user?.full_name}
            </a>{" "}
            of the team <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ) : (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            removed {info} of the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ),
      };
    },
    toggle_leader: () => {
      return {
        pretty: info === "true" ? "success" : "danger",
        pretty: info === "true" ? "Leader addition" : "Leader removal",
        content: (
          <span>
            <a href={`/admin/users/${owner?._id}`}>
              {owner?.full_name}
            </a>{" "}
            {info === "true" ? "added" : "removed"}{" "}
            <a href={`/admin/users/${user?._id}`}>
              {user?.full_name}
            </a>{" "}
            as a leader of the team{" "}
            <a href={`/admin/teams/${team?._id}`}>{team?.name}</a>.
          </span>
        ),
      };
    },
  };

  return arr[code]();
};

export default admin_codes;

