import React from "react";
import {
  Row,
  Col,
  Progress
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class DomTone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      InitialResultsError: '',
      toneResults: null,
      InitialResults: null
    };
  }

  componentDidMount() {
    this.countToxicEntries();
  }
  countToxicEntries() {
    let results;
    let initialResults;
    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl?.results?.tone_result;
      results = initialResults?.results?.tone_result;

    }
    else {
      initialResults = this.props.dataImage?.results?.tone_prompt;
      results = initialResults?.results?.results?.tone_result;
    }



    if (results) {
      this.setState({
        toneResults: results[0],
        InitialResults: initialResults
      });
    } else {

      if (this.props.media === 'dom') {
        initialResults = initialResults
      }
      else {
        if (initialResults?.status === 'finished') {
          initialResults = initialResults.results

        }
      }
      if (initialResults?.status === 'error') {
        this.setState({ InitialResults: initialResults })

        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          toneResults: null,
          InitialResultsError: words?.join(" ")
        });
      }

    }
  }

  render() {
    const { toneResults, InitialResults, InitialResultsError } = this.state;
    const angryValue = parseInt(toneResults?.Angry?.replace('%', '') || 0, 10);
    const Confused = parseInt(toneResults?.Confused?.replace('%', '') || 0, 10);
    const Serious = parseInt(toneResults?.Serious?.replace('%', '') || 0, 10);
    const Sad = parseInt(toneResults?.Sad?.replace('%', '') || 0, 10);
    const Concerned = parseInt(toneResults?.Concerned?.replace('%', '') || 0, 10);

    const negativeTotal = angryValue + Sad + Confused + Serious + Concerned;

    const Excited = parseInt(toneResults?.Excited?.replace('%', '') || 0, 10);
    const Happy = parseInt(toneResults?.Happy?.replace('%', '') || 0, 10);
    const Neutral = parseInt(toneResults?.Neutral?.replace('%', '') || 0, 10);
    const Playful = parseInt(toneResults?.Playful?.replace('%', '') || 0, 10);

    const positiveTotal = Excited + Happy + Neutral + Playful;

    return (
      <>

        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="12">
            Tone
          </Col>
          <Col className="score-color-palet">
            <span>Sentiment Score: &nbsp;</span>
            <span className="dom-score-color score-color-one">Very Positive</span>
            <span className="dom-score-color score-color-two">Positive</span>
            <span className="dom-score-color score-color-three">Neutral</span>
            <span className="dom-score-color score-color-four">Negative</span>
            <span className="dom-score-color score-color-five">Very Negative</span>
          </Col>
        </Row>
        <Row>
          <Col className="py-2 text-title dark-background" xs="8">
            <Col xs="4">
              RESULT   
              {(toneResults?.Result === 'No text detected' || InitialResults?.status === 'error') ? null :
              <Col xs="6" className={
                positiveTotal > 80
                  ? "score-color-scale score-color-one"
                  : positiveTotal > 60
                    ? "score-color-scale score-color-two"
                    : positiveTotal > 40
                      ? "score-color-scale score-color-three"
                      : positiveTotal > 20
                        ? "score-color-scale score-color-four"
                        : "score-color-scale score-color-five"
              }>
              </Col>}
            </Col>
          </Col>
          <Col className="py-2 text-title dark-background" xs="4">
            RECOMMENDED
          </Col>
        </Row>
        {(toneResults && toneResults?.Result !== 'No text detected') ? (
          <>
            <Row className="border-row">
              <Col className=" py-3 dark-background" xs="4">
                <Row>
                  <Col xs="8 text-title">Positive Sentiments </Col>
                  <Col xs="3">{positiveTotal}%</Col>
                </Row>
                <Row>
                  <Col xs="8">Excited</Col>
                  <Col xs="4">{Excited} %</Col>
                </Row>
                <Row>
                  <Col xs="8">Happy</Col>
                  <Col xs="4">{Happy} %</Col>
                </Row>
                <Row>
                  <Col xs="8">Neutral</Col>
                  <Col xs="4">{Neutral} %</Col>
                </Row>
                <Row>
                  <Col xs="8">Playful</Col>
                  <Col xs="4">{Playful} %</Col>
                </Row>


              </Col>
              <Col className="py-3 dark-background" xs="4">
                <>
                  <Row>
                    <Col xs="8 text-title">Negative Sentiments </Col>
                    <Col xs="3">{negativeTotal} %</Col>

                  </Row>
                  <Row>
                    <Col xs="8">Angry</Col>
                    <Col xs="4">{angryValue} %</Col>

                  </Row>
                  <Row>
                    <Col xs="8">Sad</Col>
                    <Col xs="4">{Sad} %</Col>

                  </Row>
                  {/* <Row>
                    <Col xs="8">Sarcastic</Col>
                    <Col xs="4">{Sarcastic} %</Col>

                  </Row> */}
                  <Row>
                    <Col xs="8">Serious</Col>
                    <Col xs="4">{Serious} %</Col>

                  </Row>
                  <Row>
                    <Col xs="8">Confused</Col>
                    <Col xs="4">{Confused} %</Col>

                  </Row>
                  <Row>
                    <Col xs="8">Concerned</Col>
                    <Col xs="4">{Concerned} %</Col>
                  </Row>





                </>
              </Col>
              <Col className="py-3 dark-background" xs="4">
                <Row> <Col> Good to have 100% for Positive Sentiments</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>




              </Col>

            </Row>
          </>
        ) :
          (
            <Row >
              <Col className="py-2 dark-background" xs="8">
                <>
                {(toneResults?.Result === 'No text detected') ? 'No text detected' :
                  (InitialResults?.status === 'error') ? (
                    InitialResultsError || 'Unknown error'
                  ) : (
                    'No Tone words detected'
                  )}

                </>
              </Col>
              <Col className="py-3 dark-background" xs="4">
                <Row> <Col> Good to have 100% for Positive Sentiments</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>
                <Row> <Col> &nbsp;</Col></Row>




              </Col>
            </Row>
          )
        }

      </>

    );
  }
}



export default DomTone;