import React, { useState } from "react";
import Layout from "./Layout";
import { Card, CardBody } from "reactstrap";
import { Box, TextField, Button, Grid, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import NavbarPublic from "./Navbar";
import {ROOT_URL} from "../../constants";

const Register = (props) => {
  const [success, setSuccess] = useState(false);
  const [errors, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [fnError, setfnError] = useState(false);
  const [lnError, setlnError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [jobError, setjobError] = useState(false);
  const [pwdError, setpwdError] = useState(false);
  const [cpwdError, setcpwdError] = useState(false);
  const [orgError, setorgError] = useState(false);
  const [cityError, setcityError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const registerAction = (event) => {
    let tempError = false;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setError(false);
    setfnError(false);
    setlnError(false);
    setemailError(false);
    setjobError(false);
    setpwdError(false);
    setcpwdError(false);
    setorgError(false);
    setcityError(false);
    setOpen(false);
    if (!data.get('firstName')?.trim()) {
      setfnError(true);
      tempError = true;
    }
    if (!data.get('lastName')?.trim()) {
      setlnError(true);
      tempError = true;
    }
    if (!data.get('email')?.trim()) {
      setemailError(true);
      tempError = true;
    }
    if (!data.get('jobTitle')?.trim()) {
      setjobError(true);
      tempError = true;
    }
    if (!data.get('password')?.trim()) {
      setpwdError(true);
      tempError = true;
    }
    if (!data.get('confirmPW')?.trim()) {
      setcpwdError(true);
      tempError = true;
    }
    if (!data.get('company')?.trim()) {
      setorgError(true);
      tempError = true;
    }
    if (!data.get('city')?.trim()) {
      setcityError(true);
      tempError = true;
    }
    if (data.get("email").includes("@accenture.com")) {
      setError("Accenture people do not have to register using this form. Please sign in with your Accenture account on the login page.");
      setOpen(true);
      tempError = true;
      return;
    }
    if (data.get("password") && data.get("confirmPW") && data.get("password") != data.get("confirmPW")) {
      setError("Passwords do not match.");
      setOpen(true);
      tempError = true;
      return;
    }   
    !tempError && axios
      .post(`${ROOT_URL}/api/public/register`,
        {
          "success": success,
          "first_name": data.get("firstName"),
          "last_name": data.get("lastName"),
          "email": data.get("email"),
          "job_title": data.get("jobTitle"),
          "password": data.get("password"),
          "password_2": data.get("confirmPW"),
          "business_org": data.get("company"),
          "city": data.get("city")
        })
      .then((res) => {
        setSuccess(true);
        setOpen(true);
      })
      .catch((err) => {
        setOpen(true);
        setError(err.response.data || err.message);
      });
  }

  return (
    <Layout location={props.location}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={success ? "success" : "error"}
          variant="filled"
        >
          {
            success
              ? "Your request was successfully sent. An adminstrator will review it and you will receive a confirmation email as soon as it is validated."
              : <>{typeof errors === "string" || errors instanceof String
              ? errors
              : Object.keys(errors).map(function (key) {
                  return (
                    <span>
                      {errors[key]}
                      <br></br>
                    </span>
                  );
                })}</>
          }
        </Alert>
      </Snackbar>
      <div className="login-logo-container">
        <img src="/img/Accenture_logo_purple.svg" className="login-logo-style"></img>
      </div>
      <Card className="card-register">
        <CardBody>
          <Box component="form" noValidate autoComplete="off" onSubmit={registerAction} sx={{ mt: 1 }} className="form-register" >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  variant="standard"
                  inputProps={{ maxLength: 60 }}
                  error={fnError}
                  helperText={fnError && "First Name is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  variant="standard"
                  inputProps={{ maxLength: 60 }}
                  error={lnError}
                  helperText={lnError && "Last Name is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  variant="standard"
                  error={pwdError}
                  helperText={pwdError && "Password is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="confirmPW"
                  label="Confirm Password"
                  type="password"
                  id="confirmPW"
                  variant="standard"
                  error={cpwdError}
                  helperText={cpwdError && "Confirm Password is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  variant="standard"
                  error={emailError}
                  helperText={emailError && "Email Address is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="jobTitle"
                  label="Job title"
                  name="jobTitle"
                  variant="standard"
                  inputProps={{ maxLength: 60 }}
                  error={jobError}
                  helperText={jobError && "Job title is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Organization"
                  name="company"
                  variant="standard"
                  inputProps={{ maxLength: 60 }}
                  error={orgError}
                  helperText={orgError && "Organization is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  variant="standard"
                  inputProps={{ maxLength: 60 }}
                  error={cityError}
                  helperText={cityError && "City is required"}
                />
              </Grid>
            </Grid>
            <div className="text-center">
              <Button type="submit" variant="contained" className="center-btn">
                Register
              </Button>
            </div>
          </Box>
        </CardBody>
      </Card>
      <NavbarPublic location={props.location}></NavbarPublic>
    </Layout>
  )
}

export default Register;