import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { CheckBlockPresence } from "./report";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class Score extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var task = this.props.task;
    var state = this.props.state;
    this.state = {
      series: [99],
      optionsFormat: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#beeb91",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["FORMAT"],
      },
      optionsComposition: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#beeb91",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["COMPOSITION"],
      },
      optionsUsability: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#beeb91",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["USABILITY"],
      },
      optionsMessage: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#beeb91",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["MESSAGE"],
      },
      optionsMobile: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#beeb91",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["MOBILE"],
      }
    };

    var scoreMessage = 0;
    if (task.analysis) {
      if (task.analysis.dom_text?.results) {
        scoreMessage = state.scoreDomText;
      } else {
        if (
          task.analysis.image_copywriting?.results.text_presence?.results
            ?.textPresence == true
        ) {
          scoreMessage = state.scoreCopywriting;
        } else {
          scoreMessage = 0;
        }
      }
    }

    var scoreUsability = 0;
    if (task.analysis) {
      if (task.analysis.dom_cta) {
        scoreUsability = (5 / 11 * state.scoreSafety + 6 / 11 * state.scoreDomCTA);
      }
      else {
        // if (task.analysis.image_emotion?.results.number_humans.results > 0) {
        //   scoreUsability = (5 / 12 * state.scoreSafety + 4 / 12 * state.scoreEmotion + 3 / 12 * state.scoreAccessibility);
        // } else {
        scoreUsability = (5 / 8 * state.scoreSafety + 3 / 8 * state.scoreAccessibility);
        // }
      }
    }

    var scoreComposition = 0;
    if (task.analysis) {
      if (task.analysis.dom_navigation) {
        scoreComposition = state.scoreDomNavigation;
      }
      else {
        if (task.analysis.image_copywriting?.results.text_presence?.results?.textPresence) {
          scoreComposition = state.scoreTextRatio;
        } else {
          scoreComposition = 0;
        }
      }
    }

    var scoreMobile = 0
    if (task.analysis) {
      if (task.analysis.dom_mobileux?.results) {
        scoreMobile = state.scoreDomMobile;
      }
    }

    return (
      <>
        <Row className="d-flex justify-content-around">
          {task.analysis.image_format ? (
            <Col xs="3 fade-in" className="text-center z-index-2">
              <a className="normal-a" href="#format">
                <Row className="justify-content-middle">
                  <ReactApexChart
                    options={this.state.optionsFormat}
                    series={[Math.floor(state.scoreFormat)]}
                    type="radialBar"
                    height={250}
                    labels={["FORMAT"]}
                  />
                </Row>
              </a>
              <span className="score-info-icon">
                <FontAwesomeIcon
                  // onMouseEnter={() => this.infoModalRef.toggle('format', task.url)}
                  className="info-icon"
                  icon="info-circle"
                  id="format-tooltip"
                ></FontAwesomeIcon>
                <ReactTooltip
                  anchorId="format-tooltip"
                  place="bottom"
                  variant="info"
                >
                  <span className="text-decoration-underline">Format score calculation for Image:</span>
                  <ul className="mt-2">
                    <li>For format, calculation of image color space, aspect ratio and image resolution are added</li>
                    <li>Imaze size score is calculated based on how far is the image height or width from the recommended</li>
                    <li>Final score = (sum of above three formats/3)*100</li>
                  </ul>
                </ReactTooltip>
              </span>
              <CheckBlockPresence analysis={task.analysis} block="image_format">
                <Label
                  result={
                    task.analysis.image_format?.results.correct_aspect_ratio?.results?.correctRatio
                  }
                  label="Ratio"
                ></Label>
                <Label
                  result={
                    task.analysis.image_format?.results.correct_image_resolution
                      .results.correctResolution
                  }
                  label="Image size"
                ></Label>
                {/* <Label
                result={
                  task.analysis.image_format?.results.correct_file_extension
                    .results.correctFormat
                }
                label="File type"
              ></Label> */}
                <Label
                  result={
                    task.analysis.image_format?.results.correct_colorspace
                      .results.correctColorspace
                  }
                  label="Colorspace"
                ></Label>
              </CheckBlockPresence>
            </Col>
          ) : (
            // <Row className="justify-content-middle full-height">
            // <div className="score-round align-middle">-</div>
            // </Row>
            <></>
          )}

          <Col xs="3 fade-in" className="text-center z-index-2">
            <a className="normal-a" href="#composition">
              <Row>
                <ReactApexChart
                  options={this.state.optionsComposition}
                  series={[Math.round(scoreComposition)]}
                  type="radialBar"
                  height={250}
                  label={["COMPOSITION"]}
                />
              </Row>
              {/* <Row className="justify-content-middle">
                <Col className="score-label">Composition</Col>
              </Row> */}
            </a>

            <span className="score-info-icon">
              <FontAwesomeIcon
                className="info-icon"
                icon="info-circle"
                id="composition-tooltip"
              ></FontAwesomeIcon>
              <ReactTooltip
                anchorId="composition-tooltip"
                place="bottom"
                variant="info"
              >
                {this.state.url ?
                  <>
                    <span className="text-decoration-underline">Composition score calculation for URL:</span>
                    <ul className="mt-2">
                      <li>For URL, if search is present then score is increased by 1</li>
                      <li>If navigation is present, is correctly placed and the elements include link to needed pages then score is increased by one (S) for each</li>
                      <li>Final score = (S/Ts)*100 (Ts is total number of factors)</li>
                    </ul>
                  </>
                  : <>
                    <span className="text-decoration-underline">Composition score calculation for Image:</span>
                    <ul className="mt-2">
                      {/* <li>First text is detected then number of humans are identified and if found image emotion (joy, anger, sorrow, surprise), safety, text ratio is used for calculation</li>
                      <li>If text is not present and number of humans is more than zero then final score composition =  (5/10)*Score_safety + (5/10)*(Score_Emotion)</li>
                      <li>If text is present and number of humans is more than zero then final score composition =  (1/11)*Score_text-ratio + (5/11)*Score_safety + (5/11)*(Score_Emotion)</li>
                      <li>If text is present and number of humans is zero then final score composition =  (1/6)*Score_text-ratio + (5/6)*Score_safety</li>
                      <li>If text is not present and number of humans is zero then final composition is the safety score</li>
                      <li>Safety score is based on various factors like adult, medical, racy, spoofed, violent content multiplied by 100</li> */}
                      <li>First text is detected, if text is present then the correct ratio of text present in the Image is calculated (S) </li>
                      <li>Final Score = S * 100</li>
                      <li>If text is not present, final composition score = 0</li>
                    </ul>
                  </>}
              </ReactTooltip>
            </span>

            <CheckBlockPresence analysis={task.analysis} block="dom_navigation">
              {task.analysis?.dom_navigation?.results?.navigation_coord ? (
                <>
                  <Label
                    result={
                      task.analysis.dom_navigation.results.navigation_coord
                        .results.has_nav
                    }
                    label="Navigation Presence"
                  ></Label>
                  {task.analysis?.dom_navigation?.results?.navigation_coord?.results?.has_nav &&
                    <Label
                      result={
                        task.analysis.dom_navigation.results.navigation_coord
                          ?.results?.correctly_placed
                      }
                      label="Navigation Correctly Placed"
                    ></Label>
                  }
                </>
              ) : (
                <></>
              )}
              {task.analysis?.dom_navigation?.results?.search_presence ? (
                <>
                  <Label
                    result={
                      task.analysis.dom_navigation.results.search_presence
                        ?.results?.has_search
                    }
                    label="Search Presence"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis.image_copywriting?.results.text_presence?.results
                ?.textPresence ? (
                <>
                  {" "}
                  <CheckBlockPresence
                    analysis={task.analysis}
                    block="image_text_ratio"
                  >
                    <Label
                      result={
                        task.analysis.image_text_ratio?.results.correct_ratio
                          .results.correct_ratio
                      }
                      label="Image:Text ratio"
                    ></Label>
                  </CheckBlockPresence>
                </>
              ) : (
                <> </>
              )}
            </CheckBlockPresence>
          </Col>

          <Col xs="3 fade-in" className="text-center z-index-2">
            <a className="normal-a" href="#usability">
              <Row>
                <ReactApexChart
                  options={this.state.optionsUsability}
                  series={[Math.round(scoreUsability)]}
                  type="radialBar"
                  height={250}
                />
              </Row>
            </a>

            <span className="score-info-icon">
              <FontAwesomeIcon
                className="info-icon"
                icon="info-circle"
                id="usability-tooltip"
              ></FontAwesomeIcon>
              <ReactTooltip
                anchorId="usability-tooltip"
                place="bottom"
                variant="info"
              >
                {this.state.url ?
                  <>
                    <span className="text-decoration-underline">Usability score calculation for URL:</span>
                    <ul className="mt-2">
                      <li>For this calculation, presence of CTA and various CTA factor like boldness, number of char, textual, duplicate, fold, descriptive is taken into consideration</li>
                      <li>If the CTA metric is compliant add 1 (S) and divide by total number of CTA metric (Ts) i.e., (S / Ts)*100</li>
                      <li>Presence of safety measures like Medical, Adult, Spoofed, Racy & Violence content are also taken into consideration for Usability score calculation and the score is multiplied with 100</li>
                      <li>Final Score =  (5/11)*Safety Score + (6/11)*CTA Score</li>
                    </ul>
                  </>
                  :
                  <>
                    <span className="text-decoration-underline">Usability score calculation for Image:</span>
                    <ul className="mt-2">
                      <li>For image calculation of usability, it checks accessibility levels like a, aa, aaa</li>
                      <li>If the Accessibility metric is compliant, add 1 (S) and divide by total number of Accessibility metric (Ts) i.e., (S / Ts)*100</li>
                      <li>Presence of safety measures like Medical, Adult, Spoofed, Racy & Violence content are also taken into consideration for Usability score calculation and the score is multiplied with 100</li>
                      <li>Final Score = (5/8)*Safety Score + (6/8)*Accessibility Score</li>
                    </ul>
                  </>}
              </ReactTooltip>
            </span>

            <CheckBlockPresence analysis={task.analysis} block="dom_cta">
              {task.analysis?.dom_cta?.results?.CTA_presence?.results
                ?.cta_presence ? (
                <>
                  {task.analysis.dom_cta.results.CTA_bold.results ? (
                    <Label
                      result={
                        task.analysis.dom_cta.results?.CTA_bold?.results
                          ?.correct_cta_bold
                      }
                      label="CTA Boldness"
                    ></Label>
                  ) : (
                    <></>
                  )}
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_textual?.results
                        .correct_cta_char
                    }
                    label="Textual CTA"
                  ></Label>
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_duplicate?.results?.correct_cta_duplicate
                    }
                    label="CTA Duplicates"
                  ></Label>
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_fold?.results?.correct_cta_fold
                    }
                    label="CTA Fold"
                  ></Label>
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_descriptive?.results?.correct_cta_descriptive
                    }
                    label="CTA Descriptive"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis?.image_copywriting?.results.text_presence?.results
                ?.textPresence ? (
                <>
                  <CheckBlockPresence
                    analysis={task.analysis}
                    block="image_accessibility"
                  >
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_aaa
                      }
                      label="AAA Accessibility level"
                    ></Label>
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_aa
                      }
                      label="AA Accessibility level"
                    ></Label>
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_large
                      }
                      label="Large Accessibility level"
                    ></Label>
                  </CheckBlockPresence>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence analysis={task.analysis} block="image_safety">
              <Label
                result={
                  task.analysis.image_safety?.results?.correct_safety?.results
                    ?.correct_adult
                }
                label="Safety: Adult content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_medical
                }
                label="Safety: Medical content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_racy
                }
                label="Safety: Racy content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_spoofed
                }
                label="Safety: Spoofed content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_violence
                }
                label="Safety: Violent content"
              ></Label>
            </CheckBlockPresence>
            {/* {task.analysis.image_emotion?.results?.number_humans?.results > 0 &&
              <CheckBlockPresence
                analysis={task.analysis}
                block="image_emotion"
              >
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_anger
                  }
                  label="Level of anger"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_joy
                  }
                  label="Level of joy"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_sorrow
                  }
                  label="Level of sorrow"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion.results?.correct_emotion.results
                      .correct_surprise
                  }
                  label="Level of surprise"
                ></Label>
              </CheckBlockPresence>
            } */}
          </Col>

          <Col xs="3 fade-in" className="text-center z-index-2">
            <a className="normal-a" href="#message">
              <Row>
                <ReactApexChart
                  options={this.state.optionsMessage}
                  series={[Math.round(scoreMessage)]}
                  type="radialBar"
                  height={250}
                />
              </Row>
              {/* <Row className="justify-content-middle">
                <Col className="score-label">message</Col>
              </Row> */}
            </a>

            <span className="score-info-icon">
              <FontAwesomeIcon
                className="info-icon"
                icon="info-circle"
                id="message-tooltip"
              ></FontAwesomeIcon>
              <ReactTooltip
                anchorId="message-tooltip"
                place="bottom"
                variant="info"
              >
                {this.state.url ?
                  <>
                    <span className="text-decoration-underline">Message score calculation for URL:</span>
                    <ul className="mt-2">
                      <li>Text to image ratio , wordiness(number of characters), font number are used for message score calculation</li>
                      <li>S = Total number of true factors</li>
                      <li>Ts = Total number of factors</li>
                      <li>If above factors are satisfied then S is incremented by 1</li>
                      <li>Final score = (S/Ts)*100</li>
                    </ul>
                  </>
                  :
                  <>
                    <span className="text-decoration-underline">Message score calculation for Image:</span>
                    <ul className="mt-2">
                      <li>For Image message calculation average of too long text and too short text is used to calculate the final score</li>
                      <li>Score = (1 – averagetoolong) + (1 – averageTooShort); average is received from response</li>
                      <li>Final score = (Score/2)*100</li>
                    </ul>
                  </>}
              </ReactTooltip>
            </span>
            <CheckBlockPresence analysis={task.analysis} block="dom_text">
              {task.analysis?.dom_text?.results ? (
                <>
                  {" "}
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.text_image_ratio
                        ?.results.correct_ratio
                    }
                    label="Text:Image ratio"
                  ></Label>
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.wordiness?.results
                        .correct_text_char
                    }
                    label="Wordiness"
                  ></Label>
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.font_number?.results
                        .correct_font_number
                    }
                    label="Font Number"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis.image_copywriting?.results?.text_presence?.results
                ?.textPresence ? (
                <>
                  <Label
                    result={
                      task.analysis.image_copywriting?.results.text_too_short
                        .results.averageTooShort *
                        100 ==
                        0
                        ? true
                        : task.analysis.image_copywriting?.results
                          .text_too_short.results.averageTooShort *
                          100 <
                          50
                          ? "Maybe"
                          : false
                    }
                    label="Wordiness"
                  ></Label>
                  <Label
                    result={
                      task.analysis.image_copywriting?.results?.text_too_long
                        ?.results?.averageTooLong *
                        100 ==
                        0
                        ? true
                        : task.analysis.image_copywriting?.results.text_too_long
                          .results.averageTooLong *
                          100 <
                          50
                          ? "Maybe"
                          : false
                    }
                    label="Conciseness"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
          </Col>

          {
            task.analysis?.dom_mobileux &&
            <Col xs="3 fade-in" className="text-center z-index-2">
              <a className="normal-a" href="#mobile">
                <Row>
                  <ReactApexChart
                    options={this.state.optionsMobile}
                    series={[Math.round(scoreMobile)]}
                    type="radialBar"
                    height={250}
                  />
                </Row>
              </a>
              <span className="score-info-icon">
                <FontAwesomeIcon
                  className="info-icon"
                  icon="info-circle"
                  id="mobile-tooltip"
                ></FontAwesomeIcon>
                <ReactTooltip
                  anchorId="mobile-tooltip"
                  place="bottom"
                  variant="info"
                >
                  {this.state.url ?
                    <>
                      <span className="text-decoration-underline">Mobile Page Analysis score calculation for URL:</span>
                      <ul className="mt-2">
                        <li>User interactions (pinch to zoom, scrolling), element visibility (target size, text font size), image optimization (image size optimization) are used for mobile page analysis score calculation</li>
                        <li>S = Total number of true factors</li>
                        <li>Ts = Total number of factors</li>
                        <li>If above factors are satisfied then S is incremented by 1</li>
                        <li>Final score = (S/Ts)*100</li>
                      </ul>
                    </>
                    :
                    <>
                      <span className="text-decoration-underline">Mobile Page Analysis score calculation for Image:</span>
                      <ul className="mt-2">
                        <li>User interactions (pinch to zoom, scrolling), element visibility (target size, text font size), image optimization (image size optimization) are used for mobile page analysis score calculation</li>
                        <li>S = Total number of true factors</li>
                        <li>Ts = Total number of factors</li>
                        <li>If above factors are satisfied then S is incremented by 1</li>
                        <li>Final score = (S/Ts)*100</li>
                      </ul>
                    </>}
                </ReactTooltip>
              </span>
              <CheckBlockPresence analysis={task.analysis} block="dom_mobileux">
                {
                  task.analysis?.dom_mobileux?.results
                    ? <>
                      {" "}
                      <Label
                        result={task.analysis?.dom_mobileux?.results?.pinch_to_zoom?.results?.supported}
                        label="Pinch-to-zoom"
                      ></Label>
                      <Label
                        result={task.analysis?.dom_mobileux?.results?.scrolling?.results?.correct_scroll}
                        label="Scrolling"
                      ></Label>
                      <Label
                        result={task.analysis?.dom_mobileux?.results?.target_size?.results?.too_small_targets <= 0}
                        label="Targets size"
                      ></Label>
                      <Label
                        result={task.analysis?.dom_mobileux?.results?.text_size?.results?.too_small_texts <= 0}
                        label="Texts font size"
                      ></Label>
                      <Label
                        result={task.analysis?.dom_mobileux?.results?.image_optim?.results?.imgs_too_large <= 0}
                        label="Images size optimization"
                      ></Label>
                    </>
                    : ""
                }
              </CheckBlockPresence>
            </Col>
          }



        </Row>
        {/* <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={250}
        /> */}
      </>
    );
  }
}

const Label = (props) => {
  return props.result == true ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == false ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "Maybe" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  );
};

export default Score;