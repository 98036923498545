import React from "react";
import axios from "axios";
import { Row, Button, Col, Card, CardBody, CardImg, CardTitle } from "reactstrap";
import ReactJson from 'react-json-view'
import striptags from "striptags"
import { setPageTitle } from "../../../actions/titleActions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROOT_URL } from "../../../constants";

class RawTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task: {},
    };
    this.export = this.export.bind(this);
  }

  fetchData() {

    const { dispatch } = this.props;

    axios
      .get(`${ROOT_URL}/api/admin/raws/gettask`, {params: {id: this.props.match.params.id}})
      .then((res) => {
        dispatch(setPageTitle( res.data.task[0].label, "file"));
        this.setState({
          task: res.data.task[0],
        });
      })
      .catch((err) => {
        // this.props.history.push("/home");
      });
  }

  export() {
      var _this = this;
    axios
    .get(`${ROOT_URL}/api/admin/raws/export`, {
      responseType: "blob",
      params: { ids: [this.state.task._id ]},
    })
    .then(function (response) {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = striptags(_this.state.task.label) + ".zip";
      link.click();
    });
  }

  componentDidMount() {

    this.fetchData();
    
  }

  render() {
    return (
      <div className="content">
               <FontAwesomeIcon
          icon="arrow-left"
          className="prev clickable"
          onClick={() => this.props.history.goBack()}
        ></FontAwesomeIcon>
          <div className="w-100 text-right">
           <Button onClick={() => this.export()}   color="secondary">
            Export
          </Button>
          </div>
       <Card>
            <CardBody>
              <CardTitle className="project-title">{this.state.task.label}</CardTitle>
      
              <ReactJson theme="monokai" collapsed={1} src={this.state.task.analysis}></ReactJson>
            </CardBody>
          </Card>
     
      </div>
    );
  }
}

export default connect()(RawTask);
