import React, { useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminLayout from "../admin/AdminLayout";
import Sidebar from "../sidebar/Sidebar";
import Header from "../navbars/Header";

import Dashboard from "../home/Dashboard";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Outlet,
  useLocation,
  Navigate,
  Routes,
} from "react-router-dom";
import TeamRoutes from "../team/Routes";
import TaskRoutes from "../task/Routes";
import ProjectRoutes from "../project/Routes";
import ProjectView from "../project/View";
import AdminRoute from "../private-route/AdminRoute";
import axios from "axios";
import { setResourceToken } from "../../actions/resourceActions";
import jwt_decode from "jwt-decode";
import View from "../project/View";
import Profile from "../user/Profile";
import Feedback from "../home/Feedback";
import ManageTeam from "../../components/team/ManageTeam"
import Admindashboard from "../admin/Dashboard";
import IndexTeam from "../admin/teams/Index";
import IndexUser from "../admin/users/Index";
import ViewTeam from "../admin/teams/View";
import ViewUser from "../admin/users/View";
import LogsIndex from "../admin/Logs";
import Explorer from "../admin/Explorer";
import Feedbacks from "../admin/Feedbacks";
import IndexProjects from "../admin/raws/IndexProjects";
import Project from "../admin/raws/Project";
import RawTask from "../admin/raws/Task";
import ViewTask from "../task/View";
import All from "../team/Index";
import ManageIndex from "../team/ManageIndex";
import { ROOT_URL } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './internal.css'
import { useEffect } from "react";
import TeamsView from '../team/View'
const Internal = (props) => {
  const [fontSize, setFontSize] = useState('custom-title')
  const [today, setToday] = useState(new Date())


  // const location = useLocation();
  useEffect(() => {
    if (props.title.title) {
      if (props.title.title.length > 80) {
        setFontSize('custom-title-small')
      }
      else {
        setFontSize('custom-title')
      }
    }
  }, [props.title.title])

  React.useEffect(() => {
    const { dispatch } = props;
    if(!localStorage.getItem('jwtToken')){
      window.location.href = "/login";
    }
    var update = false;
    if (!props.resource_token.resource_token) {
      update = true;
    } else {
      var decoded = jwt_decode(props.resource_token.resource_token);
      if (decoded.exp < Date.now() / 1000) {
        update = true;
      }
    }

    if (update) {
      axios
        .get(`${ROOT_URL}/api/users/updatetoken`)
        .then((res) => {
          var decoded = jwt_decode(res.data.token);
          if (decoded.require_logout) {
            window.location.href = "/logout";
          } else {
            dispatch(setResourceToken(res.data.token));
          }
        })
        .catch((err) => {
          console.log("Error Occured")
        });
    }
  }, []);


  const [isHovered, setIsHovered] = useState(true);

  const handleMouseEnter = (value) => {
    setIsHovered(value);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }
  const newProps = {
    ...props,
    isHovered,
    handleMouseEnter
  };

  return (
    <>
      <div>
        <Feedback></Feedback>

        <div className="wrapper">
          {/* <div className={isHovered?'hide':'display'} 
           onMouseLeave={()=>handleMouseEnter(true)}
              // onMouseLeave={handleMouseLeave}
              >        
                <Sidebar 
                  location={props.location}>
               </Sidebar>
</div> */}



          <div className="main-panel">
            <Header {...newProps} />
            <div className="sub-container">
              <div className=" formStyle">
                <span id="page-title" className="analyzed-url-heading">
                  {" "}
                  {/* <FontAwesomeIcon
                    className="mr-3"
                    icon={props.title.icon}
                  /> */}
                  {" "}
                  {/* <div >{props.title.title}</div> */}
                  <a
                    target="_blank"
                    className="quiet-a analyzed-url-label"
                    href={props.title.titlel}
                  >
                    <span
                      className="truncated-url"
                      title={props.title.title}
                    >
                      {props.title.title}
                    </span>
                  </a>
                </span>
                <br />
                <div className="pannel">
                  <Routes>
                    {/* <Route path="*" element={<AdminLayout/>}/> */}
                    <Route path="*" element={<TeamRoutes />} />
                    <Route path="admin/dashboard" element={<Admindashboard />} />
                    <Route path="/admin/teams" element={<IndexTeam />} />
                    <Route path="/admin/teams/:id" element={<ViewTeam />} />
                    <Route path="/admin/users" element={<IndexUser />} />
                    <Route path="/admin/users/:id" element={<ViewUser />} />
                    <Route path="/admin/logs" element={<LogsIndex />} />
                    <Route path="/admin/explorer" element={<Explorer />} />
                    <Route path="/admin/feedbacks" element={<Feedbacks />} />
                    <Route path="/admin/raws" element={<IndexProjects />} />
                    <Route path="/admin/raws/projects/:id" element={<Project />} />
                    <Route path="/admin/raws/tasks/:id" element={<RawTask />} />
                    <Route path="/teams/:id" element={<All />} />
                    <Route path="/teams" element={<TeamsView />} />
                    <Route path="/teams/manage/:id" element={<ManageTeam />} />
                    <Route path="/teams/manage" element={<ManageIndex />} />
                    <Route render={() => <Navigate to="/404" />} />
                    <Route path="/projects/:id" element={<ProjectView />} />
                    {/* <Route path="/projects" element={<ProjectRoutes/>} />  */}
                    {/* <Route path="/tasks" element={<TaskRoutes/>} /> */}
                    <Route path="/tasks/:id" element={<ViewTask />} />
                    <Route path="/public" element={<View />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/home" element={<Dashboard />} />
                    <Route render={() => <Navigate to="/404" />} />
                  </Routes>
                </div>
              </div>
            </div>
            <div className="footerStyle">
              <span className="footerFont" style={{ color: 'white', marginLeft: '60px' }}>CRISP</span>
              <span style={{ color: 'white', marginRight: '14px' }}>Copyright &copy; {today.getFullYear()} Accenture All rights
                reserved.</span>

            </div>
          </div>

        </div>

      </div>


    </>

  );
};

Internal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  resource_token: state.resource_token,
  title: state.title,

});



export default connect(mapStateToProps)(Internal);