import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Progress,
} from "reactstrap";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import axios from "axios";
import { setPageTitle } from "../../actions/titleActions";
import { ROOT_URL } from "../../constants";
import './header.css'
import "./View.css";
import { Link } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      activeTab: '',
      isDropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  handleAdminLinkHover = () => {
    this.setState({
      isDropdownOpen: true,
    });
  }

  handleAdminLinkLeave = () => {
    this.setState({
      isDropdownOpen: false,
    });
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }

  handlePopState = () => {
    const path = window.location.pathname; // or use a method to extract the relevant part of the path
    let activeTab = '';
    if (path.includes('/home')) {
      activeTab = 'Dashboard';
    } else if (path.includes('/teams')) {
      activeTab = 'Teams';
    } else if (path.includes('/teams/manage')) {
      activeTab = 'manageTeams';
    } else if (path.includes('/admin')) {
      activeTab = 'admin';
    }
    this.setState({ activeTab });
  }
  // componentWillMount(){
  //   localStorage.setItem('selectedMenuItem', 'Dashboard');
  // }
  componentDidMount() {
    const { dispatch } = this.props;
    setTimeout(()=>{
      const selectedMenuItem = localStorage.getItem('selectedMenuItem');
    this.setState({ activeTab: selectedMenuItem })
  },1000) 
    window.addEventListener('popstate', this.handlePopState);
    dispatch(setPageTitle("Dashboard", "home"));
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      // e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  render() {
    const { user } = this.props.auth;
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <>
        <div className="topnav">
          <div className={this.props.isHovered ? 'display' : 'display'} onMouseEnter={() => { this.props.handleMouseEnter(false) }}>
            {/* <FontAwesomeIcon className="mr-3" color="white" icon="fa-solid fa-bars" /> */}
          </div>

          <div className="headerText">
          CRISP
          </div>

          <div className="nav-container">
            <Nav navbar>
              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle  nav>
                  <img src="../../../img/profile.png" />
                 {/* <span className="userName"> {user.full_name}</span>  */}
                  {/* <FontAwesomeIcon className="mr-3" icon="user" />
                  {user.full_name} */}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem href="/profile" tag="a">
                    My profile
                  </DropdownItem>

                  <Quotas user={user}></Quotas>
                 {/* <DropdownItem href="/logout" tag="a">
                    Log out
                  </DropdownItem>  */}
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </div>
        </div>
        <div className="imgContainer">
          <img src="../../../img/header.png" alt="Snow" style={{ width: '100%', height: '18.6vh' }} />
          <div className="top-left ">
            <div className="headlineText">Campaign Comparison</div>
            {/* <div> <span>Search and Identify showcases Al Innovation Labs current image
            </span> </div>
            <div>
              <span> recognition capabilities tagging captioning and thematic decomposition</span>
            </div> */}
          </div>
        </div>
        <div className="menu">
          <Nav tabs>
            <NavItem className="col nav-design">
              <NavLink
                tag={Link} to="/home"
                style={{  border: 'none' }} 
                className={`navMenu ${(this.state.activeTab === 'Dashboard')?'active':''}`}
                path="/home"
                onClick={() => {
                  this.setState({ activeTab: 'Dashboard' });
                  localStorage.setItem('selectedMenuItem', 'Dashboard');
                }}
              >
                Dashboard

              </NavLink>
            </NavItem>
            <NavItem className="col nav-design" >
              <NavLink
                tag={Link} to="/teams"
                style={{  border: 'none' }} 
                className={`navMenu ${(this.state.activeTab === 'Teams')?'active':''}`}
                onClick={() => {
                  this.setState({ activeTab: 'Teams' });
                  localStorage.setItem('selectedMenuItem', 'Teams');
                }}
              >
               My Teams

              </NavLink>
            </NavItem>
            <NavItem className="col nav-design" >
              <NavLink
                tag={Link} to="/teams/manage"
                style={{  border: 'none' }} 
                className={`navMenu ${(this.state.activeTab === 'manageTeams')?'active':''}`}
                onClick={() => {
                  this.setState({ activeTab: 'manageTeams' });
                  localStorage.setItem('selectedMenuItem', 'manageTeams');
                }}
              >
                Manage Teams
              </NavLink>
            </NavItem>

            {this.props.auth.user.admin ? (

              <NavItem className="col nav-design">
                <div
                  className="nav-link-wrapper"
                  onMouseEnter={this.handleAdminLinkHover}
                  onMouseLeave={this.handleAdminLinkLeave}
                >
                  <NavLink tag={Link} to="/teams/manage" style={{  border: 'none' }}   className={`navMenu ${(this.state.activeTab === 'admin')?'active':''}`}>
                    Admin
                  </NavLink>
                  {this.state.isDropdownOpen && (
                    <div className="adminDropdown">
                      <ul>
                        <NavLink className="navMenu" tag={Link} to="/admin/dashboard"
                         style={{  border: 'none' }} 
                           onClick={() => {
                            this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');
                            
                          }}
                        >
                          Dashboard
                        </NavLink>
                        <NavLink   tag={Link} to="/admin/teams"
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Teams
                        </NavLink>
                        <NavLink tag={Link} to="/admin/users"
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Users
                        </NavLink>
                        <NavLink tag={Link} to="/admin/explorer"
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Explorer
                        </NavLink>
                        <NavLink tag={Link} to="/admin/logs" 
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Logs
                        </NavLink>
                        <NavLink tag={Link} to="/admin/feedbacks" 
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Feedbacks
                        </NavLink>
                        <NavLink tag={Link} to="/admin/raws"
                         style={{  border: 'none' }} 
                         onClick={() => {
                          this.setState({ activeTab: 'admin' });
                  localStorage.setItem('selectedMenuItem', 'admin');

                        }}
                        >
                          Raws
                        </NavLink>
                      </ul>
                    </div>
                  )}
                </div>
              </NavItem>
            ) : (
              null
            )}
          </Nav>
        </div>
      </>
    );
  }
}
const MenuItem = (props) => {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
}
const Quotas = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${ROOT_URL}/api/users/getquotas`);
      setData(result.data.user);
    };

    fetchData();
  }, []);

  return (
    <div className="wrapper-quotas">
      <div className="w-100 small">
        <span className="pull-left">Images</span>{" "}
        <span className="pull-right">
          {data.current_quota_image}/{data.quota_image}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_image / data.quota_image) * 100 > 80
            ? "danger"
            : (data.current_quota_image / data.quota_image) * 100 > 60
              ? "warning"
              : "info"
        }
        value={(data.current_quota_image / data.quota_image) * 100}
      />
      <div className="w-100 small mt-2">
        <span className="pull-left">Videos</span>{" "}
        <span className="pull-right">
          {data.current_quota_video}/{data.quota_video}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_video / data.quota_video) * 100 > 80
            ? "danger"
            : (data.current_quota_video / data.quota_video) * 100 > 60
              ? "warning"
              : "info"
        }
        value={(data.current_quota_video / data.quota_video) * 100}
      />
      <div className="w-100 small mt-2">
        <span className="pull-left">HTML</span>{" "}
        <span className="pull-right">
          {data.current_quota_dom}/{data.quota_dom}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_dom / data.quota_dom) * 100 > 80
            ? "danger"
            : (data.current_quota_dom / data.quota_dom) * 100 > 60
              ? "warning"
              : "info"
        }
        value={(data.current_quota_dom / data.quota_dom) * 100}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  title: state.title,
});

export default connect(mapStateToProps)(Header);
