import React, { useEffect, useState } from "react";
import moment from "moment";
import striptags from "striptags";
import axios from "axios";
import { Row, Col, Card, CardImg, CardBody, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tasks_mapping from "../../variables/access";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import industries from "../../variables/industries";
import geographies from "../../variables/geographies";
import channels from "../../variables/channels";
import types from "../../variables/types";
import { ROOT_URL } from "../../constants";
import { connect } from "react-redux";

const TaskCard = (props) => {
  const [taskList,setTaskList]=useState()
  const [task, setTask] = useState(
    props.task
      ? {
        ...props.task,
        label: props.task.label,
        crop_name:
          props.task.label.length > 18
            ? props.task.label.substring(0, 18) + "..."
            : props.task.label,
      }
      : false
  );
  var methods = {
    url: { icon: "globe", pretty: "URL" },
    chrome: { icon: "chrome", pretty: "Google Chrome" },
    upload: { icon: "upload", pretty: "Upload" },
    api: { icon: "server", pretty: "API" },
    adobe: { icon: "adobe", pretty: "Adobe" },
  };

  var media = {
    image: { icon: "image", pretty: "Image" },
    dom: { icon: "file-code", pretty: "HTML content" },
    video: { icon: "video", pretty: "Video" },
  };

  var timeout = false;


  const download = () => {
    axios
      .get(`${ROOT_URL}api/tasks/download`, {
        responseType: "blob",
        params: { id: task._id },
      })
      .then(function (response) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = striptags(task.label) + ".zip";
        link.click();
      });
  };
  useEffect(()=>{

    // console.log(props.taskData.taskData.id)
    addVideoPredictiveDesign()
    const updateTask = props.taskData.taskData
    if(updateTask.taskid === task._id){
      setTask({ ...task, status:updateTask.status });
    }
    else{
      console.log("false")
    }
  //   let taskTemp = props?.taskData?.find((t)=>t.id===task._id)
  //   // setWebSocket(props.taskData.taskData.tasks)
  //   setTask({ ...task, status:taskTemp?.status });
  } ,[props?.taskData,tasks_mapping])


  const addVideoPredictiveDesign=()=>{
    var newObject = {
      videopredictivedesign: {
        pretty: "Predictive Design",
        badge: "PREDICTIVE DESIGN",
        available_for: ["dom", "image", "video"],
      },
    };
    setTaskList({...tasks_mapping,...newObject})
  }
  // useEffect(() => {
  //   status();

  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //   };
  // 
// }, [props.taskData]);

  // const status = () => {
  //   if (task.status == "pending" || 
  //       (task.status != "error" && task.tasks.includes("ibmaccessibility") && task.accessibility_status === undefined)) {
  //     axios
  //       .get(`${ROOT_URL}/api/tasks/status`, {
  //         params: {
  //           id: task._id,
  //         },
  //       })
  //       .then((res) => {
  //         setTask({ ...task, status: res.data.status, accessibility_status: res.data.accessibility_status });
  //         timeout = setTimeout(() => status(), 2000);
  //       })
  //       .catch((err) => {
  //         setTask({ ...task, status: "error" });
  //       });
  //   }
  // };

  return task !== false ? (
    <Col xs="19">
      <Card className={"fade-in task-" + task.status}>
        <CardBody>
          <div className="container-icon-task ">
            <div className="container-icon-task ">
              {/* <FontAwesomeIcon
                className={"method-icon " + task.method}
                icon={methods[task.method].icon}
                title={methods[task.method].pretty}
              ></FontAwesomeIcon> */}
             <img src={`/img/${task.media==='dom'?'globe.png':(`${task.media==='image'?'image.png':'video.png'}`)}`} />
              {/* <FontAwesomeIcon
                className={"ml-2 method-icon"}
                icon={media[task.media].icon}
                title={media[task.media].pretty}
              ></FontAwesomeIcon> */}
            </div>
            {props.noedit ? (
              <span></span>
            ) : (
              <div className="pull-right h-100">
                {/* <FontAwesomeIcon
                  onClick={() => props.editRef.toggle(task, task.project.team)}
                  className="icon-card clickable mr-2"
                  icon="edit"
                  title="Edit task"
                /> */}
                 <img src="/img/edit.png"  className="editImage"  onClick={() => props.editRef.toggle(task, task.project.team)}/>
                {/* <FontAwesomeIcon
                onClick={() => download()}
                className="icon-card clickable mr-2"
                icon="download"
              /> */}
                {/* <FontAwesomeIcon
                  onClick={() => props.shareRef.toggle(task._id)}
                  className="icon-card clickable mr-2"
                  icon="share"
                /> */}
                {/* <FontAwesomeIcon
                  onClick={() => props.deleteRef.toggle(task._id)}
                  className="icon-card clickable"
                  icon="trash"
                  title="Delete task"
                /> */}
                <img src="/img/trash.png"   onClick={() => props.deleteRef.toggle(task._id)}/>
              </div>
            )}
          </div>

          <div className=" mt-3">
            <span className=" task-title">
              <a className="quiet-a"
                href={`/projects/${task.project._id}`}
              >{`${task.project.name}`}</a>{" "}
            </span>
           
          </div>
          <div >
          <span className="bold task-title">
              {task.method == "url" ? (
                <a target="_blank" className="quiet-a" href={task.url}>
                  {task.url.split("/")[2]}
                </a>
              ) : (
                task.label
              )}
            </span>
          </div>

          <div className="mt-2 ">
  
 


 {
 taskList?
 (Object.keys(taskList)
    .filter((key) => {

      const badge = taskList[key];
      return (
        badge.available_for.indexOf(task.media) !== -1 &&
        task.tasks.indexOf(key) !== -1
      );
    })
    .map((key, index, array) => {
      let badge = taskList[key];
 
      return (
        <span key={key} className="reportNames">
          {badge.pretty}
          {index === array.length - 1 ? '' : ', '}
        </span>
      );
    })):null
    }
</div>

          <div className="date-card mt-3">
            {" "}
            Created {moment(task.creation).format("lll")} by{" "}
            {task?.owner?.full_name}{" "}
          </div>
        </CardBody>
        <div style={{ position: "relative" }}>
          {(task.status === "pending" || (task.tasks.includes("ibmaccessibility") && task.accessibility_status === undefined)) ? (
            <FontAwesomeIcon
              className="spinner-rotate task-spinner"
              icon="spinner"
            ></FontAwesomeIcon>
          ) : task.status === "error" ? (
            <span className="task-error-icon-container">
              <FontAwesomeIcon
                className="task-error-icon"
                icon="exclamation-circle"
              ></FontAwesomeIcon>
              <br></br>
              <span>An error occured</span>
            </span>
          ) : (
            <span></span>
          )}

          <div>
            <div
              onMouseDownCapture={() => {
                document
                  .getElementById(`info-${task._id}`)
                  .classList.remove("info-out");
                document
                  .getElementById(`info-${task._id}`)
                  .classList.add("info-in");
              }}
              onMouseLeave={() => {
                document
                  .getElementById(`info-${task._id}`)
                  .classList.remove("info-in");
                document
                  .getElementById(`info-${task._id}`)
                  .classList.add("info-out");
              }}
              id={`info-${task._id}`}
              className="info-task"
            >
              <FontAwesomeIcon icon="info"></FontAwesomeIcon>
              <Row>
                <Col xs={6}>
                  <span className="title-info-task"> Type</span> <br></br>
                  {task.type != "" &&
                    types.filter((row) => row.name === task.type)[0] !==
                    undefined ? (
                    types.filter((row) => row.name === task.type)[0].pretty_name
                  ) : (
                    <span className="not-specified">Not specified</span>
                  )}
                  <hr></hr>
                  <span className="title-info-task">Industry</span>
                  <br></br>
                  {task.industry != "" &&
                    industries.filter((row) => row.name === task.industry)[0] !==
                    undefined ? (
                    industries.filter((row) => row.name === task.industry)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                </Col>
                <Col xs={6} className="text-right bordered-col">
                  <span className="title-info-task"> Geography</span>
                  <br></br>
                  {task.geography != "" &&
                    geographies.filter(
                      (row) => row.name === task.geography
                    )[0] !== undefined ? (
                    geographies.filter((row) => row.name === task.geography)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                  <hr></hr>
                  <span className="title-info-task"> Channel</span> <br></br>
                  {task.channel != "" &&
                    channels.filter((row) => row.name === task.channel)[0] !==
                    undefined ? (
                    channels.filter((row) => row.name === task.channel)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                </Col>
              </Row>
            </div>

            <a
              className={
                "cardimg-task-container" +
                (task.status !== "finished" ? "disabled" : "")
              }
              onClick={() => {
                if (task.status === "finished") {
                  window.location.replace("/tasks/" + task._id);
                }
              }}
              href={"/tasks/" + task._id}
            >
              {task.media === "video" ? (
                <span>
                  {" "}
                  <CardImg
                    top
                    width="100%"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/img/loading.gif";
                    }}
                    className={"card-img-video "}
                    src="https://crisp.rd.center/images/player.png"
                    alt="Card image cap"
                  />
                  <LazyLoadImage
                    placeholderSrc={
                      "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/img/loading.gif";
                    }}
                    effect="blur"
                    className="card-img-results"
                    src={`/thumbs/${task.upload_id}/thumb.jpeg`}
                  />
                </span>
              ) : (
                <LazyLoadImage
                  placeholderSrc={
                    "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/loading.gif";
                  }}
                  effect="blur"
                  className="card-img-results"
                  src={`/thumbs/${task.upload_id}/thumb.jpeg`}
                />
              )}
            </a>
          </div>
        </div>
      </Card>
    </Col>
  ) : (
    <span></span>
  );
};

const mapStateToProps = (state) =>
 {
  return { taskData: state.task_status };
}
;
export default connect(mapStateToProps)(TaskCard);
