import React from "react";
import { Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class ImageColorblind extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="mt-4">
          <Col className="py-2 text-title" xs="4">
            COLORBLIND
          </Col>
        </Row>
        <Row className="border-row no-top-border">
          <Col className="py-2 text-title" xs="4">
            Deuteranopia
          </Col>
          <Col className="py-2 text-title" xs="4">
            Protanopia
          </Col>
          <Col className="py-2 text-title" xs="4">
            Tritanopia
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <div>
              <img
                placeholderSrc={
                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                }
                effect="blur"
                className="card-img-results"
                src={`/output/${this.props.uploadID}/predictivedesign/deuteranopia.jpg`}
              />
            </div>
          </Col>
          <Col xs="4">
            <div>
              <img
                placeholderSrc={
                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                }
                effect="blur"
                className="card-img-results"
                src={`/output/${this.props.uploadID}/predictivedesign/protanopia.jpg`}
              />
            </div>
          </Col>
          <Col xs="4">
            <div>
              <img
                placeholderSrc={
                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                }
                effect="blur"
                className="card-img-results"
                src={`/output/${this.props.uploadID}/predictivedesign/tritanopia.jpg`}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageColorblind;
