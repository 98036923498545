import React, { Component } from "react";
import {
 // BrowserRouter as Router,
  //Redirect,
  Route,
  Navigate,
  Routes,
 // Switch,
} from "react-router-dom";
import All from "./Index";
import ManageIndex from "./ManageIndex";
import ManageTeam from "./ManageTeam";

class TeamRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/teams/manage" element={<ManageIndex/>} />
        <Route path="/teams/manage/:id" element={<ManageTeam/>} />
        <Route path="/teams/:id" element={<All/>} />
        <Route render={() => <Navigate to="/404" />} />
      </Routes>
    );
  }
}

export default TeamRoutes;