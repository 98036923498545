import React from "react";
import {

    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,

  } from "reactstrap";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubActions = ({ value, onChange }) => (
    <InputGroup className="no-border search-table">
      <Input
        className="input-blue-bg border-0"
        value={value}
        onChange={onChange}
        placeholder="Search..."
      />
      {/* <InputGroupAddon addonType="append">
        <InputGroupText className="input-blue-bg">
          <FontAwesomeIcon icon="search" />
        </InputGroupText>
      </InputGroupAddon> */}
      <div className="input-group-append ml-0">
        <InputGroupText className="input-blue-bg">
        <FontAwesomeIcon icon="search" />
        </InputGroupText>
      </div>
    </InputGroup>
  );

  export default SubActions