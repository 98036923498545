import React from "react";

import axios from "axios";
import { Card, CardBody, Badge } from "reactstrap";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import moment from "moment";
import SubActions from "./utils";
import { ROOT_URL } from "../../constants";

class Feedbacks extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = {
      feedbacks: [],
      filtered: [],
    };

    this.colors = {
      suggestion: { color: "info", pretty: "SUGGESTION" },
      bug: { color: "danger", pretty: "BUG" },
      compliment: { color: "success", pretty: "COMPLIMENT" },
    };

    this.columns = (token) => [
      {
        name: "Date",
        selector: (row) => moment(row.creation).unix(),
        cell: (row) => moment(row.creation).format("lll"),
        sortable: false,
        center: true,
      },
      {
        name: "User",
        selector: (row) => row.owner.full_name,
        sortable: true,
        center: true,
      },
      {
        name: "Tag",
        selector: "tag",
        cell: (row) => (
          <Badge color={this.colors[row.tag].color}>
            {this.colors[row.tag].pretty}
          </Badge>
        ),
        sortable: true,
        center: true,
      },
      {
        name: "Content",
        cell: (row) => {
          return (<div className=" analyzed-url-label2" >
            <span
              className="truncated-url"
              title={row.text}
            >
              {row.text}
            </span>
          </div>)
        },
        selector: "text",
        sortable: true,
        center: true,
      },
    ];
  }
  search(event) {
    var keyword = event.target.value;
    var filteredData = this.state.feedbacks.filter(function (obj) {
      return (
       obj["owner"].full_name.toLowerCase().includes(keyword.toLowerCase()) ||
        obj["text"].toLowerCase().includes(keyword.toLowerCase()) 
      );
    });
    this.setState({ filtered: filteredData });
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Explorer", "database"));
    axios
      .get(`${ROOT_URL}/api/admin/feedbacks/getall`)
      .then((res) => {
        this.setState({ feedbacks: res.data.feedbacks , filtered: res.data.feedbacks});
      })
      .catch((err) => {
        this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>CRISP - Explorer</title>
        </Helmet>
        <div className="w-100 text-right"></div>
        <Card>
          <CardBody>
            <DataTable
              title="Feedbacks"
              pagination={true}
              columns={this.columns(this.props.resource_token.resource_token)}
              data={this.state.filtered}
              subHeader={true}
              striped={true}
             
  
              subHeaderComponent={
                <SubActions value={this.state.value} onChange={this.search} />
              }
            ></DataTable>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});

export default connect(mapStateToProps)(Feedbacks);
