import React from "react";
const public_codes = ({
  code,
  owner,
  user,
  team,
  task,
  creation,
  project,
  feedback,
  info,
}) => {
  const arr = {
    task_share: () => {
      return {
        color: "yellow",
        pretty: "Task sharing",
        content: (
          <span>

            {owner.full_name}{" "}

            shared a task in the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    task_edit: () => {
      return {
        color: "info",
        pretty: "Task edition",
        content: (
          <span>

            {owner.full_name}{" "}

            edited a task in the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    task_editblock: () => {
      return {
        color: "info",
        pretty: "Task block edition",
        content: (
          <span>

            {owner.full_name}{" "}

            edited the block {info} for the task in the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    project_edit: () => {
      return {
        color: "info",
        pretty: "Project edition",
        content: (
          <span>

            {owner.full_name}{" "}

            edited the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    team_edit: () => {
      return {
        color: "info",
        pretty: "Team edition",
        content: (
          <span>

            {owner.full_name}{" "}

            edited the team <a href={`/admin/teams/${team._id}`}>{team.name}</a>
            .
          </span>
        ),
      };
    },
    apikey_edit: () => {
      return {
        color: "info",
        pretty: "API key edit",
        content: (
          <span>

            {owner.full_name}{" "}

            edited the API key ({info}).
          </span>
        ),
      };
    },
    task_create: () => {
      return {
        color: "success",
        pretty: "Task creation",
        content: (
          <span>

            {owner.full_name}{" "}

            created a task in the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    project_create: () => {
      return {
        color: "success",
        pretty: "Project creation",
        content: (
          <span>

            {owner.full_name}{" "}

            created a project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    team_create: () => {
      return {
        color: "success",
        pretty: "Team creation",
        content: (
          <span>

            {owner.full_name}{" "}

            created the team{" "}
            {(team?._id) ? <a href={`/teams/${team._id}`}>{team.name}</a> : <a>{team?.name}</a>

            }
          </span>
        ),
      };
    },
    apikey_create: () => {
      return {
        color: "success",
        pretty: "API key creation",
        content: (
          <span>

            {owner.full_name}{" "}

            created an API key ({info}).
          </span>
        ),
      };
    },
    task_delete: () => {
      return {
        color: "danger",
        pretty: "Task deletion",
        content: (
          <span>

            {owner.full_name}{" "}

            deleted a task in the project{" "}
            <a href={`/projects/${project._id}`}>{project.name}</a>.
          </span>
        ),
      };
    },
    project_delete: () => {
      return {
        color: "danger",
        pretty: "Project deletion",
        content: (
          <span>

            {owner.full_name}{" "}

            deleted the project {project.name}.
          </span>
        ),
      };
    },
    team_delete: () => {
      return {
        color: "danger",
        pretty: "Team deletion",
        content: (
          <span>

            {owner.full_name}{" "}

            deleted the team {team.name}.
          </span>
        ),
      };
    },
    apikey_delete: () => {
      return {
        color: "danger",
        pretty: "API Key deletion",
        content: (
          <span>

            {owner.full_name}{" "}

            deleted an API key ({info}).
          </span>
        ),
      };
    },
    user_activate: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User activation" : "User deactivation",
        content: (
          <span>

            {owner.full_name}{" "}

            {info === "true" ? "activated" : "deactivated"} the account of{" "}

            {user.full_name}{" "}

            .
          </span>
        ),
      };
    },
    user_promote: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User promote" : "User demote",
        content: (
          <span>

            {owner.full_name}{" "}

            {info === "true" ? "promoted" : "demoted"} the account of{" "}

            {user.full_name}{" "}

            .
          </span>
        ),
      };
    },
    block_team: () => {
      return {
        color: "secondary",
        pretty: info === "true" ? "User promote" : "User demote",
        content: (
          <span>

            {owner.full_name}{" "}

            {info === "true" ? "blocked" : "deblocked"} the team{" "}
            {team.name}.
          </span>
        ),
      };
    },
    register_sso: () => {
      return {
        color: "primary",
        pretty: "SSO registration",
        content: (
          <span>

            {owner.full_name}{" "}

            registered through SSO.
          </span>
        ),
      };
    },
    register_request: () => {
      return {
        color: "primary",
        pretty: "Registration request",
        content: (
          <span>

            {owner.full_name}{" "}

            sent a registration request.
          </span>
        ),
      };
    },
    team_add_member: () => {
      return {
        color: "success",
        pretty: "Team new member",
        content: !user ? (
          <span>

            {owner.full_name}{" "}

            added {info} to the team{" "}
            <a href={`/teams/${team._id}`}>{team.name}</a>.
          </span>
        ) : (
          <span>

            {owner.full_name}{" "}

            added{" "}

            {user.full_name}{" "}

            {info === "true" ? "(leader)" : ""} to the team{" "}
            {(team?._id) ? <a href={`/teams/${team._id}`}>{team.name}</a> : <a>{team?.name}</a> }
          </span>
        ),
      };
    },
    team_remove_member: () => {
      return {
        color: "danger",
        pretty: "Team member removal",
        content: user ? (
          <span>

            {owner.full_name}{" "}

            removed{" "}

            {user.full_name}{" "}

            of the team <a href={`/teams/${team._id}`}>{team.name}</a>.
          </span>
        ) : (
          <span>

            {owner.full_name}{" "}

            removed {info} of the team{" "}
            <a href={`/teams/${team._id}`}>{team.name}</a>.
          </span>
        ),
      };
    },
    new_feedback: () => {
      return {
        color: "secondary",
        pretty: "Feedback",
        content: (
          <span>

            {owner.full_name}{" "}

            added a feedback.
          </span>
        ),
      };
    },
    toggle_leader: () => {
      return {
        pretty: info === "true" ? "success" : "danger",
        pretty: info === "true" ? "Leader addition" : "Leader removal",
        content: (
          <span>

            {owner.full_name}{" "}

            {info === "true" ? "added" : "removed"}{" "}

            {user.full_name}{" "}

            as a leader of the team{" "}
            <a href={`/teams/${user._id}`}>{team.name}</a>.
          </span>
        ),
      };
    },
  };

  return arr[code]();
};

export default public_codes;
