import React from "react";

import Create from "./Create";
import Edit from "./Edit";
import axios from "axios";
import {
  Col,
  Card,
  CardBody,
  Badge,
  Button,

} from "reactstrap";

import DataTable from "react-data-table-component";
import moment from "moment";
import { Helmet } from "react-helmet";
import SubActions from "../utils"
import { connect } from "react-redux";
import { setPageTitle } from "../../../actions/titleActions";
import { ROOT_URL } from "../../../constants";

class IndexTeam extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = {
      teams: [],
      filtered: [],
    };
  }

  search(event) {
    var keyword = event.target.value;
    var filteredData = this.state.teams.filter(function (obj) {
      return (
        obj["name"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["description"].toLowerCase().includes(keyword.toLowerCase())
      );
    });
    this.setState({ filtered: filteredData });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Teams", "users"));
    axios
      .get(`${ROOT_URL}/api/admin/teams/getteams`)
      .then((res) => {
        this.setState({ teams: res.data, filtered: res.data });
      })
      .catch((err) => {
        this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>CRISP - Teams</title>
        </Helmet>
        <Create
          ref={(modal) => {
            this.createRef = modal;
          }}
          team={this.team}
        ></Create>

        <div className="w-100 text-right">
          <Button onClick={() => this.createRef.toggle()} color="primary">
            Create team
          </Button>
        </div>

        <Card>
          <CardBody>
            <DataTable
              title="Teams"
              pagination={true}
              columns={columns}
              striped={true}
              data={this.state.filtered}
              subHeader={true}
              className="clickable-rows "
              onRowClicked={(row) =>
                window.location.replace("/admin/teams/" + row._id)
              }
              subHeaderComponent={
                <SubActions value={this.state.value} onChange={this.search} />
              }
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}


const columns = [
  {
    name: "Name",
    cell: (row) => {
      if (row.blocked === true) {
        return (
          <span className="text-center">
            <b>{row.name}</b> <br></br>
            <Badge color="danger">BLOCKED</Badge>
          </span>
        );
      } else {
        return <b>{row.name}</b>;
      }
    },
    selector: "name",
    sortable: true,
    center: true,
  },
  {
    name: "Description",
    cell: (row) => {
      return (<div className=" analyzed-url-label2" >
        <span
          className="truncated-url"
          title={row.description}
        >
          {row.description}
        </span>
      </div>)
    },
    selector: "description",
    sortable: true,
    center: true,
  },
  {
    name: "Number of members",
    selector: (row) => row.members.length,
    sortable: true,
    center: true,
  },
  {
    name: "Number of tasks",
    selector: "tasks_cnt",
    sortable: true,
    center: true,
  },
  {
    name: "Last activity",
    selector: (row) => moment(row.modified).unix(),
    cell: (row) => moment(row.modified).format("lll"),
    sortable: true,
    center: true,
  },
];

export default connect()(IndexTeam);
