/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  FormGroup,
  InputGroup,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Table } from "reactstrap";

import axios from "axios";
import ErrorJSON from "../../utils/Error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROOT_URL } from "../../../constants";

class AddMember extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.addMember = this.addMember.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.delete = this.delete.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      members: [],
      members_name: [],
      search: [],
      input: "",
      errors: false,
    };
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  addMember(event) {
    if(event){
      event.preventDefault();
      event.stopPropagation();
    }

    if(this.state.members.map(obj => obj.email).indexOf(this.state.input) !== -1){
      this.setState({ errors: "The user is already is the list." });
      return
    }

    var bool = this.validateEmail(this.state.input);

    if (!bool) {
      this.setState({ errors: "Email is not valid" });
    } else {
      axios
        .get(`${ROOT_URL}/api/teams/checkmember`, {
          params: { email: this.state.input, team: this.props.team },
        })
        .then((res) => {
          var { user } = res.data;
          if (!this.state.members.includes(this.state.input)) {
            if (user) {
              var name = `${user.full_name}`;
            } else {
              name = `${this.state.input} <br> <span className="small"> The user is not registered yet, an invitation will be sent.</span>`;
            }
            this.setState((prevState) => ({
              members: [
                ...prevState.members,
                { email: this.state.input, leader: false },
              ],
              members_name: [...prevState.members_name, name],
            }));
          }
          this.input.value = "";
          this.setState({ errors: false });
        })
        .catch((err) => {
          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    }
  }

  handleChange(event) {
    this.setState({ input: event.target.value });
    if(event.target.value !== ""){
      axios
      .get(`${ROOT_URL}/api/users/search`, {
        params: { search: event.target.value},
      })
      .then((res) => {
        this.setState({ search: res.data });
      }).catch((err) => {
        this.setState({ search: []});
      });
    } else {
      this.setState({ search: []});
    }
  }




  toggleAdmin(index) {
    this.setState((prevState) => {
      const newItems = [...prevState.members];
      newItems[index].leader = !newItems[index].leader;
      return { members: newItems };
    });
  }

  toggle() {
    this.setState({
        dropDownOpen : !this.state.dropDownOpen
    });
}

  delete(index) {
    this.setState((prevState) => ({
      members: prevState.members.filter(function (val, i) {
        return i !== index;
      }),
      members_name: prevState.members_name.filter(function (val, i) {
        return i !== index;
      }),
    }));
  }

  render() {
    return (
      <div>
        Members
        <Table>
          <thead className="text-center">
            <tr>
              <th>User</th>
              <th>Leader</th>
              <th></th>
            </tr>
          </thead>
          {this.state.members_name.length == 0 ? (
            <p className=" w-100 text-center">No member yet.</p>
          ) : (
            <tbody className="text-center">
              {this.state.members_name.map((member, index) => {
                return (
                  <tr>
                    <td dangerouslySetInnerHTML={{ __html: member }}></td>
                    <td>
                      <label className="switch">
                        <input
                          onChange={(e) => this.toggleAdmin(e && index)}
                          type="checkbox"
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <FontAwesomeIcon
                        onClick={() => this.delete(index)}
                        className="clickable"
                        icon="times"
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        {this.state.errors ? (
          <ErrorJSON json={this.state.errors}></ErrorJSON>
        ) : (
          <span></span>
        )}
        <div>
          <FormGroup className="m-0">
            <InputGroup className="m-0">
              <Input
                innerRef={(input) => {
                  this.input = input;
                }}
                type="email"
                name="email"
                autoComplete="off"
                id="name_member"
                onChange={this.handleChange}
                placeholder="Search for a name or enter an email"
              />

              {/* <InputGroupAddon addonType="append">
                <Button className="m-0 primary" onClick={this.addMember}>
                  Add
                </Button>
              </InputGroupAddon> */}
              <div className="input-group-append">
                <Button className="m-0 primary" onClick={this.addMember}>
                  Add
                </Button>
              </div>
            </InputGroup>
          </FormGroup>
          {this.state.search.length > 0 ? (
          <Dropdown nav isOpen={true} toggle={this.toggle}>
            <DropdownMenu className="autocomplete">
            {this.state?.search.map((user) => { console.log(user); return (
              <DropdownItem toggleModal = {this.props?.toggleModal } onClick={() => this.setState({input:user.email, search:[]}, this.addMember)}>
                {user?.full_name}
              </DropdownItem>
             
            )})}
            </DropdownMenu> 
          </Dropdown>
          ) : (<span></span>)}
        </div>
      </div>
    );
  }
}

export default AddMember;