//TODO: put it back when webpage and email are available
const types = [
  {
    pretty_name: "Web Design",
    name: "webpage",
    available_for: ["dom", "image", "video"]
  },
  {
    pretty_name: "Ads",
    name: "banner",
    available_for: ["image", "video"]
  },
  {
    pretty_name: "Social Post",
    name: "social",
    available_for: ["image", "video"]
  },
  {
    pretty_name: "Digital Shelf",
    name: "shelf",
    available_for: ["image", "video", "dom"]
  },
  {
    pretty_name: "Email",
    name: "email",
    available_for: ["dom", "image", "video"]
  },
];

export default types;
