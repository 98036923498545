import React, { useState } from "react";
import axios from "axios";
import html2canvas from 'html2canvas';
import { PDFDocument, rgb } from 'pdf-lib';

import {
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import industries from "../../variables/industries";
import geographies from "../../variables/geographies";
import channels from "../../variables/channels";
import types from "../../variables/types";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ErrorJSON from "../utils/Error";
import Edit from "./Edit";
import VcoTab from "./tabs/vco";
import MemorabilityTab from "./tabs/memorability";
import { CheckBlockPresence, ReportTab } from "./tabs/report";
import AccessibilityTab from "./tabs/accessibility";
import IBMAccessibilityTab from "./tabs/ibmAccessibility";
import LighthouseTab from "./tabs/lighthouse";
import BBVATab from "./tabs/bbva";
import { ROOT_URL } from "../../constants";
import { BUCKET_URL } from "../../constants";

// import { w3cwebsocket as W3CWebSocket } from "websocket";
import { taskNames, responseTask } from "../constants";
// import jsPDF from 'jspdf';
import "./view.css";
import ProgressModal from './ProgressModal'
import store from "../../store";
import { setCurrentUser } from "../../actions/taskActions";
import jwt_decode from "jwt-decode";
// import EventSource from 'eventsource';
import { fetchEventSource } from '@microsoft/fetch-event-source';

class ViewTask extends React.Component {
  constructor(props) {
    super(props);
    //this.onResolved = this.onResolved.bind(this);
    // this.task = this.props.match.params.id;
    this.task = window.location.pathname.split(/[/]+/).pop();
    // this.webSocketData = []
    this.state = {
      imageUploaded: false,
      task: false,
      activeTab: "report",
      isModalVisible: false,
      reports: [
        { name: 'vco', displayName: "Visual Attention Report", status: 'pending' },
        { name: 'memorability', displayName: "Memorability Prediction", status: 'pending' },
        { name: 'report', displayName: "Predictive Design", status: 'pending' },
        { name: 'experience', displayName: "Page Experience Report", status: 'pending' },
        { name: 'ibmaccessibility', displayName: "Accessibility Report", status: 'pending' },
      ],
      fileteredReports: []
    };
    //   const client = new W3CWebSocket('wss://socketsbay.com/wss/v2/1/demo/');
    //   if(client){
    //   client.addEventListener('message', this.handleMessage);
    // }
    if (this.state.task?.tasks?.includes("accessibility")) {
      this.state.activeTab = "accessibility";
    }

    this.toggle = this.toggle.bind(this);
  }
  handleMessage = (event) => {
    this.setState({ webSocketData: event.data });
  };
  toggle(tab) {
    if (tab === 'videopredictivedesign') {
      tab = 'report'
    }
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  closeModal = () => {
    this.setState({ isModalVisible: false });
  };
  updateReportStatus = (reportName, status) => {
    this.setState(prevState => ({
      fileteredReports: prevState.fileteredReports.map(report =>
        report.name === reportName ? { ...report, status } : report
      ),
    }));
  };
  hideTabs = async (tabClass) => {
    const descriptionDivs = document.querySelectorAll('.' + tabClass);
    descriptionDivs.forEach(div => {
      div.style.display = 'none';
    });
  }
  unHideTabs = async (tabClass) => {
    const descriptionDivs = document.querySelectorAll('.' + tabClass);
    descriptionDivs.forEach(div => {
      div.style.display = 'block';
    });
  }
  expandAllTabs = async (tabId) => {
    if (tabId === 'experience') {
      const metrices = document.querySelectorAll('.lh-metric__description');
      metrices.forEach(tab => {
        tab.style.display = 'block'
      });
      const tabs = document.querySelectorAll('.lh-audit__title');
      tabs.forEach(tab => {
        if (!tab.classList.contains('expanded')) {
          tab.click();
        }
        tab.style.marginBottom = '20px';
      });

    }

    await this.waitFor(500);

  };
  expandAllGroupSummaryTabs = async (tabId) => {
    if (tabId === 'experience') {
      const groupSummaryTabs = document.querySelectorAll('.lh-audit-group__header');
      for (const tab of groupSummaryTabs) {
        tab.click();
        await this.waitFor(500);
        const nestedTabs = tab.querySelectorAll('.lh-audit__title');
        nestedTabs.forEach(nestedTab => {
          nestedTab.click();
        });
      }
    }

    await this.waitFor(500);

  };

  collapseAllTabs = () => {
    const tabs = document.querySelectorAll('.lh-audit__title');
    tabs.forEach(tab => {
      // Check if the tab is expanded (adjust this logic as needed)
      if (tab.classList.contains('expanded')) {
        // Simulate a click to collapse
        tab.click();
      }
    });
  };



  collapseAllGroupSummaryTabs = () => {
    const groupSummaryTabs = document.querySelectorAll('.lh-audit-group__header');
    groupSummaryTabs.forEach(tab => {
      const nestedTabs = tab.querySelectorAll('.lh-audit__title');
      nestedTabs.forEach(nestedTab => {
        nestedTab.click();
      });

      tab.click();
    });
  };
  captureIframeContent = async (tabElement) => {
    const token = localStorage.jwtToken;
    const decoded = jwt_decode(token);
    this.setState({ loading: true });
    var id = this.state.task._id;
    const urlParams = { id: id, url: this.state.url };
    store.dispatch(setCurrentUser(urlParams));

    const headers = {
      'Authorization': `Bearer ${decoded}`,
    };
    const eventSourceInitDict = { headers };
    // var eventSourceInitDict = {https: {rejectUnauthorized: false}};
    const url = `${ROOT_URL}/api/tasks/puppeteerUrl`;

    // Initialize a new EventSource
    // const eventSource = new EventSource(url,eventSourceInitDict);

    const ctrl = new AbortController();

    try {
      await fetchEventSource(url, {
        method: 'POST',
        signal: ctrl.signal,
        openWhenHidden: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          id: id,
          url: `${BUCKET_URL}/output/${this.state.task.upload_id}/accessibility_report/accessibility.html`,
          userId: decoded.id,
          cssSelector: 'itemHeader'
        }),
        onopen: async (res) => {
          if (res.status === 200) {
            this.setState({ imageUploaded: true })
            return;
          }
          const contentType = res.headers.get('content-type');

          if (!!contentType && contentType.indexOf('application/json') >= 0) {
            throw await res.json();
          }
        },
        onerror: (e) => {
          if (!!e) {
            console.log('Fetch onerror');
            // do something with this error
          }

          // ctrl.abort();

          throw e;
        },
        onmessage: async (ev) => {
          const data = ev.data;

          if (!data) {
            return;
          }

          try {
            const d = JSON.parse(data);
            console.log("d value ")
          } catch (e) {
            console.log('Fetch onmessage error');
          }
        },
      })
    } catch (e) {
      console.log('Error');
    }
    setTimeout(() => this.setState({ imageUploaded: true }), 25000)

  };

  waitForConfirmation() {
    return new Promise((resolve) => {
      const checkConfirmation = () => {
        if (this.state.imageUploaded) {
          resolve();
        } else {
          setTimeout(checkConfirmation, 2000);
        }
      };

      checkConfirmation();
    });
  }


  captureTabAsPdf = async (tabId) => {
    await new Promise(resolve => this.setState({ activeTab: tabId }, resolve));

    let componentElement;
    if (tabId !== 'config' && tabId !== 'ibmaccessibility') {
      await this.selectAndPrepareTab(tabId);
    }
    let hideArray = ['lh-audit__description', 'lh-table-column--node', 'lh-table-column--text', 'lh-table-column--text', 'lh-table-column--code', 'lh-row--even', 'lh-table-column--url', 'lh-table-column--source-location']
    if (tabId === 'report') {
      tabId = 'report'
      const elements = document.getElementsByClassName('container-scroll');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'contents';
        elements[i].style.overflow = 'visible';
      }

      const descriptionDivs = document.getElementsByClassName('image-analysis-scroll');
      for (let i = 0; i < descriptionDivs.length; i++) {
        descriptionDivs[i].style.display = 'none';
      }
    }
    if (tabId === 'experience') {
      // await this.expandAllTabs(tabId);
      await this.expandAllGroupSummaryTabs(tabId);
      for (const hide of hideArray) {
        this.hideTabs(hide)
      }
    }
    await this.waitFor(2000);
    componentElement = document.getElementById(tabId);
    if (!componentElement) {
      console.error(`Element with ID '${tabId}' not found.`);
      return;
    }
    console.log("Capturing main content of the tab");
    const canvas = await html2canvas(componentElement, { backgroundColor: '#ffffff', letterRendering: true });
    const pdfDoc = await PDFDocument.create();
    if (tabId === 'ibmaccessibility') {
      console.log("Attempting to capture iframe content");
      const report = this.state.reports.find(r => r.name === tabId);
      let response;
      //        response = await fetch(`${BUCKET_URL}/input/dom/${this.state.task._id}/accesibility.jpeg`);
      //  if(!response){
      await this.captureIframeContent(componentElement);
      console.log("I frame captured")
      await this.waitForConfirmation();
      response = await fetch(`/input/dom/${this.state.task._id}/image.jpeg`);

      //  }
      // const response = await fetch('/img/report2.jpeg');

      const arrayBuffer = await response.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const image = await pdfDoc.embedJpg(uint8Array);
      const { width: imgWidth, height: imgHeight } = image;
      const pageWidth = canvas.width;
      const pageHeight = canvas.height * 3 + 50;
      const scaleX = pageWidth / imgWidth;
      const scaleY = pageHeight / imgHeight;
      const scale = Math.max(scaleX, scaleY);
      const scaledWidth = imgWidth * scale;
      const scaledHeight = imgHeight * scale;
      const xPosition = (pageWidth - scaledWidth) / 2;
      const yPosition = (pageHeight - scaledHeight) / 2;
      const page = pdfDoc.addPage([canvas.width + 50, scaledHeight + 100]);
      page.drawImage(image, {
        x: xPosition + 30,
        y: 0,
        width: scaledWidth,
        height: scaledHeight,
      });
      page.drawText(report.displayName, { x: 30, y: page.getHeight() - 70, size: 35 });
    }
    else {
      const report = this.state.reports.find(r => r.name === tabId);
      const page = pdfDoc.addPage([canvas.width + 50, canvas.height + 100]);
      const canvasDataUrl = canvas.toDataURL('image/png', 1.0);
      try {
        const pngImage = await pdfDoc.embedPng(canvas.toDataURL('image/png', 1.0));
        page.drawImage(pngImage, { x: 50, y: 10, width: canvas.width, height: canvas.height });
        page.drawText(report.displayName, { x: 30, y: page.getHeight() - 70, size: 35 });
      }
      catch (error) {
        console.log("error in embedding")
      }
    }
    if (tabId === 'experience') {
      this.collapseAllTabs();
      this.collapseAllGroupSummaryTabs();
      for (const hide of hideArray) {
        this.unHideTabs(hide)
      }
    }
    if (tabId === 'report') {
      tabId = 'report';
      const elements = document.getElementsByClassName('container-scroll');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = '';
        elements[i].style.overflow = '';
      }

      const descriptionDivs = document.getElementsByClassName('image-analysis-scroll');
      for (let i = 0; i < descriptionDivs.length; i++) {
        descriptionDivs[i].style.display = 'block';
      }
    }
    console.log("PDF capture complete");
    this.updateReportStatus(tabId, 'done');
    return pdfDoc;
  };
  orderArray = async (order) => {
    const inputArray = ["vco", "memorability", "report", "experience", "ibmaccessibility","videopredictivedesign"]
    // Filter and sort the inputArray based on the order array
    const orderedArray = inputArray
      .filter(item => order.includes(item)) // Filter out items not in the order array
      .sort((a, b) => inputArray.indexOf(a) - inputArray.indexOf(b)); // Sort based on the index in 'order'

    return orderedArray;
  }
  captureAllTabsAsPdf = async () => {
    let tabIds = await this.orderArray(this.state.task?.tasks)

    if (tabIds.includes('videopredictivedesign')) {
      tabIds = tabIds.map(item => item === 'videopredictivedesign' ? 'report' : item);
    }
    tabIds.unshift('config')
    this.setState({ fileteredReports: this.state.reports.filter(report => tabIds.includes(report.name)) });

    this.setState({ isModalVisible: true });
    const pdfDoc = await PDFDocument.create();

    for (const tabId of tabIds) {
      const tabPdfDoc = await this.captureTabAsPdf(tabId);

      const copiedPages = await pdfDoc.copyPages(tabPdfDoc, [0]);
      copiedPages.forEach((page) => pdfDoc.addPage(page));
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'All_Reports.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    tabIds.shift()
  };
  waitFor = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  selectAndPrepareTab = async (tabId) => {
    await this.waitFor(3000);
    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      await this.waitFor(1000);
      const additionalScroll = tabElement.clientHeight - window.innerHeight;
      if (additionalScroll > 0) {
        window.scrollBy({ top: additionalScroll, behavior: 'smooth' });
        await this.waitFor(1000);
      }
    }
    await this.waitFor(2000);
  };



  componentDidMount() {
    const { dispatch } = this.props;
    var task = this.task;
    axios
      .get(`${ROOT_URL}/api/tasks/gettask`, {
        params: {
          id: task,
        },
      })
      .then((res) => {
        var type = false;
        if (res.data.type) {
          type = types.find((type) => type.name == res.data.type).pretty_name;
        }
        dispatch(
          setPageTitle(
            res.data.label
            //   (type ? " - " + type : "") +
            //   " - " +
            //   moment(res.data.creation).format("YYYY-MM-DD"),
            // "file-alt"
          )
        );

        this.setState({
          task: {
            ...res.data,
            crop_name:
              res.data.label.length > 35
                ? res.data.label.substring(0, 35) + "..."
                : res.data.label,
          },
        });
        if (res.data.status !== "finished") {
          this.props.history.push("/projects/" + res.data.project._id);
        }

        if (this.state.task?.tasks?.includes("bbva")) {
          this.setState({ activeTab: "bbva" });
        } else if (this.state.task?.tasks?.includes("vco")) {
          this.setState({ activeTab: "vco" });
        } else if (this.state.task?.tasks?.includes("memorability")) {
          this.setState({ activeTab: "memorability" });
        } else if (this.state.task?.tasks?.includes("report")) {
          this.setState({ activeTab: "report" });
        } else if (this.state.task?.tasks?.includes("experience")) {
          this.setState({ activeTab: "experience" });
        } else if (this.state.task?.tasks?.includes("ibmaccessibility")) {
          this.setState({ activeTab: "ibmaccessibility" });
        }
      })
      .catch((err) => {
        window.location.replace("/home");
      });
  }
  // componentDidUpdate(prevProps){
  //   if(this.props.screenShot !== prevProps.screenShot){
  //     this.setState({imageUploaded: this.props.screenShot})
  //   }

  // }
  render() {
    const { isModalVisible, fileteredReports } = this.state;
    var task = this.state.task;
    var token = this.props.resource_token.resource_token;
    var tabCount = 0;
    return (
      <div className="content">
        <Edit
          ref={(modal) => {
            this.editRef = modal;
          }}
          history={this.props.history}
          id={this.state.task._id}
          access={this.props.access}
        ></Edit>

        <Helmet>
          <title>{`CRISP - ${this.state.task.label}`}</title>
        </Helmet>
        {this.state.errors ? (
          <ErrorJSON json={this.state.errors}></ErrorJSON>
        ) : (
          <span></span>
        )}
        <div className="px-3" id='config'>
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              <Row className="my-5 justify-content-md-right">
                <Col xs="6" className="justify-content-center text-center">
                  {" "}
                  {task.media === "image" || task.media === "dom" ? (
                    <>
                      {task.method == "url" ? (
                        <div className="rendering-container">
                          <img
                            className="rendering-device"
                            src={`/img/desktop.png`}
                          ></img>
                          <div
                            className={`rendering-content-container desktop`}
                          >
                            <img></img>

                            <img
                              placeholderSrc={
                                "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                              }
                              effect="blur"
                              className="rendering-content"
                              src={`/input/dom/${task.upload_id}/image.jpeg`}
                            />
                          </div>
                        </div>
                      ) : (
                        <img
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className=" max-height  justify-content-center text-center"
                          src={`/input/image/${task.upload_id}/image.jpeg`}
                        />
                      )}
                    </>
                  ) : (
                    <video
                      controls
                      replay
                      className="video-result"
                      controlsList="nodownload"
                      autoplay
                      src={`/input/video/${task.upload_id}/video.mp4`}
                    ></video>
                  )}
                </Col>
                <Col xs="6">
                  <Row>
                    <Col xs="10" className="offset-1">
                      <Row className="">
                        <span className="  top-text">
                          <a
                            className="serious-link"
                            href={`/teams/${task.project.team._id}`}
                          >
                            {`${task.project.team.name}`}
                          </a>
                          <span className="bold chev">{"  >  "}</span>
                          <a
                            className="serious-link"
                            href={`/projects/${task.project._id}`}
                          >
                            {`${task.project.name}`}
                          </a>
                          {"  "}
                          <FontAwesomeIcon
                            onClick={() =>
                              this.editRef.toggle(task, task.project?.team._id)
                            }
                            className="icon-card clickable ml-4"
                            icon="edit"
                            title="Edit"
                          />
                        </span>
                      </Row>

                      <Row className=" top-text url-container " >
                        <Col className="analyzed-url-label2">
                          <span
                            className="truncated-url"
                            title={this.state.task.label}
                          >
                            {this.state.task.label}
                          </span>


                        </Col>

                      </Row>

                      {/* <div className="url-container">
                        <span className="truncated-url" title={this.state.task.label}>
                          {this.state.task.label}
                        </span>
                      </div> */}
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs="12">
                      {this.state.task.channel == "" ? (
                        <></>
                      ) : (
                        <Row>
                          <Col
                            className="justify-content-end text-right"
                            xs="5"
                          >
                            <b>Channel:</b>
                          </Col>
                          <Col xs="6">
                            {this.state.task.channel != ""
                              ? channels.find(
                                (channel) =>
                                  channel.name == this.state.task.channel
                              ).pretty_name
                              : "Not specified"}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Type:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.type != ""
                            ? types.find(
                              (type) => type.name == this.state.task.type
                            ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Industry:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.industry != ""
                            ? industries.find(
                              (industry) =>
                                industry.name == this.state.task.industry
                            ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Geography:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.geography != ""
                            ? geographies.find(
                              (geography) =>
                                geography.name == this.state.task.geography
                            ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Created:</b>
                        </Col>
                        <Col xs="6">{moment(task.creation).format("LL")}</Col>
                      </Row>
                      {task.method == "url" ? (
                        <>
                          <Row>
                            <Col
                              className="justify-content-end text-right"
                              xs="5"
                            >
                              <b>Requested URL:</b>
                            </Col>
                            <Col xs="6">
                              <a
                                target="_blank"
                                className="quiet-a"
                                href={task.url}
                              >
                                {task.url.split("/")[2]}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="justify-content-end text-right"
                              xs="5"
                            >
                              <b>Analyzed URL:</b>
                            </Col>
                            <Col xs="6">
                              <a
                                target="_blank"
                                className="quiet-a analyzed-url-label"
                                href={task.url}
                              >
                                <span
                                  className="truncated-url"
                                  title={task.url}
                                >
                                  {task.url}
                                </span>
                              </a>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>

              </Row>
            </>
          )}
        </div>
        {isModalVisible && (
          <ProgressModal
            fileteredReports={fileteredReports}
            closeModal={this.closeModal}
          />
        )}
        <div className="text-dark-blue">
          <button className="close-modal" onClick={() => this.captureAllTabsAsPdf()}>Download All Reports</button>

          <Nav tabs>
            {taskNames
              .filter((task) => this.state.task?.tasks?.includes(task.name))
              .map((task) => {
                return (
                  <NavItem className="col tabs-design" key={task.id}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === (task.name === 'videopredictivedesign' ? 'report' : task.name),
                      })}
                      onClick={() => {
                        this.toggle(task.name);
                      }}
                    >
                      {task.displayName}
                      {!this.props.access?.includes("report") ? (
                        <FontAwesomeIcon className="ml-2" icon="lock" />
                      ) : (
                        <></>
                      )}
                    </NavLink>
                  </NavItem>
                );
              })
            }

            {/* {!this.state.task?.tasks?.includes("bbva") ? ( */}
            {/* <CheckBlockPresence analysis={this.state.task.analysis} block="dom_cta"> */}
            {/* <NavItem className="col tabs-design">
                <NavLink
                   className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Predictive Design
                  {!this.props.access.includes("report") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem> */}
            {/* </CheckBlockPresence> */}
            {/* ) : (
              <></>
            )} */}
            {/* {this.webSocketData?.map((web)=>
              {
                return <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === web.id,
                  })}
                  onClick={() => {
                    this.toggle(web.id);
                  }}
                >
                  {web.displayName}
                </NavLink>
              </NavItem>
              }
              )
              }
                    */}

            {/* <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Visual Attention Prediction 
                </NavLink>
              </NavItem>
            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_lighthouse"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Page Experience Report
                </NavLink>
              </NavItem>
            </CheckBlockPresence>

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="email_accessibility"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Email Accessibility Report
                  {!this.props.access.includes("accessibility") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence>

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="image_memorability"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  Memorability Prediction
                  {!this.props.access.includes("memorability") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_form"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggle("6");
                  }}
                >
                  BBVA Report
                  {!this.props.access.includes("report") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence> */}

            {/* {this.state.task?.tasks?.includes("ibmaccessibility") && (
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "7",
                  })}
                  onClick={() => {
                    this.toggle("7");
                  }}
                >
                  Accessibility Report
                </NavLink>
              </NavItem>
            )} */}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {(() => {
              switch (this.state.activeTab) {
                case "vco":
                  return (
                    <>
                      <TabPane id='vco' className="reportTable" tabId="vco">
                        <VcoTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                        ></VcoTab>
                      </TabPane>
                    </>);

                  break;
                case "ibmaccessibility":
                  return (
                    <>
                      <TabPane id='ibmaccessibility' tabId="ibmaccessibility">
                        <IBMAccessibilityTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          history={this.props.history}
                          access={this.props.access}
                        ></IBMAccessibilityTab>
                      </TabPane>
                    </>
                  );
                  break;
                case "memorability":
                  return (
                    <>
                      <TabPane id='memorability' tabId="memorability">
                        <MemorabilityTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          access={this.props.access}
                        ></MemorabilityTab>
                      </TabPane>
                    </>
                  );
                  break;
                case "email_accessibility":
                  return (
                    <>
                      <TabPane id='email_accessibility' tabId="email_accessibility">
                        <AccessibilityTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          access={this.props.access}
                        ></AccessibilityTab>
                      </TabPane>
                    </>
                  );
                  break;
                case "experience":
                  return (
                    <>
                      <TabPane id='experience' tabId="experience">
                        <LighthouseTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          access={this.props.access}
                        ></LighthouseTab>
                      </TabPane>
                    </>
                  );
                  break;
                case "bbva":
                  return (
                    <>
                      <TabPane id="bbva" tabId="bbva">
                        <BBVATab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          access={this.props.access}
                        ></BBVATab>
                      </TabPane>
                    </>
                  );
                  break;
                case "report":
                  return (
                    <>
                      <TabPane id='report' tabId="report">
                        <ReportTab
                          editRef={this.editRef}
                          task={this.state.task}
                          token={token}
                          access={this.props.access}
                        ></ReportTab>
                      </TabPane>
                    </>
                  );
                  break;
                default:
                  return (
                    <VcoTab
                      editRef={this.editRef}
                      task={this.state.task}
                      token={token}
                    ></VcoTab>
                  );
              }
            })()}
          </TabContent>

          {/* <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <VcoTab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
              ></VcoTab>
            </TabPane>

            {!this.state.task?.tasks?.includes("bbva") ? (
              <TabPane tabId="2">
                <ReportTab
                  editRef={this.editRef}
                  task={this.state.task}
                  token={token}
                  history={this.props.history}
                  access={this.props.access}
                ></ReportTab>
              </TabPane>
            ) : (
              <></>
            )}

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_lighthouse"
            >
              <TabPane tabId="3">
                <LighthouseTab
                  task={this.state.task}
                  token={token}
                ></LighthouseTab>
              </TabPane>
            </CheckBlockPresence>

            <TabPane tabId="4">
              <AccessibilityTab
                task={this.state.task}
                editRef={this.editRef}
                token={token}
                access={this.props.access}
              ></AccessibilityTab>
            </TabPane>

            <TabPane tabId="5">
              <MemorabilityTab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
                access={this.props.access}
              ></MemorabilityTab>
            </TabPane>

            <TabPane tabId="6">
              <BBVATab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
                access={this.props.access}
              ></BBVATab>
            </TabPane>

            {this.state.task?.tasks?.includes("ibmaccessibility") && (
              <TabPane tabId="7">
                <IBMAccessibilityTab
                  taskId={task._id}
                  accessibilitystatus={task.accessibility_status}
                  token={token}
                ></IBMAccessibilityTab>
              </TabPane>
            )}
          </TabContent>  */}
        </div>
      </div>
    );
  }
}

// const Example = (props) => {};

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
  screenShot: state.screen_shot,

});

export default connect(mapStateToProps)(ViewTask);
