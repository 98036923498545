import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import titleReducer from "./titleReducer";
import resourceReducer from "./resourceReducer";
import taskReducer from "./taskReduer";
import urlReducer from "./urlReducer";
import screenShotReducer from "./screenShotReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  title: titleReducer,
  resource_token: resourceReducer,
  task_status: taskReducer,
  currentUrl: urlReducer,
  screen_shot: screenShotReducer
});
