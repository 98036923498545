import React, { useState } from "react";

import axios from "axios";
import { Row, Col, Card, CardBody, Badge, Button, Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import moment from "moment";
import Logs from "../log/Log";
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";

import { Helmet } from "react-helmet";
import TaskCard from "../task/TaskCard";
import { ROOT_URL } from "../../constants";

class Profile extends React.Component {
  constructor(props) {
    super(props);
   this.user = this.props.auth.user.id;
    this.state = {
      user: this.props.auth.user,
    };
    this.ref = React.createRef();
  }
  
  scroll = (scrollOffset) => {
    this.ref.current.scrollLeft += scrollOffset;
  };

  componentDidMount() {
    axios
      .get(`${ROOT_URL}/api/users/getprofile` )
      .then((res) => {
        const { dispatch } = this.props;
        dispatch(
          setPageTitle(res.data.full_name, "user")
        );
        this.setState({ user: res.data });
      })
      .catch((err) => {
        this.props.history.push("/admin/teams");
      });
  }

  render() {
 
    return (
      <div className="content">
        <Helmet>
          <title>{`CRISP - ${this.state.user.full_name}`}</title>
        </Helmet>
        {!this.state.user ? (
          <span></span>
        ) : (
          <div>
       

     
            <Row>
              <Col xs="5">
                <Card>
                  <CardBody>
                    <div className="w-100">
                      <h3>
                        {this.state.user.full_name}
                      </h3>
                    </div>

                    <p className="block-info">
                      <span className="title-info">Date of registration: </span>
                      {moment(this.state.user.creation).format("lll")} <br />
                      <span className="title-info">Email: </span>
                      {this.state.user.email} <br />
                      <span className="title-info">Job title:</span>{" "}
                      {this.state.user.job_title} <br />
                      <span className="title-info">City:</span>{" "}
                      {this.state.user.city} <br />
                      <span className="title-info">
                        Business organization:
                      </span>{" "}
                      {this.state.user.business_org} <br />
                      <span className="title-info">Last login: </span>
                      {moment(this.state.user.last_login).format("lll")} <br />
                      <span className="title-info">Feedbacks:</span>
                      <br />
                      <span className="title-info">
                        Quotas:{" "}
          
                      </span>
                    </p>

                    <div>
                      <div className="w-100">
                        <span className="pull-left">Images</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_image}/
                          {this.state.user.quota_image}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_image /
                            this.state.user.quota_image) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_image /
                                this.state.user.quota_image) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_image /
                            this.state.user.quota_image) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">Videos</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_video}/
                          {this.state.user.quota_video}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_video /
                            this.state.user.quota_video) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_video /
                                this.state.user.quota_video) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_video /
                            this.state.user.quota_video) *
                          100
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">HTML</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_dom}/
                          {this.state.user.quota_dom}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_dom /
                            this.state.user.quota_dom) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_dom /
                                this.state.user.quota_dom) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_dom /
                            this.state.user.quota_dom) *
                          100
                        }
                      />
                    </div>
               
               
                  </CardBody>
                </Card>
              </Col>

              <Col xs="7">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Statistics</h4>


                    <div className="text-center mt-5">
                      <h5>Coming soon...</h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Teams</h4>
                    <DataTable
                      pagination={true}
                      columns={teams_col}
                      data={this.state.user.teams}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Logs</h4>
                    <Logs public={true} logs={this.state.user.logs}></Logs>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Last tasks</h4>

                    <div className="container-scroll" ref={this.ref}>
                  {(this.state.user.last_tasks?.length>0)?(     <div className="chevron-height">
                <div className="chevron-left" onClick={() => this.scroll(-70)}>
                  <i className="fas fa-chevron-left"></i>
                </div>
              </div>):null}
               
                      {this.state.user.last_tasks?.map((task) => (
                        <TaskCard
                          key={task._id}
                          token={this.props.resource_token.resource_token}
                          history={this.props.history}
                          noedit={true}
                          task={task}
                        ></TaskCard>
                      ))}
                         <div className="chevron-spacing">&nbsp;</div>
                         {(this.state.user.last_tasks?.length>0)?(     <div className="chevron-height-right">
                <div className="chevron-right" onClick={() => this.scroll(70)}>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>):null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const teams_col = [
  {
    name: "Name",
    cell: (row) => {
      var el = [];
      el.push(<span>{row.team.name}</span>);
      if (row.leader) {
        el.push(
          <Badge className="badge-sm ml-2" color="info">
            LEADER
          </Badge>
        );
      } else {
        el.push(<span></span>);
      }

      return el;
    },
    selector: "team.name",
    sortable: true,
    center: true,
  },
  {
    name: "Number of members",
    selector: (row) => row.team.members.length,
    sortable: true,
    center: true,
  },

  {
    name: "Last activity",
    selector: (row) => moment(row.team.modified).unix(),
    cell: (row) => moment(row.team.modified).format("lll"),
    sortable: false,
    center: true,
  },
];

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  auth: state.auth,
});

export default connect(mapStateToProps)(Profile);
