import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
} from "reactstrap";
import industries from "../../../variables/industries";
import geographies from "../../../variables/geographies";
import channels from "../../../variables/channels";
import types from "../../../variables/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DomForm from "./../blocks/DomForm";
import DomText from "./../blocks/DomText";
import DomCTA from "./../blocks/DomCTA";

import { CheckBlockPresence } from "./report";

class BBVATab extends React.Component {
  constructor(props) {
    super(props);
  }

  getScoreDomCTA(score) {}

  getScoreDomText(score) {}

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              {!access.includes("report") ||
              task.tasks.indexOf("bbva") === -1 ? (
                <div className="mt-4 px-4">
                  <CheckBlockPresence analysis={task.analysis} block="vco">
                    <Row>
                      <Col xs="12 fade-in" className="disabled-grayscale">
                        <Row>
                          <div className="text-center mt-3 mb-3 w-100">
                            <h3 className="warning">
                              This functionnality is not yet available, here is
                              a sample report. To get access, please contact{" "}
                              <a href="mailto:ai4c_crisp_support@accenture.com">
                                ai4c_crisp_support@accenture.com
                              </a>
                              . Thank you!
                            </h3>
                          </div>
                        </Row>
                        <Row>
                          <img
                            top
                            width="100%"
                            className="card-img-results "
                            src={"/img/accessibility-sample.png"}
                            alt="Card image cap"
                          />
                        </Row>
                      </Col>
                    </Row>
                  </CheckBlockPresence>
                </div>
              ) : (
                <>
                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in">
                      <CheckBlockPresence
                        analysis={task.analysis}
                        block="dom_form"
                      >
                        <DomForm
                          id={task._id}
                          data={task.analysis.dom_form}
                          token={token}
                        ></DomForm>
                      </CheckBlockPresence>
                    </Col>
                  </Row>

                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in">
                      <Row>
                        <div className="category-text">MESSAGE</div>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in">
                      <CheckBlockPresence
                        analysis={task.analysis}
                        block="dom_cta"
                      >
                        <DomCTA
                          id={task._id}
                          data={task.analysis.dom_cta}
                          token={token}
                          generateScore={this.getScoreDomCTA}
                        ></DomCTA>
                      </CheckBlockPresence>
                    </Col>
                  </Row>
                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in">
                      <CheckBlockPresence
                        analysis={task.analysis}
                        block="dom_text"
                      >
                        <DomText
                          id={task._id}
                          data={task.analysis.dom_text}
                          token={token}
                          generateScore={this.getScoreDomCTA}
                        ></DomText>
                      </CheckBlockPresence>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default BBVATab;
