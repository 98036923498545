import React from "react";
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentUser } from "../../actions/authActions";
import { connect } from "react-redux";

const Logout = (props) => {
  const { dispatch } = props;
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("selectedMenuItem");

  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  window.location.href = "./login";
  return <div></div>;
};

export default connect()(Logout);
