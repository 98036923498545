import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
} from "reactstrap";
import { Cube } from "../tabs/report";

import industries from "../../../variables/industries";
import geographies from "../../../variables/geographies";
import channels from "../../../variables/channels";
import types from "../../../variables/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBlockPresence } from "./report";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import ImageMemorability from "./../blocks/ImageMemorability";


class VcoTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frame_no: false,
      score: null,
    };
  }

  componentDidMount() {
    this.fetchData()
  }
  fetchData() {
    axios.get(`/output/${this.props.task.upload_id}/score.json`)
      .then(res => {
        const score = res.data.score;
        this.setState({ score: score })
      }).catch(err => {
        console.log("error in serveattachment=lighthouse")
      })
    axios.get(`/output/${this.props.task.upload_id}/output.json`)
      .then(res => {
        const frame_no = res.data.frame_no;
        this.setState({ frame_no: frame_no })
      }).catch(err => {
        console.log("error in serveattachment=lighthouse")
      })
  }
  render() {
    var task = this.props.task;
    var vco_status = task?.vco_results?.status
    var vco_results = task?.vco_results?.results?.data
    var heatmap_description = vco_results?.heatmap_description

    var token = this.props.token;
    var editRef = this.props.editRef;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <div className="mt-4 px-4">
              <CheckBlockPresence analysis={task.analysis} block="vco">
                {this.state.score > 0 ? (
                  <ImageMemorability
                    score={this.state.score}
                    vco="vco"
                  ></ImageMemorability>
                ) : null}
                <Row>
                  <Col xs="4 fade-in">
                    <b>Original</b>
                    <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <img
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className="card-img-results"
                          src={`/input/${task.media}/${task.upload_id}/image.jpeg`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoPlay
                          src={`/input/video/${task.upload_id}/video.mp4`}
                        ></video>
                      )}
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Visual Attention Heatmap</b>
                    <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <img
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/output/${task.upload_id}/heatmap.jpeg`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoPlay
                          src={`/output/${task.upload_id}/heatmap.mp4`}
                        ></video>
                      )}
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Visual Attention Opacity map</b>
                    <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <img
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/output/${task.upload_id}/Opacitymap.jpeg`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoPlay
                          src={`/output/${task.upload_id}/Opacitymap.mp4`}
                        ></video>
                      )}
                    </Card>
                  </Col>
                </Row>
                {(this.state.score > 0 && task.media == 'dom') ? (
                  <Row>
                    <Col xs="4 fade-in">
                      <b>Original Above The Fold</b>
                      <Card>
                        {task.media === "dom" ? (
                          <img
                            placeholderSrc={
                              "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                            }
                            effect="blur"
                            className="card-img-results"
                            src={`/output/${task.upload_id}/original_abovethefold.jpeg`}
                          />
                        ) : null}
                      </Card>
                    </Col>
                    <Col xs="4 fade-in">
                      <b>VCO Above The Fold Heatmap</b>
                      <Card>
                        {task.media === "dom" ? (
                          <img
                            placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                            effect="blur"
                            src={`/output/${task.upload_id}/abovethefold_heatmap.jpeg`}
                          />
                        ) : null}
                      </Card>
                    </Col>
                    <Col xs="4 fade-in">
                      <b>VCO Above The Fold Opacity map</b>
                      <Card>
                        {task.media === "dom" ? (
                          <img
                            placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                            effect="blur"
                            src={`/output/${task.upload_id}/abovethefold_opacitymap.jpeg`}
                          />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                ) : null}

                {(this.state.frame_no > 1 && task.media != 'video') ? (
                  <Row>
                    <Col xs="4 fade-in">
                      <b>Original Scroll</b>
                      <Card>
                        {task.media === "dom" ? (
                          <video
                            controls
                            replay
                            className="video-result"
                            controlsList="nodownload"
                            autoPlay
                            src={`/output/${task.upload_id}/original_scroll.mp4`}
                          ></video>
                        ) : null}
                      </Card>
                    </Col>
                    <Col xs="4 fade-in">
                      <b>VCO Scroll Heatmap</b>
                      <Card>
                        {task.media === "dom" ? (
                          <video
                            controls
                            replay
                            className="video-result"
                            controlsList="nodownload"
                            autoPlay
                            src={`/output/${task.upload_id}/scroll_heatmap.mp4`}
                          ></video>
                        ) : null}
                      </Card>
                    </Col>
                    <Col xs="4 fade-in">
                      <b>VCO Scroll Opacity map</b>
                      <Card>
                        {task.media === "dom" ? (
                          <video
                            controls
                            replay
                            className="video-result"
                            controlsList="nodownload"
                            autoPlay
                            src={`/output/${task.upload_id}/scroll_opacitymap.mp4`}
                          ></video>
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                ) : null}

                {(task.media === 'image') ? (
                  <>
                    {(vco_status === 'finished') ?
                      (<>

                        <>
                          <Row className="border-row">
                            <Col className="py-2 text-title " xs="4">

                            </Col>
                            <Col className="py-2 text-title dark-background" xs="8">
                              Description
                            </Col>
                          </Row>
                          <Row className="border-row">
                            <Col className="py-3" xs="4">
                              Image Description
                            </Col>
                            <Col className="py-3 dark-background" xs="8">
                              {vco_results.image_description}
                            </Col>
                          </Row>
                          <Row className=" border-row" >
                            <Col className="py-3" xs="4">
                              Intent
                            </Col>
                            <Col className="py-3 dark-background" xs="8">
                              {vco_results.intent}
                            </Col>
                          </Row>
                        </>
                        <br />
                        {/* Heat Map Details */}
                        <>
                          <Row>
                            <Col className="py-2" xs="3"></Col>
                            <Col className="py-2 text-title dark-background" xs="2">
                              Presence/Absence
                            </Col>
                            <Col className="py-2 text-title dark-background" xs="2">
                              Attention Level
                            </Col>
                            <Col className="py-2 text-title dark-background" xs="5">
                              Reason
                            </Col>
                          </Row>
                          {Object.keys(heatmap_description)
                            .filter((key) => {
                              const item = heatmap_description[key];
                              // Exclude "other information texts" and "other objects" if they are not present
                              if ((key === 'other information texts' || key === 'other objects' || key === 'other_information_texts' || key === 'other_objects') && (item.presence === 'not present' || item.presence==='not applicable' )) {
                                return false;
                              }
                              return true;
                            })
                            .map((key) => {
                              const item = heatmap_description[key];
                              return (
                                <Row className="border-row" key={key}>
                                  <Col className="py-3" xs="3">
                                    <Row>
                                      <Col xs="2">
                                        <Cube
                                          color={item.color === 'red' ? true : item.color === 'yellow' ? 'maybe' : false}
                                        ></Cube>
                                      </Col>
                                      <Col xs="10">{key}</Col>
                                    </Row>
                                  </Col>
                                  <Col className="py-3 dark-background" xs="2">
                                    {item.presence === 'not present' ? 'Absent' : 'Present'}
                                  </Col>
                                  <Col className="py-3 dark-background" xs="2">
                                    {
                                    (item.reason === 'not present' || item.reason === 'not applicable') ? '-' :
                                    ( item.color === 'red'
                                        ? 'High'
                                        : item.color === 'yellow'
                                          ? 'Medium'
                                          : 'Low'
                                      )}
                                  </Col>
                                  <Col className="py-3 dark-background" xs="5">
                                    {(item.reason === 'not present' || item.reason === 'not applicable') ? '-' : item.reason}
                                  </Col>
                                </Row>
                              );
                            })}
                        </>
                        <br />
                        {/*  Summarized Suggestion */}
                        <>
                          <Row className="border-row">
                            <Col className="py-2 text-title " xs="4">

                            </Col>
                            <Col className="py-3 text-title dark-background" xs="8">
                              Description
                            </Col>
                          </Row>
                          <Row className="border-row">
                            <Col className="py-3" xs="4">
                              Summarized Suggestion
                            </Col>
                            <Col className="py-3 dark-background" xs="8">
                              {vco_results.summarized_suggestion}
                            </Col>
                          </Row>
                        </>
                      </>
                      ) : ((task?.vco_results?.reason_for_failure === 'genAI quota expired') ?
                        <p style={{fontSize: 'large', textAlign:'center'}}>

                          Insights cannot be generated due to exhaustion of Gen AI 4.0 quota. Please reach the CRISP support team for quota increase.

                        </p>
                        : null)
                    } </>
                ) : null}
              </CheckBlockPresence>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default VcoTab;
