import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorJSON from "../utils/Error";
import axios from "axios";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import FormCreate from "./Form";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { ROOT_URL } from "../../constants";

class Previews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      enter: false,
      errors: false,
      progress: []
    };

    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  componentWillUnmount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  generateUUID() {
    var d = new Date().getTime();

    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        var r = (d + (array[0] % 16)) % 16;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  onDragEnter() {
    this.setState({ enter: true });
    this.props.expand("drop");
  }
  onDragLeave() {
    this.setState({ enter: false });
    this.props.expand("reset");
  }

  onDropRejected() {
    this.setState({ errors: { type: "This file type is not allowed." } });
  }

  cancel() {
    this.setState({
      enter: false,
      progress: [],
      files: [],
      errors: false,
    });
    this.props.expand("reset");
  }

  send(file, start, chunkSize) {
    const chunk = file.slice(start, start + chunkSize);
    const fd = new FormData();

    fd.append("num", start);
    fd.append("id", file.id);
    fd.append("data", chunk);

    return axios({
      method: "post",
      url: "/api/tasks/upload/",
      data: fd,
    })
      .then(() => {
        if (start + chunkSize <= file.size) {
          this.setState((prevState) => {
            return {
              files: prevState.files.map((el) =>
                el.id === file.id
                  ? { ...el, progress: parseInt((start / file.size) * 100) }
                  : el
              ),
            };
          });
          this.send(file, start + chunkSize, chunkSize);
        } else {
          axios
            .post("/api/tasks/uploadmerge/" , {
              id:file.id,
              ext: file.ext == "png" ? "jpeg" : file.ext,
            })
            .then(() => {
              this.setState((prevState) => {
                return {
                  files: prevState.files.map((el) =>
                    el.id === file.id ? { ...el, progress: 100 } : el
                  ),
                };
              });
            })
            .catch((e) => {
              this.setState((prevState) => {
                return {
                  errors: "The upload failed. Please try again later.",
                  files: prevState.files.filter((el) => el.id !== file.id),
                };
              });
            });
        }
      })
      .catch((err) => {
        this.setState((prevState) => {
          return {
            files: prevState.files.map((el) =>
              el.id === file.id ? { ...el, progress: 101 } : el
            ),
            errors: {
              ...prevState.errors,
              upload:
                "An upload failed. Please try again by refreshing the page. ",
            },
          };
        });
      });
  }


  uploadToS3(file){
    console.log("####################   UPLOAD FILE ###########################")    
    const params = file.type.split('/')
        const url = `${ROOT_URL}/api/tasks/getUploadURL?upload_type=`+params[0]+'&upload_extension='+params[1]+'&upload_id='+file.id
        axios.post(url).then(function (response) {
            // handle success
            const fileName = params[0]=='image' ? 'image.jpeg' : 'video.mp4'
            const uploadKey = `input/${params[0]}/${file.id}/${fileName}`
            uploadFiletoS3(response.data.upload_url,file, uploadKey)
        }).catch(function (error) {
            // handle error
        }).finally(function () {
            // always executed
        })

        const uploadFiletoS3 = (url, file, uploadKey)=>{
          fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type':file.type
            },
            body:file
        }).then(() => {
          createThumb(uploadKey)
         this.setState((prevState) => {
           return {
             files: prevState.files.map((el) =>
               el.id === file.id ? { ...el, progress: 50 } : el
             ),
           };
         });
       })
     
     }


     const createThumb = (uploadKey)=>{
      axios.post(`${ROOT_URL}/api/tasks/thumbcreate/` , {
              key:uploadKey
            }).then(() =>  {
              // handle success
              this.setState((prevState) => {
                return {
                  files: prevState.files.map((el) =>
                    el.id === file.id ? { ...el, progress: 100 } : el
                  ),
                };
              });
          }).catch(function (error) {
              // handle error
          }).finally(function () {
              // always executed
          })
     }
        
  }

   


  uploadFile(file) {
    Object.assign(file, {
       preview: URL.createObjectURL(file),
      progress: 0,
      id: this.generateUUID(),

      ext: file.type.slice(file.type.indexOf("/") + 1, file.type.length),
    });
    const media_ext = {
      mp4: "video",
      jpeg: "image",
      jpg: "image",
      png: "image",
      html: "dom",
    };

    var file_obj = (({ preview, ext, name, progress, id }) => ({
      preview,
      ext,
      name,
      progress,
      id,
    }))(file);
    file_obj["media"] = media_ext[file.ext];

    this.setState(
      (prevState) => ({
        files: [...prevState.files, file_obj],
      }),
      () => {
        const chunkSize = 40000;
        //this.send(file, 0, chunkSize);
        this.uploadToS3(file)
      }
    );
  }

  onDrop(acceptedFiles) {
    if (!this.state.enter) {
      this.setState({ enter: true });
      this.props.expand("drop");
    }

    var finalFiles = acceptedFiles.slice(
      0,
      Math.min(Math.min(1, acceptedFiles.length), 1 - this.state.files.length)
    );

    if (finalFiles.length !== acceptedFiles.length) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          size: "You cannot upload more than 1 file.",
        },
      }));
    }

    finalFiles.forEach((file) => {
     const ext = file.type.slice(file.type.indexOf("/") + 1, file.type.length)
      const validMedia = ['mp4','jpeg','jpg','png','html']
      if(validMedia.includes(ext)){
       console.log("this is valid ", ext)
      }
      else{
        console.log("this is invalid file type", ext)
        this.setState({ errors: { type: "This file type is not allowed.", ext } });
      }

      if (["image/jpeg", "image/png"].includes(file.type)) {
        if (file.size > 1024 * 1024 * 5 && !this.props.auth.user.admin) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              size: "File " + file.path + " is too big.",
            },
          }));
        } else {
          this.uploadFile(file);
        }
      } else if (["text/html"].includes(file.type)) {
        this.uploadFile(file);
      } else {
        var video = document.createElement("video");
        video.preload = "metadata";
        var _this = this;
        video.onloadedmetadata = function () {
          var duration = video.duration;

          if (file.size > 1024 * 1024 * 30 && !_this.props.auth.user.admin) {
            _this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                size: "File " + file.path + " is too big.",
              },
            }));
          } else if (duration > 61 && !_this.props.auth.user.admin) {
            _this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                size: `Video ${file.path} is too long (${duration}s). `,
              },
            }));
          } else {
            video.remove();
            _this.uploadFile(file);
          }
        };

        video.src = URL.createObjectURL(file);
      }
    });
  }

  render() {
    return (
      <Card>
        <CardBody className={this.state.enter ? "dz-active" : ""}>
          <ErrorJSON json={this.state.errors}></ErrorJSON>
          <Dropzone
            accept={["image/jpeg", "image/png", "video/mp4", "text/html"]}
            onDrop={this.onDrop}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDropRejected={this.onDropRejected}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="dropbox_text">
                  {" "}
                  <FontAwesomeIcon
                    className={
                      (this.state.files.length === 0 ? "" : "fade-out") +
                      " more-sm mr-3"
                    }
                    icon="plus-circle"
                  ></FontAwesomeIcon>
                  <span>DROP YOUR FILE OR CLICK TO UPLOAD</span>
                </div>

                <Row>
                  <Col xs={5} className="thumbcontainer">
{this.state.errors? (
                          <>
                            {console.log("unsupported file type")}
                            <div>
                              <span className="unsupported-message">Unsupported file type</span>
                            </div>
                          </>
                        ) :null}

                    {this.state.files.map((file) => (
                      <div
                        className={
                          (file.progress === 100 ? "loaded" : "inprogress") +
                          " fade-in img-thumb"
                        }
                        key={file.id}
                      >
                        <Progress
                          color={file.progress === 101 ? "danger" : "purple"}
                          className={file.progress === 100 ? "fade-out" : ""}
                          value={file.progress}
                        />
                        {file.ext === "mp4" ? (
                          <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            src={file.preview}
                            className="thumb"
                          ></video>
                        ) : file.ext === "html" ? (
                          <div>
                            <div className="name-html">{file.name}</div>
                            <img src="/img/html.png" className="thumb" />
                          </div>
                        ) : (
                          <img src={file.preview} className="thumb" />
                        )}
                      </div>
                    ))}
                  </Col>

                  <Col xs={7}>
                    <FormCreate
                      method="upload"
                      files={this.state.files}
                      cancel={this.cancel}
                      className={this.state.enter ? "form-active " : ""}
                    ></FormCreate>
                  </Col>
                </Row>
              </div>
            )}
          </Dropzone>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Previews);
