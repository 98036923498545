import React from "react";
import {
  Row,
  Col,
  Progress
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class DomInclusive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      inclusiveResults: null,
      modalContent: '',
      InitialResultsError: '',
      InitialResults: null
    };
  }

  componentDidMount() {
    this.getInclusiveResult();
  }
  getInclusiveResult() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl?.results?.inclusive_result
      results = initialResults?.results?.inclusive_result;
    }
    else {
      initialResults = this.props.dataImage?.results?.inclusivity_prompt
      results = initialResults?.results?.results?.inclusive_result;
    }

    if (results) {
      this.setState({
        inclusiveResults: results[0],
        InitialResults: initialResults
      });
    } else {
      if (this.props.media === 'dom') {
        initialResults = initialResults
      }
      else {
        if (initialResults?.status === 'finished') {
          initialResults = initialResults.results

        }
      }
      if (initialResults?.status === 'error') {
        this.setState({ InitialResults: initialResults })

        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          inclusiveResults: null,
          InitialResultsError: words?.join(" ")
        });
      }

    }
  }
  setShowModal = (e, showModal, content) => {
    if (e) { e.preventDefault() }
    this.setState({ showModal: showModal });
    this.setState({ modalContent: content })
  };
  prepareContent = (item) => {
    let result = [];
    const keysToRender = [
      "Race",
      "Gender",
      "Immigrants",
      "LGBTQ", 
      "Age",
      "Physical Disability",
      "Mental Health",
      "Religion",
    ];
    keysToRender.forEach((key) => {
      if (item[key] && !item[key].some(value => ["No", "None", "Non", " "].includes(value))) {
        item[key].forEach((value) => {
          // Split value by comma and add each part as a separate entry
          value.split(',').forEach((subValue) => {
            result.push({ category: key, value: subValue.trim() });
          });
        });
      }
    });

    return result;
  };
  render() {
    const { inclusiveResults, showModal, InitialResults, InitialResultsError } = this.state;
    const NonInclusiveContent = (inclusiveResults?.Result === 'Non Inclusive') ? inclusiveResults : null;
    const preparedContent = NonInclusiveContent ? this.prepareContent(NonInclusiveContent) : [];

    const ContentDisplay = ({ content }) => {
      // Specific keys to render
   

      // Function to extract and prepare content for the table
    


      return (
        <div>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Sentence</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
              </tr>
            </thead>
            <tbody>
              {preparedContent.map((entry, idx) => (
                <tr key={idx}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.value}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };
    return (
      <>
        <>
          <Row>
            <Col className="py-2" xs="4"></Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RESULT
            </Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RECOMMENDED
            </Col>
          </Row>
          <Row className="border-row">
            <Col className=" py-3" xs="4">
              <Row>
                <Col xs="2">
                  <Cube
                    color={
                      (InitialResults?.status === 'error') ? 'maybe' : (NonInclusiveContent ? false : true)

                    }
                  ></Cube>
                </Col>
                <Col xs="10">Inclusive
                </Col>
              </Row>
            </Col>
            {NonInclusiveContent ? (
              <>
                <Col className="py-3 dark-background" xs="4">
                Non-Inclusive words detected
                  <ul>
                    <li>Age: {(['No', 'None', 'Non'].includes(inclusiveResults['Age'][0]) || inclusiveResults['Age'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Age']) ) ? 'No' : 'Yes'}
                    </li>
                    <li>Gender: {(['No', 'None', 'Non'].includes(inclusiveResults['Gender'][0]) || inclusiveResults['Gender'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Gender'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Immigrants: {(['No', 'None', 'Non'].includes(inclusiveResults['Immigrants'][0])|| inclusiveResults['Immigrants'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Immigrants'])) ? 'No' : 'Yes'}
                    </li>
                    <li>LGBTQ: {(['No', 'None', 'Non'].includes(inclusiveResults['LGBTQ'][0]) || inclusiveResults['LGBTQ'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['LGBTQ'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Mental Health: {(['No', 'None', 'Non'].includes(inclusiveResults['Mental Health'][0]) || inclusiveResults['Mental Health'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Mental Health'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Physical Disability: {(['No', 'None', 'Non'].includes(inclusiveResults['Physical Disability'][0]) || inclusiveResults['Physical Disability'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Physical Disability'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Race: {(['No', 'None', 'Non'].includes(inclusiveResults['Race'][0]) || inclusiveResults['Race'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Race'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Religion: {(['No', 'None', 'Non'].includes(inclusiveResults['Religion'][0]) || inclusiveResults['Religion'].length<1 || ['No', 'None', 'Non'].includes(inclusiveResults['Religion'])) ? 'No' : 'Yes'}
                    </li>
                  </ul>
                  {inclusiveResults['Result'] === 'Non Inclusive' ?
                    <>
                      <a href='_blank' style={{ marginLeft: '4px' }} onClick={(e) => this.setShowModal(e, true, 'info')}> More Info... </a>
                      <br />
                      <a href='_blank' style={{ marginLeft: '4px' }} onClick={(e) => this.setShowModal(e, true, 'revised')}> Revised Statement </a>
                    </>
                    : null
                  }
                  <Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>  Inclusivity </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                      {(this.state.modalContent === 'info')
                        ?
                        <ContentDisplay content={preparedContent} />
                        :
                        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
                          <thead>
                            <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                              <th style={{ border: '1px solid black', padding: '8px' }}> Revised Statement</th>
                            </tr></thead>
                          <tbody>
                            {inclusiveResults['Revised Content']}
                          </tbody>
                        </table>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => this.setShowModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </>
            )
              : (<>
                <Col className="py-3 dark-background" xs="4">
                  <>
                  {(inclusiveResults?.Result === 'No text detected') ? 'No text detected' :
                    ((InitialResults?.status === 'error') ? (
                      InitialResultsError || 'Unknown error'
                    ) : (
                      'Content is inclusive'
                    ))}
                  </>
                </Col>
              </>)
            }
            <Col className="py-3 dark-background" xs="4">
              Good to have content which is inclusive of age, gender, religion, race, neurodiversity, differently abled people
            </Col>
          </Row>
        </>
      </>
    );
  }
}



export default DomInclusive;