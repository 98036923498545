import { SET_WEB_SOCKET, SET_CURRENT_URL } from "./types";

// Set logged in user
export const setTaskStatus = (task) => {
  return {
    type: SET_WEB_SOCKET,
    task_data: task,
  };
};

export const setCurrentUser = (urlParams) => {
  return {
    type: SET_CURRENT_URL,
    urlParams: urlParams,
  };
};
