import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Card, CardBody, Row, Col, Input, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorJSON from "../utils/Error";
import axios from "axios";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import FormCreate from "./Form";
import striptags from "striptags";
import { ROOT_URL } from "../../constants";
import store from "../../store";
import { setCurrentUser } from "../../actions/taskActions";
import jwt_decode from "jwt-decode";

class Previews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: false,
      screenshot: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  generateUUID() {
    var d = new Date().getTime();
    /* 
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    } */

    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        var r = (array[0] % 16) % 16;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
  }

  screenshot() {
    const token = localStorage.jwtToken;
    const decoded = jwt_decode(token);
    this.setState({ loading: true });
    var id = this.generateUUID();

    const urlParams = { id: id, url: this.state.url };
    store.dispatch(setCurrentUser(urlParams));
    axios.post(`${ROOT_URL}/api/tasks/url/`, {
      id: id,
      url: this.state.url,
      userId: decoded.id,
    });

    localStorage.setItem('taskID', id)
    

    // .then((res) => {
    //   this.setState({
    //     screenshot: "data:image/jpeg;base64, " + res.data.screenshot,
    //     files: [
    //       {
    //         id: id,
    //         name: this.state.url,
    //         ext: res.data.media == "mp4" ? "mp4" : "jpeg",
    //         media: res.data.media,
    //         progress: 100,
    //       },
    //     ],
    //     errors: false,
    //     loading: false,
    //   });
    //   this.props.expand("url");
    // })
    // .catch((err) => {
    //   if (!err.response || !err.response.data) {
    //     this.setState({ errors: err.message, loading: false });
    //   } else {
    //     this.setState({ errors: err.response.data, loading: false });
    //   }
    // });
  }
  componentDidUpdate(prevProps){
    if(this.props.screenShot !== prevProps.screenShot){
    
      this.updateScreenShot(this.props.screenShot)
    }

  }
   updateScreenShot = (res) => {
    console.log(res.screenShot.media);
    this.setState({
      screenshot: `/input/dom/${res.screenShot.taskid}/image.jpeg`,
      files: [
        {
          id: res.screenShot.taskid,
          name: this.state.url,
          ext: res.screenShot.media == "mp4" ? "mp4" : "jpeg",
          media: res.screenShot.media,
          progress: 100,
        },
      ],
      errors: false,
      loading: false,
    });
    this.props.expand("url");
  };
  onKeyDown(event) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.screenshot();
    }
  }

  cancel() {
    this.setState({
      screenshot: false,
      errors: false,
    });
    this.props.expand("reset");
  }

  render() {
    return (
      <Card>
        <CardBody>
          <ErrorJSON json={this.state.errors}></ErrorJSON>
          <Row>
            <Col xs={5}>
              <div className="dropbox_text">
                {" "}
                <FontAwesomeIcon
                  className={
                    (this.state.screenshot == false ? "" : "fade-out") +
                    " more-sm mr-3"
                  }
                  icon="globe"
                ></FontAwesomeIcon>
                <span>TYPE URL</span>
              </div>
            </Col>
            <Col className="text-center dropbox_text">
              <div className="d-flex">
                <Input
                  className="mr-3 rounded-pill"
                  type="url"
                  name="url"
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  placeholder="http://example.com"
                  value={this.state.url}
                />
                <FontAwesomeIcon
                  onClick={this.screenshot}
                  className={
                    (this.state.loading ? "spinner-rotate" : "") +
                    " m-auto search-input-icon clickable"
                  }
                  icon={this.state.loading ? "spinner" : "search"}
                ></FontAwesomeIcon>
              </div>
            </Col>
          </Row>

          {this.state.screenshot == false ? (
            <span></span>
          ) : (
            <Row>
              <Col xs={5} className="thumbcontainer-url">
                <img src={this.state.screenshot}></img>
              </Col>

              <Col xs={7}>
                <FormCreate
                  method="url"
                  files={this.state.files}
                  cancel={this.cancel}
                ></FormCreate>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  screenShot: state.screen_shot,
});

export default connect(mapStateToProps)(Previews);
