import { SET_WEB_SOCKET } from "../actions/types";

const initialState = {
  taskData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WEB_SOCKET:
      return {
        ...state,
        taskData: action.task_data,
      };

    default:
      return state;
  }
}
