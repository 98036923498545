import React from "react";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import axios from "axios";
import ErrorJSON from "../../utils/Error";
import ImageObjects from "./../blocks/ImageObjects";
import ImageFormats from "./../blocks/ImageFormats";
import ImageSafety from "./../blocks/ImageSafety";
import ImageLogos from "./../blocks/ImageLogos";
import ImageColorblind from "./../blocks/ImageColorblind";
import ImageAccessibility from "./../blocks/ImageAccessibility";
import ImageCopywriting from "./../blocks/ImageCopywriting";
import ImageLabels from "./../blocks/ImageLabels";
import ImageTextRatio from "./../blocks/ImageTextRatio";
import ImageColorblindAccess from "./../blocks/ImageColorblindAccess";
import Score from "./scoring";
import SampleNotImplemented from "../blocks/SampleNotImplemented";
import EditBlock from "../blocks/EditBlock";
import DomNavigation from "../blocks/DomNavigation";
import DomLogo from "../blocks/DomLogo";
import DomRendering from "../blocks/DomRendering";
import DomImage from "../blocks/DomImage";
import DomCTA from "../blocks/DomCTA";
import DomText from "../blocks/DomText";
import DomForm from "../blocks/DomForm";
import 'react-lazy-load-image-component/src/effects/blur.css';
import DomMobileux from "../blocks/DomMobileux";
import VideoFormats from "../blocks/VideoFormat";
import VideoQuality from "../blocks/VideoQuality";
import VideoComposition from "../blocks/VideoComposition";
import VideoUsability from "../blocks/VideoSafety";
import VideoMessage from "../blocks/VideoMessage";
import VideoScore from "./videoScoring"
import VideoSentiment from "../blocks/VideoSentiment";
import VideoShot from "../blocks/VideoShot";
import DomToxicity from "../blocks/DOMToxicity";
import DomTone from "../blocks/DOMTone";
import DomInclusive from "../blocks/DOMInclusive";
import DomComplex from "../blocks/DOMComplex";
import DomGrammar from "../blocks/DOMGrammar";
import ImageGenAiComposition from "../blocks/ImageGenAiComposition";
import ImageGenAiFormat from "../blocks/ImageGenAiFormat";



class ReportTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scoreFormat: 0,
      scoreQuality: 0,
      scoreAccessibility: 0,
      scoreCopywriting: 0,
      scoreEmotion: 0,
      scoreMemorability: 0,
      scoreTextRatio: 0,
      scoreSafety: 0,
      scoreDomCTA: 0,
      scoreDomText: 0,
      scoreDomNavigation: 0,
      scoreDomMobile: 0,
      genAiError: false,
      genAiError4: false
    };
    this.getScoreFormat = this.getScoreFormat.bind(this);
    this.getScoreQuality = this.getScoreQuality.bind(this);

    this.getScoreSafety = this.getScoreSafety.bind(this);
    this.getScoreAccessibility = this.getScoreAccessibility.bind(this);
    this.getScoreCopywriting = this.getScoreCopywriting.bind(this);
    this.getScoreEmotion = this.getScoreEmotion.bind(this);
    this.getScoreMemorability = this.getScoreMemorability.bind(this);
    this.getScoreTextRatio = this.getScoreTextRatio.bind(this);
    this.getScoreDomCTA = this.getScoreDomCTA.bind(this);
    this.getScoreDomText = this.getScoreDomText.bind(this);
    this.getScoreDomMobile = this.getScoreDomMobile.bind(this);
    this.getScoreDomNavigation = this.getScoreDomNavigation.bind(this);
    this.showError = this.showError.bind(this)
    this.showError4 = this.showError4.bind(this)

  }

  getScoreFormat(score) {
    this.setState({ scoreFormat: score });
  }

  getScoreQuality(score) {
    this.setState({ scoreQuality: score });
  }

  getScoreTextRatio(score) {
    this.setState({ scoreTextRatio: score });
  }

  getScoreAccessibility(score) {
    this.setState({ scoreAccessibility: score });
  }

  getScoreCopywriting(score) {
    this.setState({ scoreCopywriting: score });
  }

  getScoreEmotion(score) {
    this.setState({ scoreEmotion: score });
  }

  getScoreMemorability(score) {
    this.setState({ scoreMemorability: score });
  }

  getScoreSafety(score) {
    this.setState({ scoreSafety: score });
  }

  getScoreDomCTA(score) {
    this.setState({ scoreDomCTA: score });
  }

  getScoreDomText(score) {
    this.setState({ scoreDomText: score });
  }

  getScoreDomNavigation(score) {
    this.setState({ scoreDomNavigation: score });
  }

  getScoreDomMobile(score) {
    this.setState({ scoreDomMobile: score });
  }

  showError() {
    this.setState(
      { genAiError: true }
    );
  }
  showError4() {
    this.setState(
      { genAiError4: true }
    );
  }

  reprocess(todo) {
    var task = this.props.task;
    if (todo === "report" && (task.industry === "" || task.type === "")) {
      this.setState({
        submitted: false,
        errors: "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }
    if (todo === "report" && task.tasks.indexOf("memorability") === -1) {
      todo = ["report", "memorability"]
    }
    else {
      todo = [todo]
    }
    axios
      .post("/api/tasks/edit", {
        ...task,
        tasks: task.tasks.concat(todo),
        id: task._id,
      })
      .then((res) => {
        this.props.history.push("/projects/" + task.project._id);
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        }
        else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    for (let key in task.mcu_results) {
      task.analysis[key] = task.mcu_results[key];
    }
    return (
      <>
        {
          !task.analysis
            ? ""
            : <>
              <EditBlock
                ref={(modal) => { this.editRef = modal; }}
                id={task._id}
              ></EditBlock>

              {
                !access?.includes("report")
                  ? <div className="px-4">

                    <CheckBlockPresence analysis={task.analysis} block="vco">
                      <Row>
                        <Col xs="12 fade-in" className="disabled-grayscale">
                          <Row>
                            <div className="text-center mt-3 mb-3 w-100">
                              {
                                task.media === "video"
                                  ? <h3 className="warning">
                                    This functionnality is not available for videos
                                    yet, here is a sample for an image:
                                  </h3>
                                  : !access?.includes("report")
                                    ? <h3 className="warning">
                                      This functionnality is not available in your plan,
                                      here is a sample report. To get access, please
                                      contact{" "}
                                      <a href="mailto:ai4c_crisp_support@accenture.com">
                                        ai4c_crisp_support@accenture.com
                                      </a>
                                      . Thank you!
                                    </h3>
                                    : <div>
                                      {
                                        this.state.errors
                                          ? <ErrorJSON json={this.state.errors}></ErrorJSON>
                                          : ""
                                      }
                                      {" "}
                                      <h3 className="success">
                                        This functionnality is available. <br></br>{" "}
                                        Click on the button to request a Creativity Report for this
                                        content. <br></br>{" "}
                                      </h3>
                                      <Button onClick={() => this.reprocess("report")} color="danger">
                                        PROCESS
                                      </Button>
                                    </div>
                              }
                            </div>
                          </Row>
                          <Row>
                            <img top width="100%" className="card-img-results" src={"/img/creativity-sample-cropped.png"} alt="Card image cap" />
                          </Row>
                        </Col>
                      </Row>
                    </CheckBlockPresence>
                  </div>
                  : <div>
                    <Row className="px-4 predictiveFull">
                      <Col className="mt-4 sticky-menu" xs="2">
                        <Row>
                          <Col className="category-link">Overview</Col>
                        </Row>
                        {task.media !== 'dom' ? (<><hr className="solid"></hr>
                          <Row>
                            <Col className="category-link">
                              <a className="normal-a" href="#format">
                                Format
                              </a>
                            </Col>
                          </Row>
                        </>) : null}

                        <hr className="solid"></hr>
                        {(task.media == 'video') ?
                          <>
                            <Row>
                              <Col className="category-link">
                                <a className="normal-a" href="#quality">
                                  Quality
                                </a>
                              </Col>
                            </Row>
                            <hr className="solid"></hr>
                          </> : null
                        }

                        {/* </CheckBlockPresence> */}
                        <Row>
                          <Col className="category-link">
                            <a className="normal-a" href="#composition">
                              Composition
                            </a>
                          </Col>
                        </Row>
                        <hr className="solid"></hr>
                        <Row>
                          <Col className="category-link">
                            <a className="normal-a" href="#usability">
                              Usability
                            </a>
                          </Col>
                        </Row>
                        <hr className="solid"></hr>
                        <Row>
                          <Col className="category-link">
                            <a className="normal-a" href="#message">
                              Message
                            </a>
                          </Col>
                        </Row>
                        <hr className="solid"></hr>
                        {
                          task.analysis.dom_mobileux && task.analysis.dom_mobileux.status == "finished" &&
                          <><Row>
                            <Col className="category-link">
                              <a className="normal-a" href="#mobile">
                                Mobile
                              </a>
                            </Col>
                          </Row>
                            <hr className="solid"></hr>
                          </>
                        }
                      </Col>

                      {task.media == 'dom' || task.media == 'image' ?
                        <Col className="mt-4" xs="10">
                          {this.state.genAiError && this.state.genAiError4 ? (
                            <span>Your default GenAI 3.5 and 4.0 quotas have exceeded. If your quota needs to be increased, please reach the CRISP support team.</span>
                          ) : this.state.genAiError ? (
                            <span>Your default GenAI 3.5 quota has exceeded. If your quota needs to be increased, please reach the CRISP support team.</span>
                          ) : this.state.genAiError4 ? (
                            <span>Your default GenAI 4.0 quota has exceeded. If your quota needs to be increased, please reach the CRISP support team.</span>
                          ) : null}


                          {/* Score rounds */}
                          <Score task={task} state={this.state}></Score>

                          {/* Format */}
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_format"
                          >
                            <ImageFormats
                              id={task._id}
                              data={task.analysis.image_format}
                              token={token}
                              generateScore={this.getScoreFormat}
                            ></ImageFormats>
                            {task.media == 'image' ?
                              //   <CheckBlockPresence
                              //   analysis={task.analysis}
                              // >
                              <ImageGenAiFormat
                                id={task._id}
                                dataUrl={task.analysis.dom_text}
                                dataImage={task.analysis.imagegenAI}
                                media={task.media}
                                token={token}
                                showError={this.showError4}
                              // generateScore={this.getScoreDomCTA}
                              ></ImageGenAiFormat>
                              // </CheckBlockPresence>
                              : null}
                          </CheckBlockPresence>
                          {/* Composition */}
                          <Row id="composition" className="mt-4">
                            <Col xs="12 fade-in">
                              <Row>
                                <div className="category-text">COMPOSITION</div>
                              </Row>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="notimplemented"
                              >
                                <SampleNotImplemented
                                  id={task._id}
                                  data={task.analysis.notimplemented}
                                  token={token}
                                  editRef={this.editRef}
                                ></SampleNotImplemented>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_navigation"
                              >
                                <DomNavigation
                                  id={task._id}
                                  data={task.analysis.dom_navigation}
                                  token={token}
                                  generateScore={this.getScoreDomNavigation}
                                ></DomNavigation>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_logo"
                              >
                                <DomLogo
                                  id={task._id}
                                  data={task.analysis.dom_logo}
                                  token={token}
                                  uploadID={task.upload_id}
                                ></DomLogo>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_object"
                              >
                                <ImageObjects
                                  uploadID={task.upload_id}
                                  id={task._id}
                                  data={task.analysis.image_object}
                                  token={token}
                                ></ImageObjects>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_logo"
                              >
                                <ImageLogos
                                  uploadID={task.upload_id}
                                  id={task._id}
                                  data={task.analysis.image_logo}
                                  token={token}
                                ></ImageLogos>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_label"
                              >
                                <ImageLabels
                                  id={task._id}
                                  data={task.analysis.image_label}
                                  token={token}
                                ></ImageLabels>
                              </CheckBlockPresence>
                              {
                                !task.analysis.image_copywriting?.results.text_presence?.results?.textPresence
                                  ? ""
                                  : <CheckBlockPresence
                                    analysis={task.analysis}
                                    block="image_text_ratio"
                                  >
                                    <ImageTextRatio
                                      id={task._id}
                                      data={task.analysis.image_text_ratio}
                                      token={token}
                                      generateScore={this.getScoreTextRatio}
                                    ></ImageTextRatio>
                                  </CheckBlockPresence>
                              }
                              <br />
                              {task.media == 'image' ?
                                //   <CheckBlockPresence
                                //   analysis={task.analysis}
                                // >
                                <ImageGenAiComposition
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.imagegenAI}
                                  media={task.media}
                                  token={token}
                                  showError={this.showError4}
                                // generateScore={this.getScoreDomCTA}
                                ></ImageGenAiComposition>
                                // </CheckBlockPresence>
                                : null}

                              <br />
                            </Col>
                          </Row>

                          {/* Usability */}
                          <Row id="usability" className="mt-4">
                            <Col xs="12 fade-in">
                              <Row>
                                <div className="category-text">USABILITY</div>
                              </Row>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_cta"
                              >
                                <DomCTA
                                  id={task._id}
                                  data={task.analysis.dom_cta}
                                  token={token}
                                  generateScore={this.getScoreDomCTA}
                                ></DomCTA>
                              </CheckBlockPresence>
                              <br />
                              {task.media == 'dom' || task.media == 'image' ?
                                //   <CheckBlockPresence
                                //   analysis={task.analysis}
                                // >
                                <DomToxicity
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.image_copywriting}
                                  media={task.media}
                                  token={token}
                                // generateScore={this.getScoreDomCTA}
                                ></DomToxicity>
                                // </CheckBlockPresence>
                                : null}

                              <br />
                              {task.media == 'dom' || task.media == 'image' ?
                                <DomTone
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.image_copywriting}
                                  media={task.media}
                                  token={token}
                                // generateScore={this.getScoreDomCTA}
                                ></DomTone>
                                : null}
                              <br />
                              {task.media == 'dom' || task.media == 'image' ?
                                <DomInclusive
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.image_copywriting}
                                  media={task.media}
                                  token={token}
                                // generateScore={this.getScoreDomCTA}
                                ></DomInclusive>
                                : null}
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_form"
                              >
                                <DomForm
                                  id={task._id}
                                  data={task.analysis.dom_form}
                                  token={token}

                                ></DomForm>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_safety"
                              >
                                <ImageSafety
                                  id={task._id}
                                  data={task.analysis.image_safety}
                                  uploadID={task.upload_id}
                                  token={token}
                                  generateScore={this.getScoreSafety}
                                  personFaces={task.analysis?.image_emotion?.results?.number_humans?.results}
                                  dataEmotions={task.analysis.image_emotion}
                                  generateScoreEmotions={this.getScoreEmotion}
                                ></ImageSafety>
                              </CheckBlockPresence>
                              {
                                !task.analysis.image_copywriting?.results.text_presence?.results?.textPresence
                                  ? ""
                                  : <CheckBlockPresence
                                    analysis={task.analysis}
                                    block="image_accessibility"
                                  >
                                    <ImageAccessibility
                                      id={task._id}
                                      data={task.analysis.image_accessibility}
                                      token={token}
                                      generateScore={this.getScoreAccessibility}
                                    ></ImageAccessibility>
                                  </CheckBlockPresence>
                              }
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_colorblind"
                              >
                                <ImageColorblind
                                  uploadID={task.upload_id}
                                  id={task._id}
                                  data={task.analysis.image_colorblind}
                                  token={token}
                                ></ImageColorblind>
                              </CheckBlockPresence>
                              {
                                !task.analysis.image_copywriting?.results.text_presence?.results?.textPresence
                                  ? ""
                                  : <CheckBlockPresence
                                    analysis={task.analysis}
                                    block="image_accessibility"
                                  >
                                    <ImageColorblindAccess
                                      id={task._id}
                                      data={task.analysis.image_accessibility}
                                      token={token}
                                    ></ImageColorblindAccess>
                                  </CheckBlockPresence>
                              }
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_image"
                              >
                                <DomImage
                                  id={task._id}
                                  data={task.analysis.dom_image}
                                  token={token}
                                  uploadId={task.upload_id}
                                ></DomImage>
                              </CheckBlockPresence>
                            </Col>
                          </Row>

                          {/* Message */}
                          <Row id="message" className="mt-4">
                            <Col xs="12 fade-in">
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="image_copywriting"
                              >
                                <ImageCopywriting
                                  id={task._id}
                                  data={task.analysis.image_copywriting}
                                  token={token}
                                  generateScore={this.getScoreCopywriting}
                                ></ImageCopywriting>
                              </CheckBlockPresence>
                              <CheckBlockPresence
                                analysis={task.analysis}
                                block="dom_text"
                              >
                                <DomText
                                  id={task._id}
                                  data={task.analysis.dom_text}
                                  token={token}
                                  generateScore={this.getScoreDomText}
                                ></DomText>
                              </CheckBlockPresence>
                              {task.media == 'dom' || task.media == 'image' ?
                                <DomComplex
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.image_copywriting}
                                  media={task.media}
                                  token={token}
                                  showError={this.showError}
                                // generateScore={this.getScoreDomCTA}
                                ></DomComplex>
                                : null}
                              <br />
                              {task.media == 'dom' || task.media == 'image' ?
                                <DomGrammar
                                  id={task._id}
                                  dataUrl={task.analysis.dom_text}
                                  dataImage={task.analysis.image_copywriting}
                                  media={task.media}
                                  token={token}
                                // generateScore={this.getScoreDomCTA}
                                ></DomGrammar>
                                : null}
                            </Col>
                          </Row>

                          {/* Mobile Page Analysis */}
                          <Row id="mobile" className="mt-4">
                            <Col xs="12 fade-in">
                              <CheckBlockPresence analysis={task.analysis} block="dom_mobileux">
                                <DomMobileux
                                  id={task._id}
                                  data={task.analysis.dom_mobileux}
                                  token={token}
                                  generateScore={this.getScoreDomMobile}
                                >
                                </DomMobileux>
                              </CheckBlockPresence>
                            </Col>
                          </Row>

                          {/* Device rendering */}
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="dom_rendering"
                          >
                            <DomRendering
                              id={task._id}
                              data={task.analysis.dom_rendering}
                              token={token}
                              upload_id={task.upload_id}
                            ></DomRendering>
                          </CheckBlockPresence>

                        </Col>
                        :
                        <Col className="mt-4" xs="10">
                          {/* Score rounds */}
                          <VideoScore task={task} state={this.state}></VideoScore>
                          {/* Format */}
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="videopredictivedesign"
                          >
                            <VideoFormats
                              id={task._id}
                              data={task.analysis.mcu_format}
                              token={token}
                              generateScore={this.getScoreFormat}
                            ></VideoFormats>

                            <VideoQuality
                              id={task._id}
                              data={task.analysis.mcu_quality}
                              token={token}
                              generateScore={this.getScoreQuality}
                            ></VideoQuality>
                            <VideoComposition
                              id={task._id}
                              data={task.analysis}
                              token={token}
                              generateScore={this.getScoreFormat}
                            ></VideoComposition>

                            <VideoUsability
                              id={task._id}
                              data={task.analysis.mcu_safety}
                              token={token}
                              generateScore={this.getScoreFormat}>
                            </VideoUsability>
                            <VideoMessage id={task._id}
                              data={task.analysis}
                              token={token}
                              generateScore={this.getScoreFormat}>

                            </VideoMessage>
                            <VideoSentiment id={task._id}
                              data={task.analysis}
                              token={token}>

                            </VideoSentiment>


                            <VideoShot id={task._id}
                              data={task.analysis}
                              token={token}>

                            </VideoShot>
                          </CheckBlockPresence>



                        </Col>

                      }
                    </Row>
                  </div>
              }
            </>
        }
      </>
    );
  }
}

const Label = (props) => {
  return props.result == true ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == false ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "Maybe" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  );
};

// If the results are available, display them
const CheckBlockPresence = (props) => {
  if (!props.analysis) {
    return "";
  }
  else {
    return !props.analysis[props.block]
      ? props.default
        ? props.default
        : ""
      : props.analysis[props.block].status !== "finished"
        ? props.default
          ? props.default
          : ""
        : props.children
  }
}

const Cube = (props) => {
  return props.color == true
    ? <img src={"/img/IconPassBig.png"} className="icon-score"></img>
    : props.color == "maybe"
      ? <img src={"/img/IconWarningBig.png"} className="icon-score"></img>
      : <img src={"/img/IconFailBig.png"} className="icon-score"></img>
}

export { ReportTab, CheckBlockPresence, Cube };