import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import View from "./View";

class ProjectRoutes extends React.Component {
  render() {
    console.log("iniside project routes");
    return (
      <Routes>
        <Route path="/projects/:id" element={<View />} />
        <Route render={() => <Navigate to="/404" />} />
      </Routes>
    );
  }
}

export default ProjectRoutes;
