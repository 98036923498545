import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class DomComplex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ToxicResult: null,
      showModal: false,
      currentCategory: '',
      toxicCount: null,
      categoryCounts: null,
      InitialResults: null,
      InitialResultsError: ''
    };
  }

  componentDidMount() {
    this.countToxicEntries();

  }
  countToxicEntries() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl?.results?.complex_result;
      results = initialResults?.results?.complex_result;
    } else {
      initialResults = this.props.dataImage?.results?.complex_prompt?.results;
      results = initialResults?.results?.complex_result;
    }

    if (this.props.media === 'dom') {
      initialResults = initialResults
    }
    else {
      if (initialResults?.status === 'finished') {
        initialResults = initialResults.results

      }
    }
    if (initialResults?.status === 'error') {
      this.setState({ InitialResults: initialResults })
      if (initialResults.reason_for_failure === 'genAI quota expired') {
        this.props.showError()
      }
    }

    if (results) {
      this.setState({
        ToxicResult: results[0],
        InitialResults: initialResults
      });
    } else {
      if (initialResults?.status === 'error') {
        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          ToxicResult: null,
          InitialResultsError: words?.join(" ")
        });
      }
    }
  }

  setShowModal = (e, showModal, category) => {
    if (e) { e.preventDefault() }
    this.setState({ showModal: showModal, currentCategory: category });
  };
  prepareContent = (item) => {
    const keysToRender = [
      "Acronym",
      "Colloquialism / slang",
      "Compound Words",
      "Jargon",
    ];

    let result = [];

    keysToRender.forEach((key) => {
      if (Array.isArray(item[key])) {
        // Handle the case where item[key] is an array
        if (!item[key].some(value => ["No", "None", "Non", " "].includes(value))) {
            item[key].forEach((value) => {
                value.split(',').forEach((subValue) => {
                    result.push({ category: key, value: subValue.trim() });
                });
            });
        }
    } else if (typeof item[key] === 'string') {
        // Handle the case where item[key] is a string
        if (!["No", "None", "Non", " "].includes(item[key])) {
            item[key].split(',').forEach((subValue) => {
                result.push({ category: key, value: subValue.trim() });
            });
        }
    }
    });

    return result;
  };
  render() {
    const { ToxicResult, showModal, InitialResults, InitialResultsError } = this.state
    const ToxicContent = ToxicResult?.Result === 'Complex Language' ? ToxicResult : null;
    const preparedContent = ToxicContent ? this.prepareContent(ToxicContent) : [];
    const ContentDisplay = ({ content }) => (
      <div>
        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
              <th style={{ border: '1px solid black', padding: '8px' }}>Sentence</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
            </tr>
          </thead>
          <tbody>
            {content.map((entry, idx) => (
              <tr key={idx}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{entry.value}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{entry.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
 
    return (
      <>


        <>
          <Row>
            <Col className="py-2" xs="4"></Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RESULT
            </Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RECOMMENDED
            </Col>
          </Row>
          <Row className="border-row">
            <Col className=" py-3" xs="4">
              <Row>
                <Col xs="2">
                  <Cube
                    color={
                      (InitialResults?.status === 'error') ? 'maybe' : (ToxicContent ? false : true)
                    }
                  ></Cube>
                </Col>
                <Col xs="10">Complexity
                  {/* {this.props.data.results.CTA_presence?.results?.reco_neg?.trim() &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="cta-presence-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="cta-presence-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.CTA_presence?.results?.reco_neg}
                    </ReactTooltip> */}
                </Col>
              </Row>
            </Col>
            {ToxicContent ? (
              <Col className="py-3 dark-background" xs="4">
                <>
                  Complex words detected
                  <ul>
                    <li>Academic Tone: {(['No', 'None', 'Non'].includes(ToxicResult['Academic Tone'][0]) || ToxicResult['Academic Tone'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Academic Tone'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Acronym: {(['No', 'None', 'Non'].includes(ToxicResult['Acronym'][0]) || ToxicResult['Acronym'].length < 1  || ['No', 'None', 'Non'].includes(ToxicResult['Acronym'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Colloquialism / slang:  {(['No', 'None', 'Non'].includes(ToxicResult['Colloquialism / slang'][0]) || ToxicResult['Colloquialism / slang'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Colloquialism / slang'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Compound Words:{(['No', 'None', 'Non'].includes(ToxicResult['Compound Words'][0]) || ToxicResult['Compound Words'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Compound Words'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Jargon:{(['No', 'None', 'Non'].includes(ToxicResult['Jargon'][0]) || ToxicResult['Jargon'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Jargon'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Vague Expressions:  {(['No', 'None', 'Non'].includes(ToxicResult['Vague Expressions'][0]) || ToxicResult['Vague Expressions'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Vague Expressions'])) ? 'No' : 'Yes'}
                    </li>
                  </ul>
                  {preparedContent.length<1?null:<a href='_blank' style={{ marginLeft: '4px' }} onClick={(e) => this.setShowModal(e, true)}> More Info... </a>}
                  

                  < Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>  Complexity </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <ContentDisplay content={preparedContent} />

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => this.setShowModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </Col>

            ) : (

              <Col className="py-3 dark-background" xs="4">
                <>
                {(ToxicResult?.Result === 'No text detected') ? 'No text detected' :
                  ((InitialResults?.status === 'error') ? (
                    InitialResultsError || 'Unknown error'
                  ) : (
                    'No complex words detected'
                  ))}

                </>
              </Col>
            )
            }

            <Col className="py-3 dark-background" xs="4">
              Good to have content which is non complex of Academic Tone, Acronym, Colloquialism / slang, Compound Words, Jargon and Vague Expressions       </Col>
          </Row>
        </>
      </>
    );
  }
}



export default DomComplex;