import React from "react";
import { Row, Col } from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageGenAiFormat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ToxicResult: null,
      showModal: false,
      currentCategory: '',
      toxicCount: null,
      categoryCounts: null,
      InitialResults: null,
      InitialResultsError: ''
    };
  }

  componentDidMount() {
    this.countToxicEntries();
  }

  countToxicEntries() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl;
      results = this.props.dataUrl?.results?.data;
    } else {
      initialResults = this.props.dataImage;
      results = this.props.dataImage?.results?.data;
    }

    if (initialResults?.status === 'finished') {
      initialResults = initialResults;
    }

    if (initialResults?.status === 'error') {
      this.setState({ InitialResults: initialResults });
      if (initialResults.reason_for_failure === 'genAI quota expired') {
        this.props.showError();
      }
    }

    if (results) {
      this.setState({
        ToxicResult: results,
        InitialResults: initialResults
      });
    } else {
      if (initialResults?.status === 'error') {
        var words = initialResults?.reason_for_failure.toLowerCase().split(' ');
        for (let i = 0; words && i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
        this.setState({
          ToxicResult: null,
          InitialResultsError: words?.join(" ")
        });
      }
    }
  }

  render() {
    const { ToxicResult, InitialResults, InitialResultsError } = this.state;

    const dataToDisplay = [
      { key: 'color.contrast.category', displayName: 'Contrast', recommendation: 'Low Contrast is not preferred', checkRecomendation: 'Low' },
      { key: 'color.saturation.value', displayName: 'Saturation', recommendation: 'Low saturation not preferred', checkRecomendation: 'Desaturated' },
      { key: 'color.tone.category', displayName: 'Tone', recommendation: 'Cool, Warm & Mixed tones are expected' },
    ];

    const getValueFromKey = (obj, key) => {
      if (key === 'color.saturation.value') {
        const saturationValue = key.split('.').reduce((acc, part) => acc && acc[part], obj)
        if (saturationValue > 69) {
          return 'High Saturation'
        }
        else if (saturationValue > 29) {
          return 'Medium Saturation'
        }
        else {
          return 'Low Saturation'
        }
      }
      return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    return (
      <>
        {
          dataToDisplay.map((entry, idx) => (
            <Row className="border-row" key={idx}>
              <Col className="py-3" xs="4">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        (InitialResults?.status === 'error') ? 'maybe' :
                          entry.key === 'color.saturation.value' ?
                            getValueFromKey(ToxicResult, entry.key) !== 'Low Saturation' ? true : false
                            :
                            getValueFromKey(ToxicResult, entry.key) === entry.checkRecomendation ? false : true
                      }
                    />
                  </Col>
                  <Col xs="10">
                    {entry.displayName}
                  </Col>
                </Row>
              </Col>
              <Col className="py-3 dark-background" xs="4">
                {ToxicResult ? getValueFromKey(ToxicResult, entry.key)
                  : (InitialResults?.status === 'error') ? (
                    InitialResultsError || 'Unknown error'
                  ) : (
                    'No image results detected'
                  )
                }
              </Col>
              <Col className="py-3 dark-background" xs="4">{entry.recommendation}</Col>
            </Row>
          ))
        }
      </>
    );
  }
}

export default ImageGenAiFormat;
