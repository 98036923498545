import React, { Component } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
  Button,
} from "reactstrap";
import industries from "../../../variables/industries";
import geographies from "../../../variables/geographies";
import channels from "../../../variables/channels";
import types from "../../../variables/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBlockPresence } from "./report";
import ImageMemorability from "./../blocks/ImageMemorability";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ROOT_URL } from "../../../constants";

 
class MemorabilityTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: false,
      score: null,
      scoreMemorability: null,
      abovethefold: null
    };
  }

  getScoreMemorability(score) {
    this.setState({ scoreMemorability: score });
  }
  componentDidMount(){
    this.fetchData()
  }
  fetchData() {
      axios.get(`/output/${this.props.task.upload_id}/imagememorability/results-imagememorability.json`)
        .then(res => {
          let response = JSON.parse(res.data.replace(/'/g, '"'));
          let score = response.score;
          this.setState({ score: score})
        }).catch(err => {
          console.log("error in serveattachment=lighthouse")
        })
        axios.get(`/output/${this.props.task.upload_id}/imagememorability/abovethefold_results-imagememorability.json`)
        .then(res => {
          let response = JSON.parse(res.data.replace(/'/g, '"'));
          let score = response.score;
          this.setState({ abovethefold: score})
        }).catch(err => {
          console.log("error in serveattachment=lighthouse")
        })
        console.log("fetch method end")
  }
  componentDidUpdate() {
    if (this.state.opacity == false && this.props.task._id !== undefined) {
      this.setState({
        opacity: `${ROOT_URL}/api/attachments/serveattachment?name=memorabilityopacity&id=${this.props.task._id}&token=${this.props.token}`,
      });
    }
  }

  setGif(gif) {
    if (gif == true) {
      this.setState({
        opacity: `${ROOT_URL}/api/attachments/serveattachment?name=memorabilitygif&id=${this.props.task._id}&token=${this.props.token}`,
      });
    } else {

      this.setState({
        opacity: `${ROOT_URL}/api/attachments/serveattachment?name=memorabilityopacity&id=${this.props.task._id}&token=${this.props.token}`,
      });
    }
  }

  reprocess(todo) {
    var task = this.props.task;
  

    if (todo === "report" && (task.industry === "" || task.type === "")) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    if (todo === "report") {
      todo = ["report", "memorability"];
    } else {
      todo = [todo];
    }

    axios
      .post(`${ROOT_URL}/api/tasks/edit`, {
        ...task,
        tasks: task.tasks.concat(todo),
        id: task._id,
      })
      .then((res) => {
        this.props.history.push("/projects/" + task.project._id);
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              {!access.includes("memorability") ||
              task.tasks.indexOf("memorability") === -1 ? (
                <span>
                  <Row className="mt-4 px-4">
                    <Col xs="12 fade-in" className="disabled-grayscale">
                      <Row>
                        <div className="text-center mt-3 mb-3 w-100">
                          {task.media === "video" ? (
                            <h3 className="warning">
                              This functionnality is not available for videos
                              yet, here is a sample for an image:
                            </h3>
                          ) : !this.props.access.includes("memorability") ? (
                            <h3 className="warning">
                              This functionnality is not available in your plan,
                              here is a sample report. To get access, please
                              contact{" "}
                              <a href="mailto:ai4c_crisp_support@accenture.com">
                                ai4c_crisp_support@accenture.com
                              </a>
                              . Thank you!
                            </h3>
                          ) : (
                            <div>
                              {" "}
                              <h3 className="success">
                                This functionnality is available. <br></br>{" "}
                                Click on the button to perform it on this
                                content. <br></br>{" "}
                              </h3>
                              <Button
                                onClick={() => this.reprocess("memorability")}
                                color="danger"
                              >
                                PROCESS
                              </Button>
                            </div>
                          )}
                        </div>
                      </Row>
                      <Row className="border-row no-top-border mt-3">
                        <Col className="py-2 text-title" xs="4">
                          Human Memorability prediction
                        </Col>
                        <Col className="py-2 text-title dark-background" xs="3">
                          Score
                        </Col>
                        <Col className="py-2 text-title dark-background" xs="3">
                          Label
                        </Col>
                        {/* <Col className="py-2 text-title" xs="2">
    DETAILS
  </Col> */}
                      </Row>
                      <Row>
                        <Col xs="4" className="py-3">
                          <div>
                            <CardImg
                              top
                              width="100%"
                              className="card-img-results"
                              src={"/img/sample-memorability.jpg"}
                              alt="Card image cap"
                            />
                          </div>
                        </Col>
                        <Col xs="3" className="py-3 dark-background">
                          0.9
                        </Col>
                        <Col xs="3" className="py-3 dark-background">
                          Positive
                        </Col>
                        {/* <Col xs="2" className="py-3">
    <div>Details</div>
  </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </span>
              ) : (
                <>
                      <div>
                    <CheckBlockPresence
                      analysis={task.analysis}
                      block="memorability"
                    >
               
                      <ImageMemorability
                        id={task._id}
                        data={task.analysis.memorability}
                        score={this.state.score}
                        token={token}
                        generateScore={this.getScoreMemorability}
                      ></ImageMemorability>
                    </CheckBlockPresence>
                  </div>
                <Row className="mt-4 px-4">
                  <Col xs="4 fade-in">
                    <b>Original</b>
                    <Card>
                    {task.media === "image" || task.media === "dom" ? (
                      <img
                        placeholderSrc={
                          "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                        }
                        effect="blur"
                        className="card-img-results"
                        src={`/input/${this.props.task.media}/${this.props.task.upload_id}/image.jpeg`}
                      />)
                      :
                      (
                        <video
                        controls
                        replay
                        className="video-result"
                        controlsList="nodownload"
                        autoPlay
                        src={`/input/video/${task.upload_id}/video.mp4`}
                      ></video>
                      )
  }
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Human Memorability Heatmap</b>
                    {/* <CheckBlockPresence
                      analysis={task.analysis}
                      block="image_memorability"
                    > */}
                      <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <img
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className="card-img-results"
                          src={`/output/${this.props.task.upload_id}/imagememorability/${(this.props.task.url == "")?'heatmap.jpeg':'full_heatmap.jpeg'}`}
                        />):
                        (
                          <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoPlay
                          src={`/output/${task.upload_id}/memorability_heatmap.mp4`}
                        ></video>
                        )}
                      </Card>
                    {/* </CheckBlockPresence> */}
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Human Memorability Opacity</b>
                   {/* <CheckBlockPresence
                      analysis={task.analysis}
                      block="image_memorability"
                    > 
                      <div
                        onMouseEnter={() => this.setGif(true)}
                        onMouseLeave={() => this.setGif(false)}
                      > */}
                        <Card>
                        {task.media === "image" || task.media === "dom" ? (
                          <img
                            placeholderSrc={
                              "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                            }
                            effect="blur"
                            className="card-img-results w-100"
                            src={`/output/${this.props.task.upload_id}/imagememorability/${(this.props.task.url == "")?'opacity.jpeg':'full_opacity.jpeg'}`}
                          />): (
                            <video
                            controls
                            replay
                            className="video-result"
                            controlsList="nodownload"
                            autoPlay
                            src={`/output/${task.upload_id}/memorability_Opacitymap.mp4`}
                          ></video>
                            )}
                        </Card>
                       
                      {/* </div> */}
                  {/* </CheckBlockPresence> */}
                  </Col>
                </Row>
                {(this.state.abovethefold*50+50 > 0  && task.media == 'dom') ? (
                <Row>
                  <Col xs="4 fade-in">
                    <b>Original Above The Fold</b>
                    <Card>
                      {task.media === "dom" ? (
                        <img
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className="card-img-results"
                          src={`/output/${task.upload_id}/imagememorability/abovethefold_original.jpg`}
                        />
                      ) : null}
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Memorability Above The Fold Heatmap</b>
                    <Card>
                      {task.media === "dom" ? (
                        <img
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/output/${task.upload_id}/imagememorability/abovethefold_heatmap.jpeg`}
                        />
                      ) : null}
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Memorability Above The Fold Opacity map</b>
                    <Card>
                      {task.media === "dom" ? (
                        <img
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/output/${task.upload_id}/imagememorability/abovethefold_opacity.jpeg`}
                        />
                      ) : null}
                    </Card>
                  </Col>
                </Row>
                ) : null}

            
              </>)}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default MemorabilityTab;
