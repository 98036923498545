import { SET_SCREEN_SHOT } from "../actions/types";

const initialState = {
  screenShot: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCREEN_SHOT:
      return {
        ...state,
        screenShot: action.screen_shot,
      };

    default:
      return state;
  }
}
