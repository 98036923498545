import React, { useState } from "react";

import axios from "axios";
import { Row,Input, Col, Card, CardBody, Badge, Button, Progress } from "reactstrap";
import AddMemberModal from "../admin/teams/AddMemberModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import moment from "moment";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import Logs from "../log/Log";
import { Helmet } from "react-helmet";
import { ROOT_URL } from "../../constants";

class ManageTeam extends React.Component {
  constructor(props) {
    super(props);

   // this.team = this.props.match.params.id;
   this.team = window.location.pathname.split(/[/]+/).pop();
    this.state = {
      team: false,
    };

    this.members_col = [
      {
        name: "Name",
        selector: (row) => {
          if (row.user) {
            return row.user.full_name
          } else {
            return row.email;
          }
        },

        sortable: true,
        center: true,
      },
      {
        name: "Job title",
        selector: (row) => {
          if (row.user) {
            return row.user.job_title;
          }
        },
        sortable: true,
        center: true,
      },
      {
        name: "Remove member",
        selector: (row) => {
          var leader = "";
          var type = "";
          var value = "";
        
          if(row.leader) {
            return (
                <FontAwesomeIcon
                  icon="lock"
                  title="User is a team leader"
                ></FontAwesomeIcon>)
          } else {
            if (row.user) {
                type = "team";
                value = row.user._id;
              } else {
                type = "invite";
                value = row.email;
              }
              return (
                <FontAwesomeIcon
                  className="clickable"
                  onClick={() => this.removeMember(type, value)}
                  icon="times"
                  title="Remove user"
                ></FontAwesomeIcon>)
          }
        
      
        },
        sortable: false,
        center: true,
      },
    ];

    this.projects_col = [
      {
        name: "Name",
        selector: "name",
        cell: (row) => <a href={`/projects/${row._id}`}>{row.name}</a>,
        sortable: true,
        center: true,
      },
      {
        name: "Last activity",
        selector: (row) => moment(row.modified).unix(),
        cell: (row) => moment(row.modified).format("lll"),
        sortable: true,
        center: true,
      },
      {
        name: "Tasks",
        selector: (row) => row.image_cnt + row.video_cnt,
        sortable: false,
        center: true,
      },
    ];

    this.api_col = [
      {
        name: "Key",
        selector: "key",
        cell: (row) =>  <Input
        type="text"
        readOnly
        onFocus={(e) => e.target.select()}
        value={row.key}
      />,
        sortable: true,
        center: true,
      },

      {
        name: "Quota",
        selector: (row) => {
          if (row.quota === -1) {
            return "Unlimited";
          } else {
            return row.quota;
          }
        },
        sortable: false,
        center: true,
      },

      {
        name: "Access",
        selector: (row) => {
          var colors = {
            adobe: "secondary",
            api: "info",
            chrome: "primary",
          };
          return row.access.map((el) => {
            return (
              <Badge className="mr-1" color={colors[el]}>
                {el.toUpperCase()}
              </Badge>
            );
          });
        },

        sortable: false,
        center: true,
      },
    ];
  }

  removeMember(type, value) {
    axios
      .post(`${ROOT_URL}/api/teams/removemember`, {
        id: this.team,
        type: type,
        value: value,
      })
      .then((res) => {
        axios
          .get(`${ROOT_URL}/api/teams/get`, { params: { id: this.team } })
          .then((res) => {
            this.setState({ team: res.data });
          })
          .catch((err) => {
            this.props.history.push("/admin/teams");
          });
      })
      .catch((err) => {
      });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    localStorage.setItem('selectedMenuItem', "manageTeams");

    axios
      .get(`${ROOT_URL}/api/teams/get`, { params: { id: this.team } })
      .then((res) => {
        this.setState({ team: res.data });
        dispatch(setPageTitle(res.data.name, "users"));
      })
      .catch((err) => {
        window.location.replace("/home");
      });
  }

  render() {
    return (
      <div className="content">
        {this.state.team ? (
          <Helmet>
            <title>{`CRISP - ${this.state.team.name}`}</title>
          </Helmet>
        ) : (
          <b></b>
        )}

        {!this.state.team ? (
          <span></span>
        ) : (
          <div>
            <AddMemberModal
              team={this.team}
              ref={(modal) => {
                this.addModal = modal;
              }}
            ></AddMemberModal>

            <FontAwesomeIcon
              icon="arrow-left"
              className="prev clickable"
              onClick={() => window.location.replace("/teams/manage")}
            ></FontAwesomeIcon>
            <Row>
              <Col xs="5">
                <Card>
                  <CardBody>
                    <h3>{this.state.team.name} </h3>

                    <p className="block-info">
                      {this.state.team.description} <br /> <br />
                      <span className="title-info">Last activity: </span>
                      {moment(this.state.team.modified).format("lll")} <br />
                      <span className="title-info">WBS: </span>
                      {this.state.team.wbs} <br />
                      <span className="title-info">Max members: </span>
                      {this.state.team.max_members} <br />
                      <span className="title-info"> Quotas:</span>
                    </p>

                    <div>
                      <div className="w-100">
                        <span className="pull-left">Images</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_image}/
                          {this.state.team.quota_image}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={ this.state.team.current_quota_image /
                            this.state.team.quota_image*100 > 80 ? "danger" :  (this.state.team.current_quota_image /
                            this.state.team.quota_image*100 > 60 ? "warning" : 'info')}
                        value={
                          this.state.team.current_quota_image /
                          this.state.team.quota_image*100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">Videos</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_video}/
                          {this.state.team.quota_video}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={ this.state.team.current_quota_video /
                            this.state.team.quota_video*100 > 80 ? "danger" :  (this.state.team.current_quota_video /
                            this.state.team.quota_video*100 > 60 ? "warning" : 'info')}
                        value={
                          this.state.team.current_quota_video /
                          this.state.team.quota_video*100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">HTML</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_dom}/
                          {this.state.team.quota_dom}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.team.current_quota_dom /
                            this.state.team.quota_dom) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.team.current_quota_dom /
                                this.state.team.quota_dom) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.team.current_quota_dom /
                            this.state.team.quota_dom) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">GenAi</span>{" "}
                        <span className="pull-right">
                       { (this.state.team.current_quota_genai >=
                          this.state.team.quota_genai)?'Maximum Quota Exhausted':                        
                          `${this.state.team.current_quota_genai} /
                          ${this.state.team.quota_genai}` }
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.team.current_quota_genai /
                            this.state.team.quota_genai) *
                            100 >
                            80
                            ? "danger"
                            : (this.state.team.current_quota_genai/
                              this.state.team.quota_genai) *
                              100 >
                              60
                              ? "warning"
                              : "info"
                        }
                        value={
                       
                         (this.state.team.current_quota_genai /
                            this.state.team.quota_genai) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">GenAi 4.0</span>{" "}
                        <span className="pull-right">
                       { (this.state.team.current_quota_genai4 >=
                          this.state.team.quota_genai4)?'Maximum Quota Exhausted':                        
                          `${this.state.team.current_quota_genai4} /
                          ${this.state.team.quota_genai4}` }
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.team.current_quota_genai4 /
                            this.state.team.quota_genai4) *
                            100 >
                            80
                            ? "danger"
                            : (this.state.team.current_quota_genai4/
                              this.state.team.quota_genai4) *
                              100 >
                              60
                              ? "warning"
                              : "info"
                        }
                        value={
                       
                         (this.state.team.current_quota_genai4 /
                            this.state.team.quota_genai4) *
                          100
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="7">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Statistics</h4>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Members</h4>
                    <Button
                      color="primary"
                      onClick={() => this.addModal.toggle()}
                      className="m-0 pull-right"
                    >
                      Add member
                    </Button>
                    <DataTable
                      pagination={true}
                      columns={this.members_col}
                      data={this.state.team.members.concat(
                        this.state.team.invitations
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Projects</h4>
                    <DataTable
                      pagination={true}
                      columns={this.projects_col}
                      data={this.state.team.projects}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Keys</h4>

                    <DataTable
                      pagination={true}
                      columns={this.api_col}
                      data={this.state.team.keys}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Logs</h4>
                    <Logs public={true}  logs={this.state.team.logs}></Logs>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(ManageTeam);
