var json = {
  vco: {
    pretty: "VCO",
    badge: "VISUAL ATTENTION",
    available_for: ["dom", "image", "video"],
    
  },
  memorability: {
    pretty: "Memorability",
    badge: "MEMORABILITY",
    available_for: ["dom", "image", "video"],
  
  },
  report: {
    pretty: "Predictive Design",
    badge: "PREDICTIVE DESIGN",

    available_for: ["dom", "image", "video"],
  
  },

  experience: {
    pretty: "Page Experience",
    badge: "PAGE EXPERIENCE",

    available_for: ["dom"],
    disableType:["video","image"]
  },
  ibmaccessibility: {
    pretty: "Accessibility",
    badge: "ACCESSIBILITY",

    available_for: ["dom"],
    disableType:["video","image"]
  },
  accessibility: {
    pretty: "Email Accessibility",
    badge: "EMAIL ACCESSIBILITY",
    available_for: [],
    disableType:["video","image","dom"]
  },
};

export default json;
