/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../../utils/Error";
import { ROOT_URL } from "../../../constants";

class Enable extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit(event) {
    event.preventDefault();
    axios
      .post(`${ROOT_URL}/api/admin/users/activate`, {
        id: this.props.user,
        enabled: (!this.props.enabled).toString(),
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {this.props.enabled === true ? "Deactivate user" : "Activate user"}
          </ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            Do you want to{" "}
            {this.props.enabled === true ? "deactivate" : "activate"} this user?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              color={this.props.enabled ? "danger" : "success"}
              onClick={this.handleSubmit}
              type="submit"
            >
              Yes
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Enable;
