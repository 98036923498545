import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageGenAiComposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ToxicResult: null,
      showModal: false,
      currentCategory: '',
      toxicCount: null,
      categoryCounts: null,
      InitialResults: null,
      InitialResultsError: ''
    };
  }

  componentDidMount() {
    this.countToxicEntries();

  }
  countToxicEntries() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl
      results = this.props.dataUrl?.results?.data;
    } else {
      initialResults = this.props.dataImage
      results = this.props.dataImage?.results?.data
    }

    if (initialResults?.status === 'finished') {
      initialResults = initialResults

    }

    if (initialResults?.status === 'error') {
      this.setState({ InitialResults: initialResults })
      if (initialResults.reason_for_failure === 'genAI quota expired') {
        this.props.showError()
      }
    }

    if (results) {
      this.setState({
        ToxicResult: results,
        InitialResults: initialResults
      });
    } else {
      if (initialResults?.status === 'error') {
        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          ToxicResult: null,
          InitialResultsError: words?.join(" ")
        });
      }
    }
  }


  render() {
    const { ToxicResult, showModal, InitialResults, InitialResultsError } = this.state
    let dataToDisplay = [
      { key: 'shot.angle', displayName: 'Shot Angle', recommendation: 'NA' },
      { key: 'frame.size', displayName: 'Frame Size', recommendation: 'NA' },
      { key: 'lighting.angle', displayName: 'Lighting Angle', recommendation: 'NA' },
      { key: 'shadows.intensity', displayName: 'Shadow Intensity', recommendation: 'NA' },
      { key: 'lighting.source', displayName: 'Lighting Source', recommendation: 'NA' },
      { key: 'timeOfDay.daytime', displayName: 'Time Of Day', recommendation: 'NA' },
    ];
    // const getValueFromKey = (obj, key) => {
    //   return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    // };

    const getValueFromKey = (obj, key) => {
      if (key === 'lighting.source') {
        const lightingSource = key.split('.').reduce((acc, part) => acc && acc[part], obj)
        if (lightingSource === 'Studio') {
          dataToDisplay = dataToDisplay.filter(item => item.key !== 'timeOfDay.daytime');
        }
  
      }
      return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    };
    if(ToxicResult){
      getValueFromKey(ToxicResult,'lighting.source')
    }
    return (
      <>
        <Row className="border-row">
          <Col className="py-2 text-title " xs="4">
            Photographic style
          </Col>
          <Col className="py-2 text-title dark-background" xs="8">
            RESULT
          </Col>
        </Row>
        {
          dataToDisplay.map((entry, idx) => (
            <Row key={idx} >
              <Col xs="4">
                <Row>

                  <Col xs="12">
                    {entry.displayName}
                  </Col>
                </Row>
              </Col>
              <Col className=" dark-background" xs="8">
                {ToxicResult ? getValueFromKey(ToxicResult, entry.key)
                  : (InitialResults?.status === 'error') ? (
                    InitialResultsError || 'Unknown error'
                  ) : (
                    'No image results detected'
                  )
                }
              </Col>
            </Row>
          ))
        }
      </>
    );
  }
}



export default ImageGenAiComposition;