import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class VideoUsability extends React.Component {
  constructor(props) {
    super(props);
    // this.generateScore();
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  generateScore() {

    let scorefinal =
      // ((this.props.data.correct_colorspace.results.score +
      //   this.props.data.correct_file_extension.results.score +
      //   this.props.data.correct_image_resolution.results.score +
      //   this.props.data.correct_aspect_ratio.results.score) /
      //   4) *
      // 100;

      ((this.props.data.correct_colorspace.results.score +
        this.props.data.correct_image_resolution.results.score +
        (this.props.data.correct_aspect_ratio?.results?.score | 0)) /
        3) *
      100;

    this.props.generateScore(scorefinal);
  }

  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });
    return services;
  }

  render() {
    const videoSafety = this.props.data.Category_Division['Category Percentages'];
    console.log(" video safety" , videoSafety)
    return (
      <>
        <Row id="usability" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">USABILITY</div>
            </Row>
         
          

          
               {/* Safety */}
          
            <Row>
              <Col className="py-2 text-title" xs="3">Safety</Col>
              <Col className="py-2 text-title dark-background" xs="9">
                PERCENTAGE
              </Col>
           
            </Row>
        
              <Row className="border-row">
                <Col xs="3" className="py-3">
                  <Row>
                    <Col xs="12"> Very Unlikely
                    </Col>
                  </Row>
                </Col>
                <Col xs="9" className="py-3 dark-background">
                  {
                    Math.round(videoSafety.VERY_UNLIKELY)
                  } %
                </Col>
              </Row>
              <Row className="border-row">
                <Col xs="3" className="py-3">
                  <Row>
                    <Col xs="12"> Unlikely
                    </Col>
                  </Row>
                </Col>
                <Col xs="9" className="py-3 dark-background">
                  {
                    Math.round(videoSafety.UNLIKELY)
                  } %
                </Col>
              </Row>
              <Row className="border-row">
                <Col xs="3" className="py-3">
                  <Row>
                    <Col xs="12"> Possible
                    </Col>
                  </Row>
                </Col>
                <Col xs="9" className="py-3 dark-background">
                  {
                    Math.round(videoSafety.POSSIBLE)
                  } %
                </Col>
              </Row>
              <Row className="border-row">
                <Col xs="3" className="py-3">
                  <Row>
                    <Col xs="12"> Likely
                    </Col>
                  </Row>
                </Col>
                <Col xs="9" className="py-3 dark-background">
                  {
                    Math.round(videoSafety.LIKELY)
                  } %
                </Col>
              </Row>
              <Row className="border-row">
                <Col xs="3" className="py-3">
                  <Row>
                    <Col xs="12"> Very Likely
                    </Col>
                  </Row>
                </Col>
                <Col xs="9" className="py-3 dark-background">
                  {
                    Math.round(videoSafety.VERY_LIKELY)
                  } %
                </Col>
              </Row>

          

          </Col>
        </Row>
      </>
    );
  }
}

export default VideoUsability;

