import React from "react";
import { Row, Col } from "reactstrap";
import { Cube } from "../tabs/report";

class DomMobileux extends React.Component {
    constructor(props) {
        super(props);
        this.generateScore();
    }

    generateScore() {
        let score = 0;
        let total_score = 0;
        if (this.props.data.results?.image_optim) {
            total_score = total_score + 1;
            if (this.props.data.results.image_optim?.results?.imgs_too_large <= 0) {
                score = score + 1;
            }
        }
        if (this.props.data.results?.target_size) {
            total_score = total_score + 1;
            if (this.props.data.results.target_size?.results?.too_small_targets <= 0) {
                score = score + 1;
            }
        }
        if (this.props.data.results?.text_size) {
            total_score = total_score + 1;
            if (this.props.data.results.text_size?.results?.too_small_texts <= 0) {
                score = score + 1;
            }
        }
        if (this.props.data.results?.pinch_to_zoom) {
            total_score = total_score + 1;
            if (this.props.data.results.pinch_to_zoom?.results?.supported) {
                score = score + 1;
            }
        }
        if (this.props.data.results?.scrolling) {
            total_score = total_score + 1;
            if (this.props.data.results.scrolling?.results?.correct_scroll) {
                score = score + 1;
            }
        }
        let scorefinal = (score / total_score) * 100;
        this.props.generateScore(scorefinal);
    }

    render() {
        return (
            <>
                {
                    this.props.data.results
                        ? <>
                            <Row>
                                <div className="category-text">MOBILE PAGE ANALYSIS</div>
                            </Row>
                            <Row>
                                <Col className="py-2" xs="4">
                                    User interactions
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RESULT
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RECOMMENDED
                                </Col>
                            </Row>
                            {
                                this.props.data.results?.pinch_to_zoom
                                    ? <Row className="border-row">
                                        <Col xs="4" className="py-3">
                                            <Row>
                                                <Col xs="2">
                                                    <Cube
                                                        color={this.props.data.results?.pinch_to_zoom?.results?.supported}
                                                    ></Cube>
                                                </Col>
                                                <Col xs="10">Pinch-to-zoom</Col>
                                            </Row>
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {this.props.data.results.pinch_to_zoom.results?.supported
                                                ? <span>Supported.</span>
                                                : <span>Not supported.</span>
                                            }
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {/* {this.props.data.results.pinch_to_zoom.results?.reco} */}
                                            Websites should avoid using the meta viewport tag to disable pinch-to-zoom. This behavior is highly discouraged for accessibility and usability on mobile devices
                                        </Col>
                                    </Row>
                                    : ""
                            }
                            {
                                this.props.data.results?.scrolling
                                    ? <Row className="border-row">
                                        <Col xs="4" className="py-3">
                                            <Row>
                                                <Col xs="2">
                                                    <Cube
                                                        color={this.props.data.results?.scrolling?.results?.correct_scroll}
                                                    ></Cube>
                                                </Col>
                                                <Col xs="10">Scrolling</Col>
                                            </Row>
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {this.props.data.results.scrolling.results?.correct_scroll
                                                ? <span>The page can be scrolled in a unique direction.</span>
                                                : <span>The page cannot be scrolled.</span>
                                            }
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {/* {this.props.data.results.scrolling.results?.reco} */}
                                            When viewed on small screens (like mobile phones) content must fit without requiring scrolling in two dimensions.
                                        </Col>
                                    </Row>
                                    : ""
                            }
                        </>
                        : ""
                }
                {
                    this.props.data.results
                        ? <>
                            <Row className="mt-4">
                                <Col className="py-2" xs="4">
                                    Element visibility
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RESULT
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RECOMMENDED
                                </Col>
                            </Row>
                            {this.props.data.results?.target_size
                                ? <Row className="border-row">
                                    <Col xs="4" className="py-3">
                                        <Row>
                                            <Col xs="2">
                                                <Cube
                                                    color={this.props.data.results?.target_size?.results?.too_small_targets <= 0}
                                                ></Cube>
                                            </Col>
                                            <Col xs="10">Targets size</Col>
                                        </Row>
                                    </Col>
                                    <Col xs="4" className="py-3 dark-background">
                                        {this.props.data.results?.target_size?.results?.too_small_targets > 0
                                            ? <span>
                                                {this.props.data.results.target_size.results?.too_small_targets} target element
                                                {this.props.data.results.target_size.results?.too_small_targets > 1 ? "s are" : " is"} too small.
                                            </span>
                                            : <span>{this.props.data.results?.target_size?.status === "finished" ? "No target elements are small." : "No target elements are detected"}</span>
                                        }
                                    </Col>
                                    <Col xs="4" className="py-3 dark-background">
                                        {/* {this.props.data.results.target_size.results?.reco} */}
                                        {this.props.data.results?.target_size?.status === "finished" && 
                                        "For mobile, all touch targets should be at least 44 by 44 CSS pixels."}
                                    </Col>
                                </Row>
                                : ""
                            }
                            {
                                this.props.data.results?.text_size
                                    ? <Row className="border-row">
                                        <Col xs="4" className="py-3">
                                            <Row>
                                                <Col xs="2">
                                                    <Cube
                                                        color={this.props.data.results?.text_size?.results?.too_small_texts <= 0}
                                                    ></Cube>
                                                </Col>
                                                <Col xs="10">Texts font size</Col>
                                            </Row>
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {this.props.data.results?.text_size?.results?.too_small_texts > 0
                                                ? <span>
                                                    {this.props.data.results.text_size.results?.too_small_texts} text element
                                                    {this.props.data.results.text_size.results?.too_small_texts > 1 ? "s are" : " is"} too small.
                                                </span>
                                                : <span>{this.props.data.results?.text_size?.status === "finished" ? "No text elements are small." : "No text elements are detected"}</span>
                                            }
                                        </Col>
                                        <Col xs="4" className="py-3 dark-background">
                                            {/* {this.props.data.results.text_size.results?.reco} */}
                                            {this.props.data.results?.text_size?.status === "finished" && 
                                            "Text should be large enough to read, without zooming. The effective font size on a mobile device, after the default zoom is applied, should be bigger than 12px."}
                                        </Col>
                                    </Row>
                                    : ""
                            }
                        </>
                        : ""
                }
                {
                    this.props.data.results
                        ? <>
                            <Row className="mt-4">
                                <Col className="py-2" xs="4">
                                    Image optimization
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RESULT
                                </Col>
                                <Col className="py-2 text-title dark-background" xs="4">
                                    RECOMMENDED
                                </Col>
                            </Row>
                            {this.props.data.results?.image_optim
                                ? <Row className="border-row">
                                    <Col xs="4" className="py-3">
                                        <Row>
                                            <Col xs="2">
                                                <Cube
                                                    color={this.props.data.results?.image_optim?.results?.imgs_too_large <= 0}
                                                ></Cube>
                                            </Col>
                                            <Col xs="10">Images size optimization</Col>
                                        </Row>
                                    </Col>
                                    <Col xs="4" className="py-3 dark-background">
                                        {this.props.data.results.image_optim.results?.imgs_too_large > 0
                                            ? <span>
                                                {this.props.data.results.image_optim.results?.imgs_too_large} image
                                                {this.props.data.results.image_optim.results?.imgs_too_large > 1 ? "s are" : " is"} bigger than 500KB.
                                            </span>
                                            : <span>No images are bigger than 500KB.</span>
                                        }
                                    </Col>
                                    <Col xs="4" className="py-3 dark-background">
                                        {/* {this.props.data.results.image_optim.results?.reco} */}
                                        To avoid low page speed, the images on your site should not go beyond 500 KB (= 0.5 MB) in file size.
                                    </Col>
                                </Row>
                                : ""
                            }
                        </>
                        : ""
                }
            </>
        )
    }
}

export default DomMobileux;