const industries = [
  {
    pretty_name: "Aerospace & Defense",
    name: "aerospace",
  },
  {
    pretty_name: "Automotive",
    name: "automotive",
  },
  {
    pretty_name: "Banking",
    name: "banking",
  },
  {
    pretty_name: "Capital Markets",
    name: "capital",
  },
  {
    pretty_name: "Chemicals",
    name: "chemicals",
  },
  {
    pretty_name: "Communications and Media",
    name: "media",
  },
  {
    pretty_name: "Consumer Goods and Services",
    name: "goods",
  },
  {
    pretty_name: "Energy",
    name: "energy",
  },
  {
    pretty_name: "Health",
    name: "health",
  },
  {
    pretty_name: "High Tech",
    name: "tech",
  },
  {
    pretty_name: "Industrial Equipment",
    name: "industrial",
  },
  {
    pretty_name: "Insurance",
    name: "insurance",
  },
  {
    pretty_name: "Life Sciences",
    name: "science",
  },
  {
    pretty_name: "Natural Resources",
    name: "resources",
  },
  {
    pretty_name: "Public Service",
    name: "public",
  },
  {
    pretty_name: "Retail",
    name: "retail",
  },
  {
    pretty_name: "Software and Platforms",
    name: "software",
  },
  {
    pretty_name: "Travel",
    name: "travel",
  },
  {
    pretty_name: "US Federal Government",
    name: "government",
  },
  {
    pretty_name: "Utilities",
    name: "utilities",
  },
];

export default industries;
