import React from "react";
import { Row, Col, CardImg } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageLogos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Logos
          </Col>
          <Col xs="8">
            <Row>
              <Col xs="6" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SIZE
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                VISIBILITY
              </Col>
            </Row>
          </Col>
          {/* <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col> */}
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3">
            {this.props.data.results.logo_size.results.length > 0 ? (
              <CardImg
                top
                width="100%"
                className="card-img-results"
                src={`/output/${this.props.uploadID}/predictivedesign/imagelogo.jpg`}
                alt="Card image cap"
              />
            ) : (
              <div>No Logo detected</div>
            )}
            {(this.props.data.results?.recommendation?.results?.reco_pos?.trim()) &&
              <FontAwesomeIcon
                className="info-icon"
                icon="info-circle"
                id="image-logos-tooltip"
              ></FontAwesomeIcon>}
            <ReactTooltip
              anchorId="image-logos-tooltip"
              place="bottom"
              variant="info"
            >{this.props.data.results?.recommendation?.results?.reco_pos}
            </ReactTooltip>
          </Col>
          <Col xs="8" className="py-3 dark-background">
            {this.props.data.results.logo_size.results.map(function (logo) {
              return (
                <Row>
                  <Col xs="6">{logo.logo}</Col>
                  <Col xs="3">{Math.round(logo.sizePercent)}%</Col>
                  <Col xs="3">{Math.round(logo.visibility)}</Col>
                </Row>
              );
            })}
          </Col>
          {/* <Col xs="2" className="py-3">
          {this.props.data.results?.recommendation?.results?.reco_pos} 
          </Col> */}
        </Row>
      </>
    );
  }
}

export default ImageLogos;
