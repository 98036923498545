import React from "react";
import axios from "axios";
import { Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Edit from "../task/Edit";
import Share from "../task/Share";
import Delete from "../task/Delete";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import ContentLoader from "react-content-loader";
import TaskCard from "../task/TaskCard";
import { ROOT_URL } from "../../constants";
import NewLoader from "../loader/NewLoader";
class View extends React.Component {
  constructor(props) {
    super(props);
    //this.public = this.props.public;
    this.public = window.location.pathname.split(/[/]+/).pop()
    if (this.public !== 'public') {
      // this.project = this.props.match.params.id;
      console.log("inside if");
      this.project = window.location.pathname.split(/[/]+/).pop();
      this.public=false;
    }else{
      console.log("inside else block");
      this.public=true;
    }
    
    this.state = {
      tasks: false,
      getTaskStatus:true,
      offset: 1,
      project: { team: { _id: null }, name: "" },
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

    clearTimeout(this.timeout);
  }

  componentDidMount() {
    var project = this.project;
    const { dispatch } = this.props;
    window.addEventListener("scroll", this.handleScroll.bind(this));

    var url = this.public
      ? `${ROOT_URL}/api/tasks/getforpublic`
      : `${ROOT_URL}/api/tasks/getperproject`;
    var icon = this.public ? "globe-europe" : "folder-open";

    axios
      .get(url, {
        params: {
          id: project,
        }
      })
      .then((res) => {
        this.setState({ tasks: res.data.tasks, project: res.data.project });
        dispatch(setPageTitle(res.data.project.name, ''));
      })
      .catch((err) => {
        window.location.replace("/home");
      });
  }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const totalCount = this.state.project.dom_cnt + this.state.project.image_cnt + this.state.project.video_cnt
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= 0.9 * docHeight ) {
      if(totalCount > this.state.tasks.length && this.state.getTaskStatus == true) {
      this.setState((prevState) => ({
        offset: prevState.offset + 1,
        getTaskStatus: false
        }), this.getMore);
      }
    }
  }

  async getMore() {
    var project = this.project;
    if(this.state.tasks != false){
      var url = this.public
      ? `${ROOT_URL}/api/tasks/getforpublic`
      : `${ROOT_URL}/api/tasks/getperproject`;

    axios
      .get(url, {
        params: {
          id: project,
          offset: this.state.offset -1,
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
        
          // offset: prevState.offset + 1,
          tasks: [...prevState.tasks, ...res.data.tasks],
          getTaskStatus : true,
        }));
      })
      .catch((err) => {
        this.props.history.push("/home");
      });
    }


  }

  render() {

    return (
      <div className="content">
        
        <Helmet>
          <title>CRISP2 - {this.state.project.name}</title>
        </Helmet>
        <FontAwesomeIcon
          icon="arrow-left"
          className="prev clickable"
          onClick={() => window.location.replace("/teams/" + this.state.project.team._id)}
          title="Back"
        ></FontAwesomeIcon>
        <Edit
          ref={(modal) => {
            this.editRef = modal;
          }}
          history={this.props.history}
          access={this.props.access}
          team={this.state.project.team._id}
        ></Edit>
        <Share
          ref={(modal) => {
            this.shareRef = modal;
          }}
        ></Share>

        <Delete
          ref={(modal) => {
            this.deleteRef = modal;
          }}
        ></Delete>

        {this.state.tasks === false ? (
          <NewLoader />
        ) : this.state.tasks.length === 0 ? (
          <h3 className="text-center">There is no tasks here.</h3>
        ) : (
          
          <div>
              
            <Row>
              {this.state.tasks.map((task) => {
                return (
                  <TaskCard
                    token={this.props.resource_token.resource_token}
                    history={this.props.history}
                    editRef={this.editRef}
                    shareRef={this.shareRef}
                    deleteRef={this.deleteRef}
                    task={task}
                  ></TaskCard>
                );
              })}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const MyLoader = (props) => (
  <ContentLoader
    width={1500}
    height={800}
    viewBox="0 0 1200 575"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="300" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="600" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="900" y="0" rx="2" ry="2" width="250" height="250" />

    <rect x="12" y="300" rx="2" ry="2" width="250" height="250" />
    <rect x="300" y="300" rx="2" ry="2" width="250" height="250" />
    <rect x="600" y="300" rx="2" ry="2" width="250" height="250" />
    <rect x="900" y="300" rx="2" ry="2" width="250" height="250" />
  </ContentLoader>
);

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(View);
