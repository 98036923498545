import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageCopywriting extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score =
      1 -
      this.props.data.results.text_too_long.results.averageTooLong +
      (1 - this.props.data.results.text_too_short.results.averageTooShort);

    let scorefinal = (score / 2) * 100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return !this.props.data.results?.text_presence?.results?.textPresence ? (
      <>
        <Row>
          <div className="category-text">MESSAGE</div>
        </Row>
        <div>No text detected</div>
      </>
    ) : (
      <>
        <Row>
          <div className="category-text">MESSAGE</div>
        </Row>
        <Row>
          <Col className="py-2" xs="4"></Col>
          <Col className="py-2 text-title dark-background" xs="4">
            RESULT
          </Col>
          <Col className="py-2 text-title dark-background" xs="4">
            RECOMMENDED
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.text_too_short.results
                      .averageTooShort * 100
                  }
                ></Cube>
              </Col>
              <Col xs="10">Wordiness
                {(this.props.data.results.text_too_short.results.reco_pos_min_length?.trim() ||
                  this.props.data.results.text_too_short.results.reco_neg_min_length) &&
                  <FontAwesomeIcon
                    className="info-icon"
                    icon="info-circle"
                    id="copy-wordiness-tooltip"
                  ></FontAwesomeIcon>}
                <ReactTooltip
                  anchorId="copy-wordiness-tooltip"
                  place="bottom"
                  variant="info"
                >{this.props.data.results.text_too_short.results.averageTooShort * 100 == 0 ?
                  this.props.data.results.text_too_short.results.reco_pos_min_length :
                  this.props.data.results.text_too_short.results.reco_neg_min_length}
                </ReactTooltip>
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="py-3 dark-background">
            {Math.round(
              this.props.data.results.text_too_short.results.averageTooShort *
              100
            )}
            % Text too short
          </Col>
          <Col xs="4" className="py-3 dark-background">
            Minimum Characters:{" "}
            {this.props.data.results.text_too_short.results.min_length_variable}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.text_too_long.results
                      .averageTooLong * 100
                  }
                ></Cube>
              </Col>
              <Col xs="10">Conciseness
                {(this.props.data.results.text_too_long.results.reco_pos_max_length?.trim() ||
                  this.props.data.results.text_too_long.results.reco_neg_max_length) &&
                  <FontAwesomeIcon
                    className="info-icon"
                    icon="info-circle"
                    id="copy-concise-tooltip"
                  ></FontAwesomeIcon>}
                <ReactTooltip
                  anchorId="copy-concise-tooltip"
                  place="bottom"
                  variant="info"
                >{this.props.data.results.text_too_short.results.averageTooLong * 100 == 0 ?
                  this.props.data.results.text_too_long.results.reco_pos_max_length :
                  this.props.data.results.text_too_long.results.reco_neg_max_length}
                </ReactTooltip>
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="py-3 dark-background">
            {Math.round(
              this.props.data.results.text_too_long.results.averageTooLong * 100
            )}
            % Text too long
          </Col>
          <Col xs="4" className="py-3 dark-background">
            Maximum Characters:{" "}
            {this.props.data.results.text_too_long.results.max_length}
          </Col>
        </Row>



        {/* TExt Presence */}

       
        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data?.results.text_presence?.results?.textPresence===true?0:1
                  }
                ></Cube>
              </Col>
              <Col xs="10">Text Presence
              
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="py-3 dark-background">
          {this.props.data?.results.text_presence?.results?.textPresence===true?"Text is present":"No text detected"}
          </Col>
          <Col xs="4" className="py-3 dark-background">
           
          </Col>
        </Row>
      </>
    );
  }
}

const Cube = (props) => {
  return props.color == 0 ? (
    <img src={"/img/IconPassBig.png"} className="icon-score"></img>
  ) : props.color < 50 ? (
    <img src={"/img/IconWarningBig.png"} className="icon-score"></img>
  ) : (
    <img src={"/img/IconFailBig.png"} className="icon-score"></img>
  );
};

export default ImageCopywriting;
