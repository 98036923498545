import { SET_CURRENT_PAGE_TITLE } from "../actions/types";

const initialState = {
  title: "",
  icon: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE_TITLE:
      return {
        ...state,
        title: action.title,
        icon: action.icon,
      };

    default:
      return state;
  }
}
