/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import striptags from "striptags";
import _ from "lodash";

import axios from "axios";
import ErrorJSON from "../utils/Error";
import { ROOT_URL } from "../../constants";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Initialize state properties relevant to the project data
    this.state = {
      modal: false,
      _id: "",
      name: "",
      description: "",
      errors: null
    };
  }

  toggle(project) {
    // Deep clone the project object if it exists and contains the relevant data
    if (project && project._id && project.name ) {
      const clonedProject = _.cloneDeep(project);
      this.setState({ modal: !this.state.modal, ...clonedProject });
    } else {
      // If the project data is incomplete, just toggle the modal without updating the state
      this.setState({ modal: !this.state.modal });
    }
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
    this.setState({id : this.props.projectId._id})
  }

  handleSubmit(event) {
    event.preventDefault();
    axios
      .post(`${ROOT_URL}/api/projects/edit`, { ...this.state })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={() => this.toggle(this.props)}>
          <ModalHeader toggle={() => this.toggle()}>Edit project</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="name">Project name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.handleChange}
                  required
                  maxLength={30}
                  placeholder="Enter a name"
                  value={this.state.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Project description (optional)</Label>
                <Input
                  maxLength={120}
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={this.handleChange}
                  placeholder="Describe your project here"
                  value={this.state.description}
                />
              </FormGroup>
              {/* Add a hidden field to hold the id */}
              <input type="hidden" name="id" value={this.state._id} />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.toggle()}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Edit;
