import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class ImageTextRatio extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let scorefinal = (this.props.data.results?.correct_ratio?.results?.score || 0) * 100;
    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        <Row className="mt-2">
          <Col className="py-2" xs="4"></Col>
          <Col className="py-2 text-title dark-background" xs="4">
            RESULT
          </Col>
          <Col className="py-2 text-title dark-background" xs="4">
            RECOMMENDED
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results?.correct_ratio?.results?.correct_ratio
                  }
                ></Cube>
              </Col>
              <Col xs="10">Ratio Image:Text
                {(this.props.data.results?.correct_ratio?.results?.reco_pos_min_ratio?.trim() ||
                  this.props.data.results?.correct_ratio?.results?.reco_neg_min_ratio?.trim()) &&
                  <FontAwesomeIcon
                    className="info-icon"
                    icon="info-circle"
                    id="image-ratio-tooltip"
                    ></FontAwesomeIcon>}
                  <ReactTooltip
                    anchorId="image-ratio-tooltip"
                    place="bottom"
                    variant="info"
                  >{this.props.data.results?.correct_ratio?.results?.correct_ratio ?
                    this.props.data.results?.correct_ratio?.results?.reco_pos_min_ratio +
                    this.props.data.results?.correct_ratio?.results?.reco_pos_max_ratio :
                    this.props.data.results?.correct_ratio?.results?.reco_neg_min_ratio +
                    this.props.data.results?.correct_ratio?.results?.reco_neg_max_ratio}
                  </ReactTooltip>
              </Col>
            </Row>
          </Col>
          <Col xs="4" className="py-3 dark-background">
            {this.props.data.results?.correct_ratio?.results?.closest_ratio}
          </Col>
          <Col xs="4" className="py-3 dark-background">
            <Row>
              <Col>
                Min Ratio:{" "}
                {
                  this.props.data.results?.correct_ratio?.results
                    ?.min_expected_ratio
                }
              </Col>
            </Row>
            <Row>
              <Col>
                Max Ratio:{" "}
                {
                  this.props.data.results?.correct_ratio?.results
                    ?.max_expected_ratio
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageTextRatio;
