const geographies = [
  {
    pretty_name: "ASIAM",
    name: "asiam",
  },
  {
    pretty_name: "ASGR",
    name: "asgr",
  },
  {
    pretty_name: "Gallia",
    name: "gallia",
  },
  {
    pretty_name: "Greater China",
    name: "china",
  },
   {
    pretty_name: "Iberia",
    name: "iberia",
  },
  {
    pretty_name: "ICEG",
    name: "iceg",
  },
  {
    pretty_name: "Japan",
    name: "japan",
  },
  {
    pretty_name: "Latin America",
    name: "latin america",
  },
  {
    pretty_name: "UKI",
    name: "uki",
  },
  {
    pretty_name: "US Midwest",
    name: "us midwest",
  },
  {
    pretty_name: "US Northeast",
    name: "us northeast",
  },
  {
    pretty_name: "US South",
    name: "us south",
  },
  {
    pretty_name: "US West",
    name: "us west",
  },
];

export default geographies;
