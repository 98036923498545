/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import ErrorJSON from "../../utils/Error";
import { TagInput } from "reactjs-tag-input";

class EditBlock extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.id = this.props.id;

    this.state = {
      modal: false,
      results: {},
    };
  }

  toggle(results, blockname) {
    if (results) {
      this.setState({
        modal: !this.state.modal,
        results: results,
        blockname: blockname,
      });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    axios
      .post("/api/tasks/editblock", {
        id: this.id,
        blockname: this.state.blockname,
        results: this.state.results,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  handleChange(e, k, type) {
    var target = e.target;
    this.setState((prevState) => {
      var res = prevState.results;
      var val = type === "num" ? parseFloat(target.value) : target.value;
      res[k].results[target.name] =
        (type === "text" && val === "") || (type === "num" && isNaN(val))
          ? false
          : val;
      return { results: res };
    });
  }

  handleChangeMulti(e, k, name) {
    this.setState((prevState) => {
      var res = prevState.results;
      res[k].results[name] = e === null ? false : e.map((el) => el.value);
      return { results: res };
    });
  }

  handleList(tags, k, name) {
    this.setState((prevState) => {
      var res = prevState.results;
      res[k].results[name] =
        tags.length === 0 ? false : tags.map((el) => el.displayValue);
      return { results: res };
    });
  }

  render() {
    return (
      <div>
        <Modal
          centered={true}
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
        >
          <ModalHeader toggle={() => this.toggle()}>Edit results</ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            {Object.keys(this.state.results).map((k) => {
              return (
                <div>
                  <h6>{this.state.results[k].block_pretty}</h6>

                  {Object.keys(this.state.results[k].results.fields_input).map(
                    (key) => {
                      var field = this.state.results[k].results.fields_input[
                        key
                      ];

                      if (field.type == "num") {
                        return (
                          <FormGroup>
                            <Label for="name">{field.pretty}</Label>
                            <br></br>
                            <small>{field.description}</small>
                            <Input
                              type="number"
                              name={key}
                              onChange={(e) => this.handleChange(e, k, "num")}
                              value={this.state.results[k].results[key]}
                            />
                          </FormGroup>
                        );
                      } else if (field.type == "choice") {
                        return (
                          <FormGroup>
                            <Label for="name">{field.pretty}</Label>
                            <br></br>
                            <small>{field.description}</small>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={
                                this.state.results[k].results[key].length >=
                                field.options.max
                                  ? field.options.choices.filter(
                                      (el) =>
                                        this.state.results[k].results[
                                          key
                                        ].indexOf(el.value) !== -1
                                    )
                                  : field.options.choices
                              }
                              isMulti
                              onChange={(e, action) =>
                                this.handleChangeMulti(e, k, key)
                              }
                              defaultValue={
                                this.state.results[k].results[key] === false
                                  ? []
                                  : field.options.choices.filter(
                                      (el) =>
                                        this.state.results[k].results[
                                          key
                                        ].indexOf(el.value) !== -1
                                    )
                              }
                            />
                          </FormGroup>
                        );
                      } else if (field.type == "list") {
                        return (
                          <FormGroup>
                            <Label for="name">{field.pretty}</Label>
                            <br></br>
                            <small>{field.description}</small>
                            <TagInput
                              tagStyle={`
                            background: #972ad6;
                            font-size: 14px;
                          `}
                              inputStyle={`
                            color: #66615b;
                            font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
                            font-weight: 400;
                            line-height: normal;
                            font-size: 14px;
                            background: white;
                            &::placeholder {
                                font-style: normal;
                                
                              }
                           
                          `}
                              wrapperStyle={`
                            background: white;
                            box-shadow: none;
                            border:1px;
                            position:relative;
                            transform:none;
                            left:0;
                            top:0;
                            width:100%;
                            border: 1px solid #DDDDDD;
                            border-radius: 25px !important;

                          `}
                              tags={
                                this.state.results[k].results[key] === false
                                  ? []
                                  : this.state.results[k].results[key].reduce(
                                      (acc, val) => {
                                        acc.push({ displayValue: val });
                                        return acc;
                                      },
                                      []
                                    )
                              }
                              onTagsChanged={(e) => this.handleList(e, k, key)}
                            />
                          </FormGroup>
                        );
                      } else if (field.type == "text") {
                        return (
                          <FormGroup>
                            <Label for="name">{field.pretty}</Label>
                            <br></br>
                            <small>{field.description}</small>
                            <Input
                              type="text"
                              name={key}
                              onChange={(e) => this.handleChange(e, k, "text")}
                              value={
                                this.state.results[k].results[key] !== false
                                  ? this.state.results[k].results[key]
                                  : ""
                              }
                            />
                          </FormGroup>
                        );
                      }
                    }
                  )}
                </div>
              );
            })}
            <ModalFooter>
              <Button color="secondary" onClick={() => this.toggle()}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.handleSubmit}>
                Save
              </Button>{" "}
            </ModalFooter>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default EditBlock;
