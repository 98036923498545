/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../../utils/Error";
import { ROOT_URL } from "../../../constants";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      modal: false,
      initialData: {
        name: this.props.team.name,
        description: this.props.team.description,
        quota_image: this.props.team.quota_image.toString(),
        quota_video: this.props.team.quota_video.toString(),
        quota_dom: this.props.team.quota_dom.toString(),
        quota_genai: this.props.team.quota_genai?.toString(),
        quota_genai4: this.props.team.quota_genai4?.toString(),
        wbs: this.props.team.wbs,
        max_members: this.props.team.max_members.toString(),
      },
      name: this.props.team.name,
      description: this.props.team.description,
      quota_image: this.props.team.quota_image.toString(),
      quota_video: this.props.team.quota_video.toString(),
      quota_dom: this.props.team.quota_dom.toString(),
      quota_genai: this.props.team.quota_genai?.toString(),
      quota_genai4: this.props.team.quota_genai4?.toString(),
      wbs: this.props.team.wbs,
      max_members: this.props.team.max_members.toString(),
    };
  }

  toggle() {
    if (this.state.modal) {
      this.handleCancel();
    }
    this.setState({ modal: !this.state.modal });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val).toString() });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ errors: false });

    axios
      .post(`${ROOT_URL}/api/admin/teams/edit`, { id: this.props.team._id, ...this.state })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  handleCancel() {
    this.setState((prevState) => ({
      ...prevState.initialData,
      modal: false,
    }));
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit team</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="name">Team name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.handleChange}
                  value={this.state.name}
                  required
                  maxLength={30}
                  placeholder="Enter a name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Team description</Label>
                <Input
                  maxLength={120}
                  type="textarea"
                  name="description"
                  value={this.state.description}
                  id="description"
                  onChange={this.handleChange}
                  placeholder="Describe your project here"
                />
              </FormGroup>

              <FormGroup>
                <Label for="description">WBS</Label>
                <Input
                  maxLength={120}
                  type="text"
                  name="wbs"
                  id="wbs"
                  value={this.state.wbs}
                  onChange={this.handleChange}
                  placeholder="WBS of the team"
                />
              </FormGroup>

              <FormGroup>
                <Label for="quota_image">Image quota</Label>
                <Input
                  type="number"
                  name="quota_image"
                  id="image_quota"
                  max="99999"
                  required
                  min="0"
                  value={this.state.quota_image}
                  onChange={this.handleChange}
                  placeholder="Image quota"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quota_video">Video quota</Label>
                <Input
                  type="number"
                  name="quota_video"
                  id="video_quota"
                  required
                  max="99999"
                  value={this.state.quota_video}
                  min="0"
                  onChange={this.handleChange}
                  placeholder="Video quota"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quota_dom">HTML quota</Label>
                <Input
                  type="number"
                  name="quota_dom"
                  id="quota_dom"
                  max="99999"
                  required
                  min="0"
                  value={this.state.quota_dom}
                  onChange={this.handleChange}
                  placeholder="HTML quota"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quota_genai">GenAI quota</Label>
                <Input
                  type="number"
                  name="quota_genai"
                  id="quota_genai"
                  max="99999"
                  required
                  min="0"
                  value={this.state.quota_genai}
                  onChange={this.handleChange}
                  placeholder="GenAi quota"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quota_genai">GenAI 4.0 quota</Label>
                <Input
                  type="number"
                  name="quota_genai4"
                  id="quota_genai4"
                  max="99999"
                  required
                  min="0"
                  value={this.state.quota_genai4}
                  onChange={this.handleChange}
                  placeholder="GenAi 4.0 quota"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Maximum number of members</Label>
                <Input
                  type="number"
                  name="max_members"
                  id="max_members"
                  required
                  max="99999"
                  min="0"
                  value={this.state.max_members}
                  onChange={this.handleChange}
                  placeholder="Max members"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Edit;
