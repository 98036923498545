import { SET_SCREEN_SHOT } from "./types";

// Set logged in user
export const setScreenShot = (task) => {
  return {
    type: SET_SCREEN_SHOT,
    screen_shot: task,
  };
};


