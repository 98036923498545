import React from "react";

import axios from "axios";
import { Col, Card, CardBody, Badge, Button } from "reactstrap";

import DataTable from "react-data-table-component";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Navigate } from 'react-router-dom';
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { ROOT_URL } from "../../constants";

class ManageIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Manage teams", "shield-alt"));
    localStorage.setItem('selectedMenuItem', "manageteams");
    axios
      .get(`${ROOT_URL}/api/teams/getforcurrentuser`)
      .then((res) => {
        this.setState({ teams: res.data.teams.filter((row) => row.leader) });
      })
      .catch((err) => {
           this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>CRISP - Manage teams</title>
        </Helmet>

        <Card>
          <CardBody>
            <DataTable
              title="Teams"
              pagination={true}
              columns={columns}
              striped={true}
              data={this.state.teams}
              subHeader={true}
              className="clickable-rows "
              onRowClicked={(row) =>
                window.location.replace("/teams/manage/" + row.team._id)
              }
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const columns = [
  {
    name: "Name",
    cell: (row) => {
      if (row.blocked === true) {
        return (
          <span className="text-center">
            <b>{row.name}</b> <br></br>
            <Badge color="danger">BLOCKED</Badge>
          </span>
        );
      } else {
        return <b>{row.team.name}</b>;
      }
    },
    selector:(row) => row.team.name,
    sortable: true,
    center: true,
  },
  {
    name: "Description",
    selector: (row) => row.team.description,
    sortable: true,
    center: true,
  },
  {
    name: "Last activity",
    selector: (row) => moment(row.team.modified).unix(),
    cell: (row) => moment(row.team.modified).format("lll"),
    sortable: true,
    center: true,
  },
];

export default connect()(ManageIndex);