// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/',
    clientId: '6affa01e-3b32-4d6b-bf00-e7670045f064',
    redirectUri: 'https://crisp.relevanceengine.demo.accenture.com/check/azure',
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["https://graph.windows.net/User.Read"],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/check/azure",
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
