/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Tooltip,
} from "reactstrap";
import striptags from "striptags";
import industries from "../../variables/industries";
import geographies from "../../variables/geographies";
import channels from "../../variables/channels";
import types from "../../variables/types";
import axios from "axios";
import ErrorJSON from "../utils/Error";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROOT_URL } from "../../constants";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
      projects: [],
      tasks: [],
      oldTasks: [],
      team: { public: false },
      errors: false,
      tasksUpdated: false
    };
  }

  toggle(_task, team_id) {
    this.setState({ errors: false });
    if (_task._id) {
      var task = JSON.parse(JSON.stringify(_task));
      this.setState({
        modal: !this.state.modal,
        ...task,
        oldTasks: task.tasks !== undefined ? task.tasks.slice(0) : [],
      });
      axios
        .get(`${ROOT_URL}/api/projects/getlistperteam`, { params: { id: team_id } })
        .then((res) => {
          this.setState({
            projects: res.data.projects,
            team: res.data.team,
          });
        })
        .catch((err) => {
        });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  handleCheck(value) {
    this.setState((prevState) => {
      const tasks = [...prevState.tasks];

      let tasksUpdated = prevState.tasksUpdated;

      if (tasks.indexOf(value) !== -1) {

        const index = tasks.indexOf(value);
        tasks.splice(index, 1);
        tasksUpdated = true;
      } else {
        tasks.push(value);

        tasksUpdated = true;
      }

      return { tasks, tasksUpdated }; // Update both tasks and tasksUpdated
    });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    if (nam === "tasks") {
      val = JSON.parse(val);
      this.setState({ [nam]: val });
    } else {
      this.setState({ [nam]: striptags(val) });
    }
  }

  componentWillUnmount() {
    this.setState({
      modal: false,
      projects: [],
      tasks: [],
      oldTasks: [],
      team: { public: false },
      errors: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (
      this.state.tasks.indexOf("report") !== -1 &&
      (this.state.industry === "" || this.state.type === "")
    ) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    if (this.state.tasksUpdated) {
      var updatedTasks;
      if (this.state.media === 'video' && this.state.tasks.includes('report')) {
         updatedTasks = this.state.tasks.map(task => task === 'report' ? 'videopredictivedesign' : task);
      }
      else {
        updatedTasks = this.state.tasks
      }
      axios
        .post(`${ROOT_URL}/api/tasks/create`, {
          channel: this.state.channel,
          errors: false,
          extension: this.state.media==='video'?"mp4":"jpeg",
          geography: this.state.geography,
          industry: this.state.industry,
          label: this.state.label,
          media: this.state.url ? "dom" : this.state.media,
          method: this.state.url ? "url" : "upload",
          modal: false,
          project: this.state.project._id ? this.state.project._id : this.state.project,
          public: false,
          submitted: false,
          tasks: updatedTasks,
          team: this.state.team._id,
          // teams: [{_id: "64378f1b49a659fd09d9dc0f",…}, {_id: "63b979063b01030008732dac",…},…],
          type: "webpage",
          upload_id: this.state.upload_id,
          url: this.state.url,

        })
        .then((res) => {
          window.location.href = "/projects/" + res.data.project;
        })
        .catch((err) => {
          this.setState({ submitted: false });

          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    }
    else {
      axios
        .post(`${ROOT_URL}/api/tasks/edit`, {
          project: this.state.project,
          label: this.state.label,
          industry: this.state.industry,
          channel: this.state.channel,
          geography: this.state.geography,
          client: this.state.client,
          type: this.state.type,
          tasks: this.state.tasks,
          id: this.state._id,
          url: this.state.url
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    }

  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit task</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="name">Task label</Label>
                <Input
                  type="text"
                  name="label"
                  id="name"
                  onChange={this.handleChange}
                  required
                  maxLength={30}
                  placeholder="Enter a label"
                  value={this.state.label}
                // readOnly 
                />
              </FormGroup>
              {this.state.team.public ? (
                <span></span>
              ) : (
                <FormGroup>
                  <Label for="name">Project</Label>
                  <Input
                    onChange={this.handleChange}
                    required
                    type="select"
                    name="project"
                  >
                    <option disabled selected value="">
                      Select the project of the task
                    </option>
                    {this.state.projects.map((project) => {
                      return (
                        <option
                          selected={
                            this.state.project?._id === project._id
                              ? true
                              : false
                          }
                          value={project._id}
                        >
                          {project.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}

              <FormGroup className="form-create-check" check>
                <Tooltiped
                  id="vco"
                  text="This feature is only available for upgraded accounts."
                  disabled={this.props?.access?.indexOf("vco") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("vco") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("vco") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("vco")}
                          type="checkbox"
                          checked={
                            this.state.tasks.includes("vco")
                          }
                          disabled={
                            this.props?.access?.indexOf("vco") === -1
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props?.access?.indexOf("vco") === -1
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props?.access?.indexOf("vco") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Visual Attention Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="memorability"
                  text="This feature is only available for upgraded accounts."
                  disabled={this.props?.access?.indexOf("memorability") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("memorability") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("memorability") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("memorability")}
                          type="checkbox"
                          checked={
                            this.state.tasks.includes("memorability")
                          }
                          disabled={
                            this.props?.access?.indexOf("memorability") === -1
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props?.access?.indexOf("memorability") === -1
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props?.access?.indexOf("memorability") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Memorability Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="report"
                  text={
                    this.props?.access?.indexOf("report") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={this.props?.access?.indexOf("report") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("report") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("report") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("report")}
                          type="checkbox"
                          checked={
                            this.state.tasks.includes("report") || this.state.tasks.includes("videopredictivedesign")
                          }

                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props?.access?.indexOf("report") === -1
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props?.access?.indexOf("report") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Predictive design
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="experience"
                  text={
                    this.props?.access?.indexOf("experience") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={this.props?.access?.indexOf("experience") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("experience") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("experience") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("experience")}
                          type="checkbox"
                          disabled={
                            this.state.media !== "dom"
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props?.access?.indexOf("experience") === -1 ||
                              this.state.media !== "dom"
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props?.access?.indexOf("experience") === -1 ||
                          this.state.media !== "dom"
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Page Experience Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="ibmaccessibility"
                  text={
                    this.props?.access?.indexOf("ibmaccessibility") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={this.props?.access?.indexOf("ibmaccessibility") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("ibmaccessibility") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("ibmaccessibility") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("ibmaccessibility")}
                          type="checkbox"
                          disabled={
                            this.state.media !== "dom"
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props?.access?.indexOf("ibmaccessibility") === -1 ||
                              this.state.media !== "dom"
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props?.access?.indexOf("ibmaccessibility") === -1 ||
                          this.state.media !== "dom"
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Accessibility Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="accessibility"
                  text={
                    this.props?.access?.indexOf("accessibility") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={
                    this.props?.access?.indexOf("accessibility") === -1 ||
                    !(this.state.media === "dom" && this.state.type === "email")
                  }
                >

                </Tooltiped>
              </FormGroup>

              <FormGroup>
                <Label for="name">Content type</Label>
                <Input onChange={this.handleChange} type="select" name="type">
                  <option disabled selected value="">
                    Select the type of content
                  </option>
                  {types.map((type) => {
                    if (type.available_for.indexOf(this.state.media) !== -1) {
                      return (
                        <option
                          selected={
                            this.state.type === type.name ? true : false
                          }
                          key={type.name}
                          value={type.name}
                        >
                          {type.pretty_name}
                        </option>
                      );
                    }
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="name">Industry</Label>
                <Input
                  onChange={this.handleChange}
                  type="select"
                  name="industry"
                >
                  <option disabled selected value="">
                    Select the industry
                  </option>
                  {industries.map((industry) => {
                    return (
                      <option
                        selected={
                          this.state.industry === industry.name ? true : false
                        }
                        key={industry.name}
                        value={industry.name}
                      >
                        {industry.pretty_name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="name">Geography</Label>
                <Input
                  onChange={this.handleChange}
                  type="select"
                  name="geography"
                >
                  <option disabled selected value="">
                    Select the geography
                  </option>
                  {geographies.map((geography) => {
                    return (
                      <option
                        selected={
                          this.state.geography === geography.name ? true : false
                        }
                        key={geography.name}
                        value={geography.name}
                      >
                        {geography.pretty_name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              {this.state.type == "banner" ? (
                <FormGroup>
                  <Label for="name">Channel</Label>
                  <Input
                    onChange={this.handleChange}
                    type="select"
                    name="channel"
                  >
                    <option disabled selected value="">
                      Select the channel
                    </option>
                    {channels.map((channel) => {
                      return (
                        <option
                          selected={
                            this.state.channel === channel.name ? true : false
                          }
                          key={channel.name}
                          value={channel.name}
                        >
                          {channel.pretty_name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              ) : (
                <span></span>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Edit;

const Tooltiped = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={props.id}>{props.children}</span>
      {props.disabled == true ? (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={props.id}
          toggle={toggle}
        >
          {props.text}
        </Tooltip>
      ) : (
        <span></span>
      )}
    </span>
  );
};