import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

class SampleNotImplemented extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h6 className="text-warning w-100 text-center">
          This module has not been implemented yet. You can edit the results.{" "}
        </h6>

        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Sample
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 text-title dark-background">
                Sample
              </Col>
            </Row>
          </Col>
          {/* EDIT ICON */}
          <FontAwesomeIcon
            onClick={() =>
              this.props.editRef.toggle(
                this.props.data.results,
                "notimplemented"
              )
            }
            className="icon-card clickable mr-2 pull-right"
            icon="edit"
          title="Edit values"

          />
        </Row>
        <Row className="border-row">
          <Col className="py-2" xs="4">
            Field 1
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 dark-background">
                {this.props.data.results.test.results.field_1 === false
                  ? "Not provided"
                  : this.props.data.results.test.results.field_1.map((el) => (
                      <span>
                        {el}
                        <br />
                      </span>
                    ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="border-row">
          <Col className="py-2" xs="4">
            Field 2
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 dark-background">
                {this.props.data.results.test.results.field_2 === false
                  ? "Not provided"
                  : this.props.data.results.test.results.field_2}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="border-row">
          <Col className="py-2" xs="4">
            Field 3
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 dark-background">
                {this.props.data.results.test.results.field_3 === false
                  ? "Not provided"
                  : this.props.data.results.test.results.field_3}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="border-row">
          <Col className="py-2" xs="4">
            Field 4
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 dark-background">
                {this.props.data.results.test.results.field_4 === false
                  ? "Not provided"
                  : this.props.data.results.test.results.field_4.map((el) => (
                      <span>
                        {el}
                        <br />
                      </span>
                    ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SampleNotImplemented;
