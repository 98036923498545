import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";
import { Modal, Button } from 'react-bootstrap';


class VideoShot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  setShowModal = (showModal) => {
    this.setState({ showModal });
  };


  render() {
    const shot = this.props.data.mcu_shot;
    const { showModal } = this.state;
console.log("shot ",shot.length)
    return (
      <>
        <Row id="usability" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">SHOT</div>
            </Row>
          
            <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>

                    <Col xs="10">{shot?.length}
                    {shot?.length>0 && (
         <a
          
            onClick={() => this.setShowModal(true)}
          > (More Info) </a>
        )}
        <ReactTooltip
          id={`image-ratio-tooltip-shot`}
          place="bottom"
          variant="info"
        >
          Click for detailed Shot data
        </ReactTooltip>

        {/* Modal for displaying face details */}
        <Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Shot Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {shot?.map((face, index) => (
              <div key={index}>
                <h4>Shot {index }</h4>
                <ul>
                  <li>Start Time: {face.start_time}</li>
                  <li>End Time: {face.end_time}</li>
                </ul>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
                    </Col>
                  </Row>
                </Col>
              
              </Row>
            
          </Col>
        </Row>
      </>
    );
  }
}

export default VideoShot;

