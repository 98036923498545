import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Navigate,
  Routes,
  Switch,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import IndexTeam from "./teams/Index";
import IndexUser from "./users/Index";
import ViewTeam from "./teams/View";
import ViewUser from "./users/View";
import LogsIndex from "./Logs";
import Explorer from "./Explorer";
import Feedbacks from "./Feedbacks";
import IndexProjects from "./raws/IndexProjects";
import Project from "./raws/Project";
import RawTask from "./raws/Task";
class AdminLayout extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/admin/dashboard" element={<Dashboard/>} />
        <Route path="/admin/teams" element={<IndexTeam/>} />
        <Route path="/admin/teams/:id" element={<ViewTeam/>} />
        <Route path="/admin/users" element={<IndexUser/>} />
        <Route path="/admin/users/:id" element={<ViewUser/>} />
        <Route path="/admin/logs" element={<LogsIndex/>} />
        <Route path="/admin/explorer" element={<Explorer/>} />
        <Route path="/admin/feedbacks" element={<Feedbacks/>} />
        <Route path="/admin/raws" element={<IndexProjects/>} />
        <Route path="/admin/raws/projects/:id" element={<Project/>} />
        <Route path="/admin/raws/tasks/:id" element={<RawTask/>} />
        <Route render={() => <Navigate to="/404" />} />
      </Routes>
    );
  }
}

AdminLayout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminLayout);
