import admin_codes from "./admin_codes";
import public_codes from "./public_codes"
import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Badge } from "reactstrap";
const admin_logs_col = [
  {
    name: "Date",
    selector: (row) => moment(row.creation).unix(),
    cell: (row) => moment(row.creation).format("lll"),
    sortable: true,
    center: true,

  },
  {
    name: "Type",
    selector: row => admin_codes(row).pretty,
    cell: (row) => {
      return (
        <Badge color={admin_codes(row).color}>{admin_codes(row).pretty}</Badge>
      );
    },
    sortable: true,
    center: true,

  },
  {
    name: "Log",
    cell: (row) => admin_codes(row).content,
    sortable: false,
    center: true,
  },
];

const public_logs_col = [
  {
    name: "Date",
    selector: (row) => moment(row.creation).unix(),
    cell: (row) => moment(row.creation).format("lll"),
    sortable: true,
    center: true,

  },
  {
    name: "Type",
    selector: row => admin_codes(row).pretty,
    cell: (row) => {
      return (
        <Badge color={public_codes(row).color}>{public_codes(row).pretty}</Badge>
      );
    },
    sortable: true,
    center: true,

  },
  {
    name: "Log",
    cell: (row) => public_codes(row).content,
    sortable: false,
    center: true,
  },
];

const Logs = (props) => {
  return (
    <DataTable
      pagination={true}
      columns={props.public ? public_logs_col : admin_logs_col}
      data={props.logs}
    ></DataTable>
  );
};

export default Logs;
