import React, { useState } from "react";
import axios from "axios";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import industries from "../../variables/industries";
import geographies from "../../variables/geographies";
import channels from "../../variables/channels";
import types from "../../variables/types";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ErrorJSON from "../utils/Error";

import { ReportTab } from "./Report";

class ViewTask extends React.Component {
  constructor(props) {
    super(props);

    this.task = this.props.match.params.id;

    this.state = {
      task: false,
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    axios
      .get(`/staticreports/${this.task}.json`)
      .then((response) => {
        this.setState({ task: response.data });
        console.log("responseJson", response.data);
        dispatch(setPageTitle(response.data.title, "file-alt"));
      })

      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    var task = this.state.task;
  

    return (
      <div className="content">
        <Helmet>
          <title>{`CRISP - ${this.state.task.title}`}</title>
        </Helmet>
        {this.state.errors ? (
          <ErrorJSON json={this.state.errors}></ErrorJSON>
        ) : (
          <span></span>
        )}
        <div className="px-3">
          {!task ? (
            <span></span>
          ) : (
            <>
              <Row className="my-5 justify-content-md-right">
                <Col xs="6">
                  <Row className="justify-content-center">
                    <Col xs="12">
                      {this.state.task.channel == "" ? (
                        <></>
                      ) : (
                        <Row>
                          <Col
                            className="justify-content-end text-right"
                            xs="5"
                          >
                            <b>Channel:</b>
                          </Col>
                          <Col xs="6">{this.state.task.channel}</Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Type:</b>
                        </Col>
                        <Col xs="6">{this.state.task.type}</Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Industry:</b>
                        </Col>
                        <Col xs="6">{this.state.task.industry}</Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Geography:</b>
                        </Col>
                        <Col xs="6">{this.state.task.geography}</Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Content analyzed:</b>
                        </Col>
                        <Col xs="6">{this.state.task.content_analyzed}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="justify-content-center text-center">
                  {" "}
                  <LazyLoadImage
                    placeholderSrc={
                      "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                    }
                    effect="blur"
                    className=" max-height  justify-content-center text-center"
                    src={this.state.task.image}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
        <div className="text-dark-blue">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <ReportTab task={this.state.task}></ReportTab>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

// const Example = (props) => {};

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(ViewTask);
