import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ROOT_URL } from '../../../constants';

const IBMAccesibility = (props) => {
  const [error, setError] = useState(false);
  const [presignedURL, setPresignedURL] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [task,setTask]= useState([]);

  useEffect(() => {

    axios.get(`/output/${task.upload_id}/accessibility_report/accessibility.html`,{
          headers: {
            'Cache-Control': 'no-cache'
        }
      })
        .then(res => {
          console.log(res.data)
          // this.setState({ jsonReport: res.data , isLoading: false })
        }).catch(err => {
          console.log("error in serveattachment=lighthouse")
          // this.setState({ error: 'Error fetching JSON file.', isLoading: false  });
        })
    if (props.task.upload_id) {
      setTask(props.task)
      setShowLoader(false)
    }
    if((props.task.output_path).includes("accessibility.htm")){
      setError(true)
    }
  }, [props.task.upload_id]);


//   useEffect(axios.get(`https://demo.ai4ccrisp.accentureanalytics.com/output/79bcd71b-410a-490c-812c-44ed926aadf7/accessibility_report/accessibility.html`,{
//     headers: {
//       'Cache-Control': 'no-cache'
//   }
// })
//   .then(res => {
//     console.log(res.data)
//     // this.setState({ jsonReport: res.data , isLoading: false })
//   }).catch(err => {
//     console.log("error in serveattachment=lighthouse", err)
//     // this.setState({ error: 'Error fetching JSON file.', isLoading: false  });
//   }),[])
  // const getAccessibilityReport = () => {
  //   setShowLoader(true);
  //   if (props.accessibilitystatus) {
  //     axios
  //       .post(`${ROOT_URL}/api/tasks/getPresignedURL`, {
  //         taskId: props.taskId
  //       })
  //       .then((res) => {
  //         console.log("presigned URL is:", res.data.url);
  //         setPresignedURL(res.data.url);
  //         setError(res.data.success);
  //         setShowLoader(false);
  //       })
  //       .catch((err) => {
  //         setError(false);
  //         setShowLoader(false);
  //       });
  //   } else {
  //     setError(false);
  //     setShowLoader(false);
  //   }

  // }
 
  return ( 
    
    <div>
      { (task.accessibility_status)?
      (<iframe src={`/output/${task.upload_id}/accessibility_report/accessibility.html`} className="accessibility-iframe" />):
      (<div className="loading-div">
          <span>The report failed to load. This may be because the requested website is blocking webcrawling & thereby inhibiting report generation.</span>
        </div>)
      
//         <object data={`${props.task.output_path}/accessibility.htm`} width="600" height="400">
//     <embed src={`${props.task.output_path}/accessibility.htm`} width="600" height="400"> </embed>
//     Error: Embedded data could not be displayed.
// </object>
      }
      {
        showLoader && <div className="loading-div">
          <span>The report is still loading. Please try again in sometime.</span>
        </div>
      }
      {/* {
        !error && <div className="loading-div">
          <span>The report failed to load. This may be because the requested website is blocking webcrawling & thereby inhibiting report generation.</span>
        </div>
      } */}
    </div >
  )
}
export default (IBMAccesibility);
