import React from "react";

const Layout = (props) => {
  return (
    <div>
      <div className="wrapper wrapper-full-page">
        <div className="full-page">
          <div className="full-page-background">
            <div className="container">{props.children}</div>
            <div className="powered-by-text">Powered by Accenture Song | AI for Customer</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;