import React, { useState } from "react";

import axios from "axios";
import { Row, Col, Card, CardBody, Badge, Button, Progress } from "reactstrap";
import Edit from "./Edit";
import Enable from "./Enable";
import Promote from "./Promote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import moment from "moment";
import Logs from "../../log/Log";
import { connect } from "react-redux";
import { setPageTitle } from "../../../actions/titleActions";
import json_access from "../../../variables/access"
import { Helmet } from "react-helmet";
import TaskCard from "../../task/TaskCard";
import { ROOT_URL } from "../../../constants";

class ViewUser extends React.Component {
  constructor(props) {
    super(props);

    // this.user = this.props.match.params.id;
    this.user = window.location.pathname.split(/[/]+/).pop();
    this.state = {
      user: false,
      loading: true,
    };
    this.ref = React.createRef();
  }

  scroll = (scrollOffset) => {
    this.ref.current.scrollLeft += scrollOffset;
  };

  componentDidMount() {
    axios
      .get(`${ROOT_URL}/api/admin/users/getuser`, { params: { id: this.user } })
      .then((res) => {
        const { dispatch } = this.props;
        dispatch(setPageTitle(res.data.full_name, "user"));
        this.setState({ user: res.data, loading: false });
      })
      .catch((err) => {
        this.setState({ error: "Failed to fetch user data.", loading: false });
      });
  }
  

  render() {
    const { user, error, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    if (!user) {
      return <div>No user data available.</div>;
    }
    return (
      <div className="content">
        <Helmet>
          <title>{`CRISP - ${this.state.user.full_name}`}</title>
        </Helmet>
        {!this.state.user ? (
          <span></span>
        ) : (
          <div>
            <Edit
              ref={(modal) => {
                this.edit = modal;
              }}
              user={this.state.user}
            ></Edit>

            <Enable
              ref={(modal) => {
                this.enable = modal;
              }}
              user={this.state.user._id}
              enabled={this.state.user.enabled}
            ></Enable>

            <Promote
              ref={(modal) => {
                this.promote = modal;
              }}
              user={this.state.user._id}
              admin={this.state.user.admin}
            ></Promote>

            <FontAwesomeIcon
              icon="arrow-left"
              className="prev clickable"
              onClick={() => window.location.replace("/admin/users")}
            ></FontAwesomeIcon>
            <Row>
              <Col xs="5">
                <Card>
                  <CardBody>
                    <div className="w-100">
                    <FontAwesomeIcon
                          icon="edit"
                          className="h5 m-0 clickable pull-right "
                          onClick={() => this.edit.toggle()}
                        ></FontAwesomeIcon>
                      {this.state.user.admin ? (
                        <Badge className="pull-right mr-2" color="info">
                          ADMIN
                        </Badge>
                      ) : (
                        <span></span>
                      )}

                      {!this.state.user.enabled ? (
                        <Badge className="pull-right mr-2" color="danger">
                          DISABLED
                        </Badge>
                      ) : (
                        <span></span>
                      )}
                      <h3>
                        {this.state.user.full_name}
        
                      </h3>
                    </div>

                    <p className="block-info">
                      <span className="title-info">Date of registration: </span>
                      {moment(this.state.user.creation).format("lll")} <br />
                      <span className="title-info">Email: </span>
                      {this.state.user.email} <br />
                      <span className="title-info">Job title:</span>{" "}
                      {this.state.user.job_title} <br />
                      <span className="title-info">City:</span>{" "}
                      {this.state.user.city} <br />
                      <span className="title-info">
                        Business organization:
                      </span>{" "}                 {this.state.user.business_org} <br />
                      <span className="title-info">
                        Accesses: {this.state.user.access.map(access => {
                          return (<Badge className="ml-2" color ={json_access[access].color}>{json_access[access].pretty}</Badge>)
                        })}
                      </span>{" "}
                      <br />
                      <span className="title-info">Last login: </span>
                      {moment(this.state.user.last_login).format("lll")} <br />
                      <span className="title-info">Feedbacks:</span>
                      <br />
                      <span className="title-info">
                        Quotas:{" "}
               
                      </span>
                    </p>

                    <div>
                      <div className="w-100">
                        <span className="pull-left">Images</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_image}/
                          {this.state.user.quota_image}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_image /
                            this.state.user.quota_image) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_image /
                                this.state.user.quota_image) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_image /
                            this.state.user.quota_image) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">Videos</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_video}/
                          {this.state.user.quota_video}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_video /
                            this.state.user.quota_video) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_video /
                                this.state.user.quota_video) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_video /
                            this.state.user.quota_video) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">HTML</span>{" "}
                        <span className="pull-right">
                          {this.state.user.current_quota_dom}/
                          {this.state.user.quota_dom}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.user.current_quota_dom /
                            this.state.user.quota_dom) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.user.current_quota_dom /
                                this.state.user.quota_dom) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.user.current_quota_dom /
                            this.state.user.quota_dom) *
                          100
                        }
                      />
                    </div>
                    <div className="text-right mt-3">
                      <Button
                        color={this.state.user.enabled ? "danger" : "success"}
                        onClick={() => this.enable.toggle()}
                      >
                        {this.state.user.enabled ? "Disable" : "Enable"}
                      </Button>
                      <Button
                        color={this.state.user.admin ? "danger" : "info"}
                        onClick={() => this.promote.toggle()}
                      >
                        {this.state.user.admin ? "Demote" : "Promote"}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="7">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Statistics</h4>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Teams</h4>
                    {user.teams ? (
          <DataTable pagination={true} columns={teams_col} data={user.teams} />
        ) : (
          <div>Loading teams...</div>
        )}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Logs</h4>
                    {user.logs ? <Logs logs={user.logs}></Logs> : <div>Loading logs...</div>}
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Last tasks</h4>

                    <div className="container-scroll" ref={this.ref}>
                    {(this.state.user.last_tasks?.length>0)?(     <div className="chevron-height">
                <div className="chevron-left" onClick={() => this.scroll(-70)}>
                  <i className="fas fa-chevron-left"></i>
                </div>
              </div>):null}
                      {this.state.user.last_tasks?.map((task) => ( 
                        <TaskCard
                          key={task._id}
                          token={this.props.resource_token.resource_token}
                          history={this.props.history}
                          noedit={true}
                          task={task}
                        ></TaskCard>
                      ))}
                    <div className="chevron-spacing">&nbsp;</div>
                    {(this.state.user.last_tasks?.length>0)?(     <div className="chevron-height-right">
                <div className="chevron-right" onClick={() => this.scroll(70)}>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>):null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const teams_col = [
  {
    name: "Name",
    cell: (row) => {
      var el = [];
      if (row.team) {
        el.push(<a href={`/admin/teams/${row.team._id}`}>{row.team.name}</a>);
        if (row.leader) {
          el.push(
            <Badge className="badge-sm ml-2" color="info">
              LEADER
            </Badge>
          );
        } else {
          el.push(<span></span>);
        }
      } else {
        el.push(<span>Loading team...</span>);
      }

      return el;

      return el;
    },
    selector: "team.name",
    sortable: true,
    center: true,
  },
  {
    name: "Number of members",
    selector: (row) => (row.team && row.team.members ? row.team.members.length : 0),
    sortable: true,
    center: true,
  },

  {
    name: "Last activity",
    selector: (row) => (row.team ? moment(row.team.modified).unix() : 0),
    cell: (row) => (row.team ? moment(row.team.modified).format("lll") : "N/A"),
    sortable: false,
    center: true,
  },
];

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});



export default connect(mapStateToProps)(ViewUser);
