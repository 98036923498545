export const taskNames =  [
  {"id":1,"name": "vco", "displayName":"Visual Attention Report"},
  {"id":2,"name": "memorability", "displayName":"Memorability Prediction"},
  {"id":3,"name":  "report", "displayName":"Predictive Design"},
  {"id":4,"name":  "experience", "displayName":" Page Experience Report","disableType":"mp4"},
  {"id":5,"name":  "ibmaccessibility", "displayName":"Accessibility Report","disableType":"mp4"},
  {"id":6,"name":  "email_accessibility", "displayName":"Email Accessibility Report","disableType":"dom"},
  {"id":7,"name":  "bbva", "displayName":" BBVA Report"},
  {"id":8,"name":  "videopredictivedesign", "displayName":"Predictive Design"}
]

export const responseTask =  ["memorability","vco","email_accessibility","ibmaccessibility","report","bbva"]


