import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

class ImageEmotions extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let scorefinal =
      this.props.data.results.correct_emotion.results.score * 100;
    this.props.generateScore(scorefinal);
  }

  render() {
    const result = this.props.data.results.correct_emotion.results;

    return (
      <>
        <Row className="mt-3 no-top-border">
          <Col xs="12" className="d-flex mb-2">
            <div className="emotion-color score-color-one">Anger and Sorrow 0% - 50% <br /> Joy and Surprise 51% - 100%</div>
            <div className="emotion-color score-color-five">Anger and Sorrow 51% - 100% <br /> Joy and Surprise 0% - 50%</div>
          </Col>
          <Col xs="12" className="">
            <Card>
              <CardBody>

                <div className="row score_card_color">
                  <div className="col-8 score_name">Level of anger</div>
                  <div className="col-4">
                    <div className={
                      result.averageScoreAnger <= 50
                        ? "score-color-scale score-color-one"
                        : "score-color-scale score-color-five"
                    }>
                    </div>
                  </div>
                </div>

                <div className="row score_card_color">
                  <div className="col-8 score_name">Level of sorrow</div>
                  <div className="col-4">
                    <div className={
                      result.averageScoreSorrow <= 50
                        ? "score-color-scale score-color-one"
                        : "score-color-scale score-color-five"
                    }>
                    </div>
                  </div>
                </div>

                <div className="row score_card_color">
                  <div className="col-8 score_name">Level of joy</div>
                  <div className="col-4">
                    <div className={
                      result.averageScoreJoy >= 50
                        ? "score-color-scale score-color-one"
                        : "score-color-scale score-color-five"
                    }>
                    </div>
                  </div>
                </div>

                <div className="row score_card_color">
                  <div className="col-8 score_name">Level of surprise</div>
                  <div className="col-4">
                    <div className={
                      result.averageScoreSurprise >= 50
                        ? "score-color-scale score-color-one"
                        : "score-color-scale score-color-five"
                    }>
                    </div>
                  </div>
                </div>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageEmotions;