/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../utils/Error";
import { ROOT_URL } from "../../constants";

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
      errors: false,
    };
  }

  toggle(id) {
    this.setState({ modal: !this.state.modal, id: id, errors: false });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
  }

  handleSubmit(event) {
    event.preventDefault();
    axios
      .post(`${ROOT_URL}/api/tasks/delete`, { id: this.state.id })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delete task</ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            Do you want to delete this task?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="danger" onClick={this.handleSubmit} type="submit">
              Delete
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Delete;
