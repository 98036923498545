import React from "react";
import {
  Row,
  Col,
  Progress,
} from "reactstrap";
import 'react-lazy-load-image-component/src/effects/blur.css';

class ImageMemorability extends React.Component {
  constructor(props) {
    super(props);
    // this.generateScore();
  }
 


  render() {
    var score = 0
    score = this.props.vco?this.props.score:this.props.score * 50 + 50;


    return (
      <Row className="memorability-score-row">
        <Col className="py-2 text-title" xs="6">
          <span>SCORE</span>
          <div className="memorability-score-col">
            <Progress
              className="w-100 white-bg"
              color={
                score < 25
                  ? "danger"
                  : score < 50
                    ? "warning"
                    : score < 75
                      ? "yellow"
                      : "success"
              }
              value={score}
            />
            <div className="w-100 mt-2 score-memo">{Math.round(score)}/100</div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ImageMemorability;
