import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import ViewTask from "./View";

class TaskRoutes extends React.Component {
  render() {
    console.log("inside task routesss");
    return (
      <Routes>
        <Route path="/tasks/:id" component={<ViewTask />} />
        <Route render={() => <Navigate to="/404" />} />
      </Routes>
    );
  }
}

export default TaskRoutes;