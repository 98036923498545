import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";

import ErrorJSON from "../utils/Error";
import Score from "./Scoring";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
class ReportTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var task = this.props.task;

    return (
      <>
        <>
          {task === false ? (
            <div className=" px-4"></div>
          ) : (
            <div>
              <Row className="px-4 predictiveFull">
                <Col className="mt-4 sticky-menu" xs="2">
                  <Row>
                    <Col className="category-link">Overview</Col>
                  </Row>
                  <hr className="solid"></hr>
                  {task.sections.map((section) => (
                    <>
                      <Row>
                        <Col className="category-link">
                          <a
                            className="normal-a"
                            href={`#${section.name.toLowerCase()}`}
                          >
                            {section.name}
                          </a>
                        </Col>
                      </Row>
                      <hr className="solid"></hr>
                    </>
                  ))}
                </Col>

                {/* Score rounds */}
                <Col className="mt-4" xs="10">
                  <Score task={task} state={this.state}></Score>

                  {task.sections.map((section) => (
                    <>
                      <Row id={section.name.toLowerCase()} className="mt-4">
                        <Col xs="12 fade-in">
                          <Row
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: "1rem",
                            }}
                          >
                            <div className="category-text-2">
                              {section.name.toUpperCase()}
                              <hr className="solid-white"></hr>
                            </div>
                      
                            <Row>
                              <Col xs="2 fade-in"></Col>
                              <Col xs="4 fade-in">
                                <div className="category-text-image">
                                  <img src={section.image}></img>
                                </div>
                              </Col>
                              <Col xs="2 fade-in">
                                {" "}
                                <div className="category-text-description">
                                  {section.description}
                                </div>
                              </Col>

                              <Col xs="2 fade-in"></Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col className="py-2 text-title " xs="4"></Col>

                        <Col className="py-2 text-title dark-background" xs="3">
                          RESULT
                        </Col>
                        <Col className="py-2 text-title dark-background" xs="3">
                          {task.competitors == false
                            ? "RECOMMENDED"
                            : "COMPETITORS"}
                        </Col>
                        <Col className="py-2 text-title" xs="2">
                          DETAILS
                        </Col>
                      </Row>
                      {section.checks.map((check) => (
                        <>
                          {check.status == "subtitle" ? (
                            <Row className="border-row">
                              <Col xs="4" className="py-3">
                                <Row>
                                  <Col xs="10">
                                    <b>{check.title}</b>
                                  </Col>
                                </Row>{" "}
                              </Col>

                              <Col xs="3" className="py-3 "></Col>
                              <Col xs="3" className="py-3"></Col>
                              <Col className="py-3 " xs="2"></Col>
                            </Row>
                          ) : (
                            <Row className="border-row">
                              <Col xs="4" className="py-3">
                                <Row>
                                  <Col xs="2">
                                    <Cube color={check.status}></Cube>
                                  </Col>
                                  <Col xs="10">{check.title}</Col>
                                </Row>{" "}
                              </Col>

                              <Col xs="3" className="py-3 dark-background">
                                {check.result.includes("img://") ? (
                                  <img src={check.result.split("img://")[1]} />
                                ) : (
                                  <>{check.result}</>
                                )}
                              </Col>
                              <Col xs="3" className="py-3 dark-background">
                                {check.details.includes("img://") ? (
                                  <img src={check.details.split("img://")[1]} />
                                ) : (
                                  <>{check.recommended}</>
                                )}
                              </Col>
                              <Col className="py-3 " xs="2">
                                {check.recommended.includes("img://") ? (
                                  <img
                                    src={check.recommended.split("img://")[1]}
                                  />
                                ) : (
                                  <>{check.details}</>
                                )}
                              </Col>
                            </Row>
                          )}{" "}
                        </>
                      ))}
                    </>
                  ))}
                </Col>
              </Row>
            </div>
          )}
        </>
      </>
    );
  }
}

const Label = (props) => {
  return props.result == true ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == false ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "Maybe" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  );
};

const Cube = (props) => {
  return props.color == "pass" ? (
    <img src={"/img/IconPassBig.png"} className="icon-score"></img>
  ) : props.color == "warning" ? (
    <img src={"/img/IconWarningBig.png"} className="icon-score"></img>
  ) : (
    <img src={"/img/IconFailBig.png"} className="icon-score"></img>
  );
};

export { ReportTab, Cube };
