import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import "react-tooltip/dist/react-tooltip.css";

class DomToxicity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ToxicResult: null,
      showModal: false,
      currentCategory: '',
      toxicCount: null,
      categoryCounts: null,
      InitialResults: null,
      InitialResultsError: ''
    };
  }

  componentDidMount() {
    this.countToxicEntries();

  }
  countToxicEntries() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl?.results?.toxic_result;
      results = initialResults?.results?.toxic_result;
    } else {
      initialResults = this.props.dataImage?.results?.toxic_prompt?.results;
      results = initialResults?.results?.toxic_result;
    }
    if (this.props.media === 'dom') {
      initialResults = initialResults
    }
    else {
      if (initialResults?.status === 'finished') {
        initialResults = initialResults.results

      }
    }
    if (initialResults?.status === 'error') {
      this.setState({ InitialResults: initialResults })
    }

    if (results) {
      this.setState({
        ToxicResult: results[0],
        InitialResults: initialResults
      });
    } else {
      if (initialResults?.status === 'error') {
        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          ToxicResult: null,
          InitialResultsError: words?.join(" ")
        });
      }
    }
  }

  setShowModal = (e, showModal, category) => {
    if (e) { e.preventDefault() }
    this.setState({ showModal: showModal, currentCategory: category });
  };

  render() {
    const { ToxicResult, showModal, InitialResults, InitialResultsError } = this.state
    const ToxicContent = ToxicResult?.Result === 'Toxic' ? ToxicResult : null;
    const ContentDisplay = ({ content }) => {
      // Specific keys to render
      const keysToRender = [
        "Insults and Swear Words",
        "Sexual Content",
        "Inappropriate Language",
        "Racist or Discriminatory",
        "Blame and Aggressive Language",
      ];

      // Function to extract and prepare content for the table
      const prepareContent = (item) => {
        let result = [];

        keysToRender.forEach((key) => {
          if (item[key] && !item[key].some(value => ["No", "None", "Non", " "].includes(value))) {
            item[key].forEach((value) => {
              // Split value by comma and add each part as a separate entry
              value.split(',').forEach((subValue) => {
                result.push({ category: key, value: subValue.trim() });
              });
            });
          }
        });

        return result;
      };

      const preparedContent = prepareContent(content);

      return (
        <div>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Sentence</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
              </tr>
            </thead>
            <tbody>
              {preparedContent.map((entry, idx) => (
                <tr key={idx}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.value}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <>


        <>
          <Row>
            <Col className="py-2" xs="4"></Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RESULT
            </Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RECOMMENDED
            </Col>
          </Row>
          <Row className="border-row">
            <Col className=" py-3" xs="4">
              <Row>
                <Col xs="2">
                  <Cube
                    color={
                      (InitialResults?.status === 'error') ? 'maybe' : (ToxicContent ? false : true)
                    }
                  ></Cube>
                </Col>
                <Col xs="10">Toxicity
                </Col>
              </Row>
            </Col>
            {ToxicContent ? (


              <Col className="py-3 dark-background" xs="4">
                <>
                  Toxic words detected
                  <ul>

                    <li>Insults and Swear Words: {(['No', 'None', 'Non'].includes(ToxicResult['Insults and Swear Words'][0]) || ToxicResult['Insults and Swear Words'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Insults and Swear Words'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Sexual Content: {(['No', 'None', 'Non'].includes(ToxicResult['Sexual Content'][0]) || ToxicResult['Sexual Content'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Sexual Content'][0])) ? 'No' : 'Yes'}
                    </li>
                    <li>Inappropriate Language:  {(['No', 'None', 'Non'].includes(ToxicResult['Inappropriate Language'][0]) || ToxicResult['Inappropriate Language'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Inappropriate Language'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Racist or Discriminatory:{(['No', 'None', 'Non'].includes(ToxicResult['Racist or Discriminatory'][0]) || ToxicResult['Racist or Discriminatory'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Racist or Discriminatory'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Blame and Aggressive Language:  {(['No', 'None', 'Non'].includes(ToxicResult['Blame and Aggressive Language'][0]) || ToxicResult['Blame and Aggressive Language'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Blame and Aggressive Language'])) ? 'No' : 'Yes'}
                    </li>
                  </ul>
                  <a href='_blank' style={{ marginLeft: '4px' }} onClick={(e) => this.setShowModal(e, true)}> More Info... </a>

                  < Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>  Toxicity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ContentDisplay content={ToxicContent} />

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => this.setShowModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </Col>




            ) : (

              <Col className="py-3 dark-background" xs="4">
                <>
                  {(ToxicResult?.Result === 'No text detected') ? 'No text detected' :
                    (((InitialResults?.status === 'error') ? (
                      InitialResultsError || 'Unknown error'
                    ) : (
                      'No toxic words detected'
                    )))
                }


                </>
              </Col>
            )
            }

            <Col className="py-3 dark-background" xs="4">
              Good to have Non Toxic & no harmful words           </Col>
          </Row>
        </>


      </>
    );
  }
}



export default DomToxicity;