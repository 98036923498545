import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageObjects extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Objects
          </Col>
          <Col xs="8">
            <Row>
              <Col xs="6" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SIZE
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                VISIBILITY{" "}
                <FontAwesomeIcon
                  className="info-icon"
                  icon="info-circle"
                  id="visibility-tooltip"
                ></FontAwesomeIcon>
                <ReactTooltip
                  anchorId="visibility-tooltip"
                  place="bottom"
                  variant="info"
                >The visibility of the object is calculated by calculating the average of
                  the visual attention score for each pixel of the object. The pixel of the object are the ones outlined by the red rectangle
                </ReactTooltip>
              </Col>
            </Row>
          </Col>
          {/* <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col> */}
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3">
            <div>
              <img
                placeholderSrc={
                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                }
                effect="blur"
                className="card-img-results"
                alt="Card image cap"
                src={`/output/${this.props.uploadID}/predictivedesign/imageobject.jpg`}
              />
              {(this.props.data.results?.recommendation?.results?.reco_pos?.trim()) &&
                <FontAwesomeIcon
                  onClick={() => this.informationModalRef.toggle('OBJECT', this.props.data.results?.recommendation?.results?.reco_pos)}
                  className="info-icon"
                  icon="info-circle"
                  id="visibile-tooltip"
                ></FontAwesomeIcon>}
              <ReactTooltip
                anchorId="visibile-tooltip"
                place="bottom"
                variant="info"
              >{this.props.data.results?.recommendation?.results?.reco_pos}
              </ReactTooltip>
            </div>
          </Col>
          <Col xs="8" className="py-3 dark-background">
            {this.props.data.results.object_size.results.map(function (object) {
              return (
                <Row>
                  <Col xs="6">{object.name}</Col>
                  <Col xs="3">{Math.round(object.sizePercent)}%</Col>
                  <Col xs="3">{Math.round(object.visibility)}</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageObjects;
