import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

class Score extends React.Component {
  constructor(props) {
    super(props);

    var options = [];
    for (var section of props.task.sections) {
      options.push({
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#EA5455",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: [section.name.toUpperCase()],
      });
    }

    this.state = {
      series: [99],
      optionsChart: options,
    };
  }

  render() {
    var task = this.props.task;
    return (
      <>
        <Row className="d-flex justify-content-around">
          {task.sections.map((section, index) => (
            <Col xs="3 fade-in" className="text-center ">
              <a className="normal-a" href={`#${section.name.toLowerCase()}`}>
                <Row>
                  <ReactApexChart
                    options={this.state.optionsChart[index]}
                    series={[Math.round(section.score)]}
                    type="radialBar"
                    height={250}
                    label={[section.name.toUpperCase()]}
                  />
                </Row>
              </a>
              {section.checks.map((check) =>
                check.status == "subtitle" ? (
                  <></>
                ) : (
                  <Label result={check.status} label={check.title}></Label>
                )
              )}
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

const Label = (props) => {
  return props.result == "pass" ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == "fail" ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "warning" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  );
};

export default Score;
