import React, { Component } from "react";
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class NavbarPublic extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div className="navbar-login">
      <Navbar expand="lg" className="navbar-absolute navbar-transparent home">
        <NavbarToggler onClick={this.toggle}>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
        </NavbarToggler>
        <Collapse
          className="nav-content"
          isOpen={this.state.isOpen}
          navbar
        >
          <Nav navbar className="text-menu">
            <div className="d-inline-flex align-right">
              {
                window.location.pathname=== "/login"
                  ? <NavMenuItem
                    location={this.props.location}
                    path="/register"
                    name="Register"
                    icon="user-plus"
                  ></NavMenuItem>
                  : <NavMenuItem
                    location={window.location}
                    path="/login"
                    name="Login"
                    icon="sign-in-alt"
                  ></NavMenuItem>
              }
              <NavMenuItem
                location="mailto:ai4c_crisp_support@accenture.com"
                path={false}
                name="Contact Us"
                mailto="mailto:ai4c_crisp_support@accenture.com"
                icon="info"
              ></NavMenuItem>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    );
  }
}

const NavMenuItem = (props) => {
  const activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <NavItem className={props.path ? `${activeRoute(props.path)} mr-5` : 'ml-5'}>
    <NavLink href={props.path ? props.path : props.mailto} className="nav-link mr-4" activeClassName="active">
      <p>
        <FontAwesomeIcon className="mr-2" icon={props.icon} />
        {props.name}
      </p>
    </NavLink>
  </NavItem>
  );
};

export default NavbarPublic;