import { SET_RESOURCE_TOKEN } from "../actions/types";

const initialState = {
  resource_token: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_TOKEN:
      return {
        ...state,
        resource_token: action.resource_token,
      };

    default:
      return state;
  }
}
