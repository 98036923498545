// Dashboard.js
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../actions/titleActions';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ProjectCard from '../project/ProjectCard';
import Create from './Create';
import ContentLoader from 'react-content-loader';
import { ROOT_URL } from '../../constants';

// Import the missing components here
import Edit from '../task/Edit';
import Share from '../task/Share';
import Delete from '../task/Delete';
import TaskCard from '../task/TaskCard';
import EditProject from '../project/Edit';
import DeleteProject from '../project/Delete';
import NewLoader from '../loader/NewLoader';

const Dashboard = (props) => {
 
  useEffect(() => {
    const { dispatch } = props;
    dispatch(setPageTitle('Dashboard', 'home'));
    localStorage.setItem('selectedMenuItem', "Dashboard");

  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>CRISP - Dashboard</title>
      </Helmet>
      <Create></Create>
      <LastPredictions access={props.access} history={props.history} token={props.resource_token.resource_token} />
      <LastProjects history={props.history} token={props.resource_token.resource_token} />
    </div>
  );
};

const LastPredictions = (props) => {
  const [data, setData] = useState([]);
  const editRef = useRef(null);
  const shareRef = useRef(null);
  const deleteRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('selectedMenuItem', "Dashboard");

    const fetchData = async () => {
      const result = await axios(`${ROOT_URL}/api/tasks/getlastsforuser`);
      setData(result.data.tasks);
    };
    fetchData();
  }, []);

  const ref = useRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="mt-4">
        <Edit access={props.access} history={props.history} ref={editRef}></Edit>
      <Share ref={shareRef}></Share>
      <Delete ref={deleteRef}></Delete>

      <h4>Your last tasks</h4>
      <div className="mt-4 position-relative">
        {data === false ? (
          <NewLoader />
        ) : data.length == 0 ? (
          <div className="text-center">
            <h5>You haven't made any tasks.</h5>
          </div>
        ) : (
          <div>
            <div className="gradient-right"></div>
            <div className="gradient-left"></div>
            <div className="container-scroll" ref={ref}>
              <div className="chevron-height">
                <div className="chevron-left" onClick={() => scroll(-70)}>
                  <i className="fas fa-chevron-left"></i>
                </div>
              </div>
              <div className="chevron-spacing">&nbsp;</div>
              {data.map((task) => (
                <TaskCard
                  key={task._id}
                  token={props.token}
                  history={props.history}
                  editRef={editRef.current}
                  shareRef={shareRef.current}
                  deleteRef={deleteRef.current}
                  task={task}
                ></TaskCard>
              ))}
              <div className="chevron-spacing">&nbsp;</div>
              <div className="chevron-height-right">
                <div className="chevron-right" onClick={() => scroll(70)}>
                  <i className="fas fa-chevron-right" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};



const LastProjects = (props) => {

  const [data, setData] = useState([]);
  const editRef = useRef(null);
  const deleteRef = useRef(null);
  const ref = useRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const currentProject = (value)=>{
}
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${ROOT_URL}/api/projects/getlastsforuser`);
      setData(result.data.projects);
    };
    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <EditProject ref={editRef} access={props.access} history={props.history}/>
      <DeleteProject ref={deleteRef} />

      <h4>Last modified projects</h4>
      <div className="mt-4 position-relative">
        {data.length === 0 ? (
          <div className="text-center">
            <h5>You don't have any projects.</h5>
          </div>
        ) : (
          <div>
            <div className="gradient-right" />
            <div className="gradient-left" />
            <div className="container-scroll" ref={ref}>
              <div className="chevron-height">
                <div className="chevron-left" onClick={() => scroll(-70)}>
                  <i className="fas fa-chevron-left"></i>
                </div>
              </div>
              <div className="chevron-spacing">&nbsp;</div>
              {data.map((project) => {
                 const modifiedProject = {
                  ...project,
                  id: project._id
              };
              return  <ProjectCard
                  key={project._id}
                  token={props.token}
                  history={props.history}
                  editRef={editRef}
                  deleteRef={deleteRef}
                  project={modifiedProject}
                  currentProject={currentProject}

                />
})}
               <div className="chevron-spacing">&nbsp;</div>
              <div className="chevron-height-right">
                <div className="chevron-right" onClick={() => scroll(70)}>
                  <i className="fas fa-chevron-right" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyLoader = (props) => (
  <ContentLoader
    width={1500}
    height={400}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="300" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="600" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="900" y="0" rx="2" ry="2" width="250" height="250" />
  </ContentLoader>
);

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(Dashboard);
