import React, { useState } from "react";
import {
  Button,
  Modal,
  Label,
  Input,
  Col,
  Row,
  Alert,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ROOT_URL } from "../../constants";


const Feedback = (props) => {
  const [modal, setModal] = useState(false);
  const [tag, setTag] = useState("");
  const [text, setText] = useState("");
  const [state, setState] = useState("idle");

  const toggle = () => {
  if(modal){
    setState('idle')
  }
    setModal(!modal);
  
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setState("idle");
    axios
      .post(`${ROOT_URL}/api/feedbacks/add`, { tag, text })
      .then((res) => {
        setState("success");
      })
      .catch((err) => {
        setState("error");
      });
  };

  return (
    <div>
      <Button onClick={toggle} className="btn-feedback">
        Feedback
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>We'd love your feedback</ModalHeader>
        <form onSubmit={handleSubmit} className="feedback-form">
          <ModalBody>
            {state === "success" ? (
              <Alert color="success">
                Your feedback has been successfully submitted. Thank you!
              </Alert>
            ) : state === "error" ? (
              <Alert color="danger">
                An error occured while submitting your feedback. Please try
                again later.
              </Alert>
            ) : (
              <span></span>
            )}
            <p className="text-center">
              We would like your feedback to improve our platform and your
              experience.
              <br></br>
              <br></br>
              Please select your feedback category:
            </p>
            <FormGroup  onChange={(event) => setTag(event.target.value)}>
              <Row>
                <Col xs={4}>
                  <Input
                    type="radio"
                    name="tag"
                    value="suggestion"
                    id="checkbox1"
                    required
                  />
                  <Label className="feedback-check-label" for="checkbox1">
                    <FontAwesomeIcon
                      className="feedback-icon"
                      icon="lightbulb"
                    ></FontAwesomeIcon>{" "}
                    <br></br>Suggestion
                  </Label>
                </Col>

                <Col xs={4}>
                  <Input
                    type="radio"
                    name="tag"
                    value="compliment"
                    id="checkbox2"
                  />
                  <Label className="feedback-check-label" for="checkbox2">
                    <FontAwesomeIcon
                      className="feedback-icon"
                      icon="smile"
                    ></FontAwesomeIcon>
                    <br></br>Compliment
                  </Label>
                </Col>

                <Col xs={4}>
                  <Input type="radio" name="tag" value="bug" id="checkbox3" />
                  <Label className="feedback-check-label" for="checkbox3">
                    <FontAwesomeIcon
                      className="feedback-icon"
                      icon="bug"
                    ></FontAwesomeIcon>
                    <br></br>Bug
                  </Label>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Label for="exampleText">Please leave your feedback below:</Label>
              <Input
              required
                type="textarea"
                onChange={(event) => setText(event.target.value)}
                row={5}
                name="text"
                id="exampleText"
              />
            </FormGroup>
          </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Send
              </Button>{" "}
            </ModalFooter>

        </form>
      </Modal>
    </div>
  );
};
export default Feedback;
