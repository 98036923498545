import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class ImageFormats extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  generateScore() {
    let scorefinal =
      // ((this.props.data.results.correct_colorspace.results.score +
      //   this.props.data.results.correct_file_extension.results.score +
      //   this.props.data.results.correct_image_resolution.results.score +
      //   this.props.data.results.correct_aspect_ratio.results.score) /
      //   4) *
      // 100;

      ((this.props.data.results.correct_colorspace.results.score +
        this.props.data.results.correct_image_resolution.results.score +
        (this.props.data.results.correct_aspect_ratio?.results?.score | 0)) /
        3) *
      100;

    this.props.generateScore(scorefinal);
  }

  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });
    return services;
  }

  render() {
    return (
      <>
        <Row id="format" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">FORMAT</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_aspect_ratio?.results?.correctRatio
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Ratio
                    {(this.props.data.results.correct_aspect_ratio?.results?.reco_pos_ratio?.trim() ||
                      this.props.data.results.correct_aspect_ratio?.results?.reco_neg_ratio?.trim()) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="image-ratio-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="image-ratio-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_aspect_ratio?.results?.correctRatio ?
                      this.props.data.results.correct_aspect_ratio?.results?.reco_pos_ratio :
                      this.props.data.results.correct_aspect_ratio?.results?.reco_neg_ratio}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.results.correct_aspect_ratio?.results?.closest_ratio
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {this.props.data.results.correct_aspect_ratio?.results ? this.convertAspectRatio(
                  this.props.data.results.correct_aspect_ratio?.results?.expected_ratio
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                }) : ''}
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_image_resolution.results
                          .correctResolution
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Image size
                    {(this.props.data.results.correct_image_resolution.results.reco_pos_min_height?.trim() ||
                      this.props.data.results.correct_image_resolution.results.reco_neg_min_height?.trim()) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="image-size-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="image-size-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_image_resolution.results.correctResolution ?
                      this.props.data.results.correct_image_resolution.results.reco_pos_min_height +
                      this.props.data.results.correct_image_resolution.results.reco_pos_max_height +
                      this.props.data.results.correct_image_resolution.results.reco_pos_min_width +
                      this.props.data.results.correct_image_resolution.results.reco_pos_max_width :
                      this.props.data.results.correct_image_resolution.results.reco_neg_min_height +
                      this.props.data.results.correct_image_resolution.results.reco_neg_max_height +
                      this.props.data.results.correct_image_resolution.results.reco_neg_min_width +
                      this.props.data.results.correct_image_resolution.results.reco_neg_max_width}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {this.props.data.results.correct_image_resolution.results.width}
                x
                {
                  this.props.data.results.correct_image_resolution.results
                    .height
                }
                px
              </Col>
              <Col xs="4" className="py-3 dark-background">
                <Row>
                  <Col>
                    Min Width:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .min_width
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Min Height:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .min_height
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Max Width:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .max_width
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Max Height:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .max_height
                    }
                    px
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_colorspace.results
                          .correctColorspace
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Colorspace
                    {(this.props.data.results.correct_colorspace.results.reco_pos_colorspace?.trim() ||
                      this.props.data.results.correct_colorspace.results.reco_neg_colorspace?.trim()) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="colorspace-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="colorspace-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_colorspace.results.correctColorspace ?
                      this.props.data.results.correct_colorspace.results.reco_pos_colorspace :
                      this.props.data.results.correct_colorspace.results.reco_neg_colorspace}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {this.props.data.results.correct_colorspace.results.colorspace}
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {JSON.parse(
                  this.props.data.results.correct_colorspace.results.expected_colorspace.replace(
                    /'/g,
                    '"'
                  )
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageFormats;

