import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageAccessibility extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    if (this.props.data.results) {
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_aa
      ) {
        score = score + 1;
      }
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_aaa
      ) {
        score = score + 1;
      }
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_large
      ) {
        score = score + 1;
      }
    }

    let scorefinal = (score / 3) * 100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        {this.props.data.results ? (
          <>
            <Row className="mt-2">
              <Col className="py-2 text-title" xs="4">Image of Text</Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_aaa
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">AAA Accessibility level
                    {(this.props.data.results.correct_average_score.results.reco_pos_min_average_aaa?.trim() ||
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_aaa) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="aaa-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="aaa-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_average_score.results.correct_accessibility.correct_accessibility_aaa ?
                      this.props.data.results.correct_average_score.results.reco_pos_min_average_aaa :
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_aaa}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Text passing AAA accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreAAA
                )}{" "}
                %
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_aaa
                }
                %
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_aa
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">AA Accessibility level
                    {(this.props.data.results.correct_average_score.results.reco_pos_min_average_aa?.trim() ||
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_aa) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="aa-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="aa-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_average_score.results.correct_accessibility.correct_accessibility_aa ?
                      this.props.data.results.correct_average_score.results.reco_pos_min_average_aa :
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_aa}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Text passing AA accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreAA
                )}{" "}
                %
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_aa
                }
                %
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_large
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Large Accessibility level
                    {(this.props.data.results.correct_average_score.results.reco_pos_min_average_large?.trim() ||
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_large) &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="large-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="large-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.correct_average_score.results.correct_accessibility.correct_accessibility_large ?
                      this.props.data.results.correct_average_score.results.reco_pos_min_average_large :
                      this.props.data.results.correct_average_score.results.reco_neg_min_average_large}
                    </ReactTooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Text passing Large accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreLarge
                )}{" "}
                %
              </Col>
              <Col xs="4" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_large
                }
                %
              </Col>
            </Row>{" "}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ImageAccessibility;
