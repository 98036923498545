import React from 'react';
import './loader.css';

const NewLoader = () => {
  // Component logic and JSX go here

  return (
    <div >
  <div class="loading-spinner">
    <div class="">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h1 class="loading-text">Loading...</h1>
    </div>
  </div>
</div>
  );
};

export default NewLoader;
