import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CheckAzure extends React.Component {
  render() {
    return (
      <div className="azure-bg">
        <FontAwesomeIcon
          icon="spinner"
          className="spinner-rotate spinner-loggin"
        ></FontAwesomeIcon>
      </div>
    );
  }
}

export default CheckAzure;
