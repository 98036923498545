import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { Modal, Button } from 'react-bootstrap';
import { Cube } from "../tabs/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class DomGrammar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ToxicResult: null,
      showModal: false,
      currentCategory: '',
      toxicCount: null,
      categoryCounts: null,
      InitialResults: null,
      InitialResultsError: ''
    };
  }

  componentDidMount() {
    this.countToxicEntries();

  }
  countToxicEntries() {
    let results;
    let initialResults;

    if (this.props.media === 'dom') {
      initialResults = this.props.dataUrl?.results?.grammer_result;
      results = initialResults?.results?.grammer_result;
    } else {
      initialResults = this.props.dataImage?.results?.grammar_prompt?.results;
      results = initialResults?.results?.grammer_result;
    }

   
    if (this.props.media === 'dom') {
      initialResults = initialResults
    }
    else {
      if (initialResults?.status === 'finished') {
        initialResults = initialResults.results

      }
    }
    if (initialResults?.status === 'error') {
      this.setState({ InitialResults: initialResults })
    }

    if (results) {
      this.setState({
        ToxicResult: results[0],
        InitialResults: initialResults
      });
    } else {
      if (initialResults?.status === 'error') {
        var words = initialResults?.reason_for_failure.toLowerCase().split(' ')
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].slice(1)
        }
        this.setState({
          ToxicResult: null,
          InitialResultsError: words?.join(" ")
        });
      }
    }
  }

  setShowModal = (e, showModal, category) => {
    if (e) { e.preventDefault() }
    this.setState({ showModal: showModal, currentCategory: category });
  };
  prepareContent = (item) => {
    let result = [];
    const keysToRender = [
      "Articles and Preposition",
      "Comparative and Superlative Forms",
      "Conjuctions and Verb Forms",
      "Possessive Forms",
      "Quotation and Punctuation",
      "Spelling Mistakes"
    ];
    keysToRender.forEach((key) => {
      if (item[key] && !item[key].some(value => ["No", "None", "Non", " "].includes(value))) {
        item[key].forEach((value) => {
          // Split value by comma and add each part as a separate entry
          value.split(',').forEach((subValue) => {
            result.push({ category: key, value: subValue.trim() });
          });
        });
      }
    });

    return result;
  };
  render() {
    const { ToxicResult, showModal, InitialResults, InitialResultsError } = this.state
    const ToxicContent = ToxicResult?.Result === 'Not Correct' ? ToxicResult : null;
    const preparedContent = ToxicContent ? this.prepareContent(ToxicContent) : [];

    const ContentDisplay = ({ content }) => {
      // Specific keys to render 
  

      // Function to extract and prepare content for the table
 


      return (
        <div>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                <th style={{ border: '1px solid black', padding: '8px' }}>Sentence</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
              </tr>
            </thead>
            <tbody>
              {preparedContent.map((entry, idx) => (
                <tr key={idx}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.value}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{entry.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <>


        <>
          <Row>
            <Col className="py-2" xs="4"></Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RESULT
            </Col>
            <Col className="py-2 text-title dark-background" xs="4">
              RECOMMENDED
            </Col>
          </Row>
          <Row className="border-row">
            <Col className=" py-3" xs="4">
              <Row>
                <Col xs="2">
                  <Cube
                    color={
                      (InitialResults?.status === 'error') ? 'maybe' : (ToxicContent ? false : true)
                    }
                  ></Cube>
                </Col>
                <Col xs="10">Grammar
                  {/* {this.props.data.results.CTA_presence?.results?.reco_neg?.trim() &&
                      <FontAwesomeIcon
                        className="info-icon"
                        icon="info-circle"
                        id="cta-presence-tooltip"
                      ></FontAwesomeIcon>}
                    <ReactTooltip
                      anchorId="cta-presence-tooltip"
                      place="bottom"
                      variant="info"
                    >{this.props.data.results.CTA_presence?.results?.reco_neg}
                    </ReactTooltip> */}
                </Col>
              </Row>
            </Col>
            {ToxicContent ? (
              <Col className="py-3 dark-background" xs="4">
                <>
                  Grammar errors detected
                 
                  <ul>
                    <li>Articles and Preposition: {( ['No', 'None', 'Non'].includes(ToxicResult['Articles and Preposition'][0]) || ToxicResult['Articles and Preposition'].length < 1 ||['No', 'None', 'Non'].includes(ToxicResult['Articles and Preposition'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Comparative and Superlative Forms: {(['No', 'None', 'Non'].includes(ToxicResult['Comparative and Superlative Forms'][0]) || ToxicResult['Comparative and Superlative Forms'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Comparative and Superlative Forms'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Conjuctions and Verb Forms:  {(['No', 'None', 'Non'].includes(ToxicResult['Conjuctions and Verb Forms'][0]) || ToxicResult['Conjuctions and Verb Forms'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Conjuctions and Verb Forms'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Possessive Forms:{(['No', 'None', 'Non'].includes(ToxicResult['Possessive Forms'][0]) || ToxicResult['Possessive Forms'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Possessive Forms'])) ? 'No' : 'Yes'}
                    </li>
                    <li>Quotation and Punctuation:{(['No', 'None', 'Non'].includes(ToxicResult['Quotation and Punctuation'][0]) || ToxicResult['Quotation and Punctuation'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Quotation and Punctuation'][0])) ? 'No' : 'Yes'}
                    </li>
                    <li>Spelling Mistakes:  {(['No', 'None', 'Non'].includes(ToxicResult['Spelling Mistakes'][0]) || ToxicResult['Spelling Mistakes'].length < 1 || ['No', 'None', 'Non'].includes(ToxicResult['Spelling Mistakes']))  ? 'No' : 'Yes'}
                    </li>
                  </ul>
                  {preparedContent.length<1?null: <a href='_blank' style={{ marginLeft: '4px' }} onClick={(e) => this.setShowModal(e, true)}> More Info... </a>}

                  < Modal show={showModal} onHide={() => this.setShowModal(false)} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>  Grammar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ContentDisplay content={preparedContent} />

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => this.setShowModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </Col>

            ) : (

              <Col className="py-3 dark-background" xs="4">
                <>


                {(ToxicResult?.Result === 'No text detected') ? 'No text detected' :
                  ((InitialResults?.status === 'error') ? (
                    InitialResultsError || 'Unknown error'
                  ) : (
                    'No grammar errors detected'
                  ))}





                </>
              </Col>
            )
            }

            <Col className="py-3 dark-background" xs="4">
              Good to have no grammar errors   </Col>
          </Row>
        </>
      </>
    );
  }
}



export default DomGrammar;