import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ImageEmotions from "./ImageEmotions";

class ImageSafety extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = Math.round(
      this.props.data.results.correct_safety.results.score * 100
    );
    this.props.generateScore(score);
  }

  render() {
    if (this.props?.data?.results?.correct_safety?.results) {
      const result = this.props.data.results.correct_safety.results;

      return (
        <>
          <Row className="mt-3 no-top-border">
            <Col className="py-1 text-title" xs="12">
              <div className="d-grid">
                <div className="mb-2">Images analysis</div>
                <img
                  placeholderSrc={
                    "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                  }
                  effect="blur"
                  className=" max-height justify-content-center text-center img-height"
                  src={`/input/image/${this.props.uploadID}/image.jpeg`}
                />
                <div className="text-title mt-2">Number of human faces detected: {this.props.personFaces}</div>
              </div>
            </Col>
            <div className="container-scroll">
              <Col className="score-color-palet" xs="6">
                <span>Safety Score:&nbsp;</span>
              </Col>
              <Col className="score-color-palet" xs="6">
                {
                  this.props.personFaces > 0
                    ? <span>Emotions Score:&nbsp;</span>
                    : ""
                }
              </Col>
            </div>
            <div className="addingScroll">
              <Col xs="6" className="col-sentiment-card-score">
                <Col className="score-color-palet">
                  <span className="score-color score-color-one">Very Unlikely</span>
                  <span className="score-color score-color-two">Unlikely</span>
                  <span className="score-color score-color-three">Possible</span>
                  <span className="score-color score-color-four">Likely</span>
                  <span className="score-color score-color-five">Very Likely</span>
                </Col>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <div className="row score_card_color">
                        <div className="col-8 score_name">
                          Medical
                          <FontAwesomeIcon
                            onClick={() => this.infoModalRef.toggle("Medical")}
                            className="info-icon"
                            icon="info-circle"
                            id="medical-tooltip"
                            ></FontAwesomeIcon>
                            <ReactTooltip
                              anchorId="medical-tooltip"
                              place="bottom"
                              variant="info"
                            >Likelihood that this is a medical image
                            </ReactTooltip>
                        </div>
                        <div className="col-4">
                          <div className={
                            result.safety_number_medical === 0
                              ? "score-color-scale score-color-one"
                              : result.safety_number_medical === 20
                                ? "score-color-scale score-color-two"
                                : result.safety_number_medical === 50
                                  ? "score-color-scale score-color-three"
                                  : result.safety_number_medical === 70
                                    ? "score-color-scale score-color-four"
                                    : result.safety_number_medical === 90
                                      ? "score-color-scale score-color-five"
                                      : "None"
                          }>
                          </div>
                        </div>
                      </div>
                      <div className="row score_card_color">
                        <div className="col-8 score_name">
                          Spoofed
                          <FontAwesomeIcon
                            onClick={() => this.infoModalRef.toggle("Spoofed")}
                            className="info-icon"
                            icon="info-circle"
                            id="spoofed-tooltip"
                            ></FontAwesomeIcon>
                            <ReactTooltip
                              anchorId="spoofed-tooltip"
                              place="bottom"
                              variant="info"
                            >The likelihood that a modification was made to the image's canonical version to make it appear funny or offensive
                            </ReactTooltip>
                        </div>
                        <div className="col-4">
                          <div className={
                            result.safety_number_spoofed === 0
                              ? "score-color-scale score-color-one"
                              : result.safety_number_spoofed === 20
                                ? "score-color-scale score-color-two"
                                : result.safety_number_spoofed === 50
                                  ? "score-color-scale score-color-three"
                                  : result.safety_number_spoofed === 70
                                    ? "score-color-scale score-color-four"
                                    : result.safety_number_spoofed === 90
                                      ? "score-color-scale score-color-five"
                                      : "None"
                          }>
                          </div>
                        </div>
                      </div>
                      <div className="row score_card_color">
                        <div className="col-8 score_name">
                          Adult
                          <FontAwesomeIcon
                            onClick={() => this.infoModalRef.toggle("Adult")}
                            className="info-icon"
                            icon="info-circle"
                            id="adult-tooltip"
                            ></FontAwesomeIcon>
                            <ReactTooltip
                              anchorId="adult-tooltip"
                              place="bottom"
                              variant="info"
                            >Represents the adult content likelihood for the image. Adult content may contain elements such as nudity, pornographic images or cartoons, or sexual activities
                            </ReactTooltip>
                        </div>
                        <div className="col-4">
                          <div className={
                            result.safety_number_adult === 0
                              ? "score-color-scale score-color-one"
                              : result.safety_number_adult === 20
                                ? "score-color-scale score-color-two"
                                : result.safety_number_adult === 50
                                  ? "score-color-scale score-color-three"
                                  : result.safety_number_adult === 70
                                    ? "score-color-scale score-color-four"
                                    : result.safety_number_adult === 90
                                      ? "score-color-scale score-color-five"
                                      : "None"
                          }>
                          </div>
                        </div>
                      </div>
                      <div className="row score_card_color">
                        <div className="col-8 score_name">
                          Racy
                          <FontAwesomeIcon
                            onClick={() => this.infoModalRef.toggle("Racy")}
                            className="info-icon"
                            icon="info-circle"
                            id="racy-tooltip"
                            ></FontAwesomeIcon>
                            <ReactTooltip
                              anchorId="racy-tooltip"
                              place="bottom"
                              variant="info"
                            >Likelihood that the request image contains racy content. Racy content may include (but is not limited to) skimpy or sheer clothing, strategically covered nudity, lewd or provocative poses, or close-ups of sensitive body areas
                            </ReactTooltip>
                        </div>
                        <div className="col-4">
                          <div className={
                            result.safety_number_racy === 0
                              ? "score-color-scale score-color-one"
                              : result.safety_number_racy === 20
                                ? "score-color-scale score-color-two"
                                : result.safety_number_racy === 50
                                  ? "score-color-scale score-color-three"
                                  : result.safety_number_racy === 70
                                    ? "score-color-scale score-color-four"
                                    : result.safety_number_racy === 90
                                      ? "score-color-scale score-color-five"
                                      : "None"
                          }>
                          </div>
                        </div>
                      </div>
                      <div className="row score_card_color">
                        <div className="col-8 score_name">
                          Violence
                          <FontAwesomeIcon
                            onClick={() => this.infoModalRef.toggle("Violence")}
                            className="info-icon"
                            icon="info-circle"
                            id="violence-tooltip"
                            ></FontAwesomeIcon>
                            <ReactTooltip
                              anchorId="violence-tooltip"
                              place="bottom"
                              variant="info"
                            >Likelihood that this image contains violent content
                            </ReactTooltip>
                        </div>
                        <div className="col-4">
                          <div className={
                            result.safety_number_violence === 0
                              ? "score-color-scale score-color-one"
                              : result.safety_number_violence === 20
                                ? "score-color-scale score-color-two"
                                : result.safety_number_violence === 50
                                  ? "score-color-scale score-color-three"
                                  : result.safety_number_violence === 70
                                    ? "score-color-scale score-color-four"
                                    : result.safety_number_violence === 90
                                      ? "score-color-scale score-color-five"
                                      : "None"
                          }>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              {
                this.props.personFaces > 0
                  ? <Col xs="6" className="col-emotion-card">
                    <ImageEmotions
                      id={this.props.id}
                      data={this.props.dataEmotions}
                      token={this.props.token}
                      generateScore={this.props.generateScoreEmotions}
                    ></ImageEmotions>
                  </Col>
                  : ""
              }
            </div>
          </Row>
        </>
      );
    }
    else {
      return ""
    }
  }
}

export default ImageSafety;