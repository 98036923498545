var modules = {
    image: [
        {name: "ImageSafety", value: "imagesafety"},
        {name: "ImageEmotion", value: "imageemotion"},
        {name: "ImageColor", value: "imagecolor"},
        {name: "ImageAccessibility", value: "imageaccessibility"},
        {name: "ImageColorblind", value: "imagecolorblind"},
        {name: "ImageMetadata", value: "imagemetadata"},
        {name: "Vco", value: "vco"},
        {name: "ImageOcr", value: "imageocr"},
        {name: "ImageLabel", value: "imagelabel"},
        {name: "ImageLogo", value: "imagelogo"},
        {name: "ImageMemorability", value: "imagememorability"}
    ],
    video: [
        {name: "VideoLabel", value: "videolabel"},
        {name: "VideoTranscription", value: "videotranscription"},
        {name: "VideoObject", value: "videoobject"},
        {name: "VideoMetadata", value: "videometadata"},
        {name: "VideoLogo", value: "videologo"},
        {name: "Vco", value: "vco"},
        {name: "VideoSafety", value: "videosafety"},
        {name: "VideoOcr", value: "videoocr"},
        {name: "VideoShot", value: "videoshot"},
        {name: "VideoFace", value: "videoface"},
        {name: "VideoPeople", value: "videopeople"}

    ]
}

export default modules