import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
import ImageSafety from "./ImageSafety";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

var safetys = [
  { name: "medical", pretty: "Medical" },
  { name: "spoofed", pretty: "Spoofed" },
  { name: "adult", pretty: "Adult" },
  { name: "racy", pretty: "Racy" },
  { name: "violence", pretty: "Violence" },
];
class DomImage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const ref = React.createRef(null);
    const scroll = (scrollOffset) => {
      ref.current.scrollLeft += scrollOffset;
    };

    return (
      <>
        {this.props.data?.results ? (
          <>
            {this.props.data.results.img_data.results.length == 0 ? (
              <>
                <Row className=" mt-4 no-top-border">
                  <Col className="py-2 text-title" xs="4">
                    Images analysis
                  </Col>
                  <Col className="py-2 text-title dark-background" xs="4">
                    RESULT
                  </Col>
                  <Col className="py-2 text-title dark-background" xs="4">
                    RECOMMENDED
                  </Col>
                </Row>
                <Row className="border-row">
                  <Col xs="4" className="py-3">
                    <Row>
                      <Col xs="2">
                        <Cube color={false}></Cube>
                      </Col>
                      <Col xs="10">Images
                        {(this.props.data.results?.reco?.results?.reco_neg?.trim()) &&
                          <FontAwesomeIcon
                            className="info-icon"
                            icon="info-circle"
                            id="cta-img-tooltip"
                          ></FontAwesomeIcon>}
                        <ReactTooltip
                          anchorId="cta-img-tooltip"
                          place="bottom"
                          variant="info"
                        >{this.props.data.results?.reco?.results?.reco_neg}
                        </ReactTooltip>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="4" className="py-3 dark-background">
                    No images detected
                  </Col>
                  <Col xs="4" className="py-3 dark-background"></Col>
                </Row>
              </>
            ) : (<>
              <Row className="border-row mt-4 no-top-border">
                <Col className="py-2 text-title" xs="12">
                  Images analysis
                </Col>
                <Col className="score-color-palet">
                  <span>Safety Score:&nbsp;</span>
                  <span className="dom-score-color score-color-one">Very Unlikely</span>
                  <span className="dom-score-color score-color-two">Unlikely</span>
                  <span className="dom-score-color score-color-three">Possible</span>
                  <span className="dom-score-color score-color-four">Likely</span>
                  <span className="dom-score-color score-color-five">Very Likely</span>
                </Col>
              </Row>
              <Row>
                <div className="container-scroll" ref={ref}>
                  <div class='image-analysis-scroll'>
                    <div className="chevron-height">
                      <div className="chevron-left" onClick={() => scroll(-50)}><i className="fas fa-chevron-left"></i></div>
                    </div>
                    <Col xs="1">
                    </Col>
                  </div>


                  {

                    this.props.data.results.img_data.results.map(
                      (img, index) => {
                        return (
                          <Col xs="3" className="col-sentiment-card">
                            <Card>
                              <img
                                placeholderSrc={
                                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                                }
                                effect="blur"
                                src={`/output/${this.props.uploadId}/${index}.jpg`}
                              />

                              <CardBody className="sentiment-card">
                                Positive sentiment
                                <FontAwesomeIcon
                                  className="info-icon"
                                  icon="info-circle"
                                  id={`dom-image-tooltip-${index}`}
                                ></FontAwesomeIcon>
                                <ReactTooltip
                                  anchorId={`dom-image-tooltip-${index}`}
                                  place="bottom"
                                  variant="info"
                                >Image Sentiment Analysis is a neural network-based image classification that is tailored to feelings expressed in images from various domains. It notes the feelings giving a degree of positivity and a degree of negativity from a given image. <br></br><br></br>
                                  The sentiment analysis was formed on 600 images and validated on 200 images reaching high precision. The dataset consists of images that were presented to at least 10 different people to determine if the image expresses positive or negative sentiment.
                                </ReactTooltip>
                                <Progress
                                  className="w-100 position-relative progress-light"
                                  value={((img.sentiment.positive === null) || (img.sentiment.positive === undefined)) ? JSON.parse(img.sentiment).body.positive : img.sentiment.positive}
                                  color={
                                    ((img.sentiment.positive === null) || (img.sentiment.positive === undefined)) ? JSON.parse(img.sentiment).body.positive : img.sentiment.positive > 30
                                      ? "success !important"
                                      : "danger !important"
                                  }
                                >
                                  <span className="progress-text">
                                    {" "}
                                    {Math.round(((img.sentiment.positive === null) || (img.sentiment.positive === undefined)) ? JSON.parse(img.sentiment).body.positive : img.sentiment.positive)}%
                                  </span>
                                </Progress>
                                <hr></hr>
                                {img.faces.length > 0 ? (
                                  <span>
                                    {img.faces.length} human face
                                    {img.faces.length > 1 ? "s" : ""} detected.
                                  </span>
                                ) : (
                                  <span>No human faces detected.</span>
                                )}
                                <hr></hr>
                                Safety scores:
                                <br></br>
                                {safetys.map((safety) => {
                                  if (
                                    this.props.data.results.safety_scores.results
                                  ) {
                                    var res = this.props.data.results
                                      .safety_scores.results[index];
                                    return (
                                      <div className="row score_card_color">
                                        <div className="col-8 score_name">{safety.pretty}</div>
                                        <div className={
                                          res["safety_number_" + safety.name] === 0
                                            ? "col-2 score-color-scale score-color-one"
                                            : res["safety_number_" + safety.name] === 20
                                              ? "col-2 score-color-scale score-color-two"
                                              : res["safety_number_" + safety.name] === 50
                                                ? "col-2 score-color-scale score-color-three"
                                                : res["safety_number_" + safety.name] === 70
                                                  ? "col-2 score-color-scale score-color-four"
                                                  : res["safety_number_" + safety.name] === 90
                                                    ? "col-2 score-color-scale score-color-five"
                                                    : "None"
                                        }>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })}
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      }
                    )}
                  <div className="image-analysis-scroll">
                    <Col xs="1">
                    </Col>
                    <div className="chevron-height-right">
                      <div className="chevron-right" onClick={() => scroll(50)}><i className="fas fa-chevron-right"></i></div>
                    </div>
                  </div>
                </div>
              </Row>
            </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default DomImage;