import React from "react";
import {
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cube } from "../tabs/report";

class VideoQuality extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
    this.convertAspectRatio = this.convertAspectRatio.bind(this);
  }

  generateScore() {
       let scorefinal =
      ((this.props.data.correct_filesize?1:0) +
        (this.props.data.correct_resolution?1:0) +
        (this.props.data.Brightness.OverallCategory==='Bright'?1:0) +
       ( this.props.data.Contrast.OverallCategory==='High contrast'?1:0) +
      (this.props.data.Sharpness.OverallCategory==='Very sharp'?1:0) +
      (this.props.data.Noise.OverallCategory==='Low Noise'?1:0)
   ) *  100/6;

    this.props.generateScore(scorefinal);
  }

  convertAspectRatio(expected_ratio) {
    var services = expected_ratio;
    services = services.split(",");
    services[0] = services[0].substring(1);
    services[services.length - 1] = services[services.length - 1].substring(
      0,
      services[services.length - 1].length - 1
    );
    services.forEach((x, i) => {
      services[i] = services[i].includes('"') ? services[i].replaceAll('"', "").trim()
        : services[i].replaceAll("'", "").trim();
    });

    console.log("services", services);
    return services;
  }

  render() {
    return (
      <>
        <Row id="quality" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">QUALITY</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="4">
                RECOMMENDED
              </Col>
            </Row>
           {/* File Size  */}
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correct_filesize
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">File Size
                
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.FileSize
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              File size should be within 50 mb to 500 mb
               {/* {this.props.data.recommended_filesize} */}
              </Col>
            </Row>

                 {/* Resolution */}
                 <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.correct_resolution
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Resolution
                  
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.Resolution
                }
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video resolution should be 480p(SD), 720p(HD), 1080p(FHD), 1440p(2K) & 2160p(4K)
               {/* {this.props.data.recommended_resolution} */}
              </Col>
            </Row>
            
               {/* Brightness */}
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        (this.props.data.Brightness.OverallCategory==='Bright'?true:false )
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Brightness
                  
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.Brightness.OverallCategory
                }

{ this.props.data.Brightness &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id={`image-ratio-tooltip-${ this.props.data.Brightness.OverallCategory}`}
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId={`image-ratio-tooltip-${this.props.data.Brightness.OverallCategory}`}
                        place="bottom"
                        variant="info"
                      > {Object.entries(this.props.data.Brightness.Percentage).map(([key, value]) => (
                        <div key={key}>
                          {key}: {Math.round(value)}
                        </div>
                      ))}
                      </ReactTooltip>
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video brightness should be Bright
              </Col>
            </Row>

               {/* Contrast */}
               <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        (this.props.data.Contrast.OverallCategory==='High contrast'?true:false)
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Contrast
                
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.Contrast.OverallCategory
                }
                { this.props.data.Contrast &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id={`image-ratio-tooltip-${ this.props.data.Contrast.OverallCategory}`}
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId={`image-ratio-tooltip-${this.props.data.Contrast.OverallCategory}`}
                        place="bottom"
                        variant="info"
                      > {Object.entries(this.props.data.Contrast.Percentage).map(([key, value]) => (
                        <div key={key}>
                          {key}: {Math.round(value)}
                        </div>
                      ))}
                      </ReactTooltip>
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video contrast should be High contrast
              </Col>
            </Row>
                {/* Sharpness */}
                <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.Sharpness.OverallCategory==='Very sharp'?true:false
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Sharpness
             
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.Sharpness.OverallCategory
                }
                 { this.props.data.Sharpness &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id={`image-ratio-tooltip-${ this.props.data.Sharpness.OverallCategory}`}
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId={`image-ratio-tooltip-${this.props.data.Sharpness.OverallCategory}`}
                        place="bottom"
                        variant="info"
                      > {Object.entries(this.props.data.Sharpness.Percentage).map(([key, value]) => (
                        <div key={key}>
                          {key}: {Math.round(value)}
                        </div>
                      ))}
                      </ReactTooltip>
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video sharpness should be Very sharp
              </Col>
            </Row>

               {/* Noise */}
               <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.Noise.OverallCategory==='Low Noise'?true:false
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">RMS Noise
                 
                  </Col>
                </Row>
              </Col>
              <Col xs="4" className="py-3 dark-background">
                {
                  this.props.data.Noise.OverallCategory
                }
                 { this.props.data.Noise &&
                        <FontAwesomeIcon
                          className="info-icon"
                          icon="info-circle"
                          id={`image-ratio-tooltip-${ this.props.data.Noise.OverallCategory}`}
                        ></FontAwesomeIcon>}
                      <ReactTooltip
                        anchorId={`image-ratio-tooltip-${this.props.data.Noise.OverallCategory}`}
                        place="bottom"
                        variant="info"
                      > {Object.entries(this.props.data.Noise.Percentage).map(([key, value]) => (
                        <div key={key}>
                          {key}: {Math.round(value)}
                        </div>
                      ))}
                      </ReactTooltip>
              </Col>
              <Col xs="4" className="py-3 dark-background">
              Video noise should be Low Noise
              </Col>
            </Row>

          </Col>
        </Row>
      </>
    );
  }
}

export default VideoQuality;

