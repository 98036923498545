import React from "react";


import PublicSidebar from "./PublicSidebar";
import PublicHeader from "./PublicHeader";

import {
 // BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
 // useLocation
} from "react-router-dom";

import ViewStaticReport from "./View"

const StaticRoutes = (props) => {
 

  return (
    <div>
  
       
      <div className="wrapper">
        <PublicSidebar location={props.location}></PublicSidebar>

        <div className="main-panel">
          <PublicHeader {...props} />
     
          <Routes>
            <Route path="/staticreports/:id" element={<ViewStaticReport/>} />
            <Route render={() => <Navigate to="/404" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default (StaticRoutes);
