import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import Create from "../project/Create";
import Edit from "../project/Edit";
import Delete from "../project/Delete";
import axios from "axios";
import { Row, Button } from "reactstrap";
import { useDispatch, connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import ProjetCard from "../project/ProjectCard";
import { ROOT_URL } from "../../constants";
import ContentLoader from "react-content-loader";
import NewLoader from "../loader/NewLoader";
const All = ({ resource_token, history }) => {
  const [state, setState] = useState({
    projects: false,
    offset: 1,
    getTaskStatus: true,
    projectsCount: null,
  });

  const dispatch = useDispatch();
  const createRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const location = useLocation();
  const [projectId,setProjectId]= useState()

  useEffect(() => {
    const team = location.pathname.split(/[/]+/).pop();

    const fetchData = async () => {
      window.addEventListener("scroll", handleScroll);
  
      try {
        const res = await axios.get(`${ROOT_URL}/api/projects/getperteam`, {
          params: {
            id: team,
          },
        });

        dispatch(setPageTitle(res.data.team.name, "users"));
        setState((prevState) => ({
          ...prevState,
          projects: res.data.projects,
          team_name: res.data.team.name,
          projectsCount: res.data.projectsCount
        }));
      } catch (err) {
        window.location.replace("/home");
      }
    };

    fetchData();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname, dispatch]);

  const handleScroll = useCallback(() => {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= 0.9*docHeight) {
      if (state.projectsCount > (state.projects && state.projects.length) && state.getTaskStatus === true) {
        setState((prevState) => ({
          ...prevState,
          offset: prevState.offset + 1,
          getTaskStatus: false,
        }));
        getMore();
      }
    }
  }, [state]);

  const getMore = useCallback(async () => {
    const team = location.pathname.split(/[/]+/).pop();

    if(state.projects !== false) {
      try {
        const res = await axios.get(`${ROOT_URL}/api/projects/getperteam`, {
          params: {
            id: team,
            offset: state.offset,
          },
        });

        setState((prevState) => ({
          ...prevState,
          projects: [...prevState.projects, ...res.data.projects],
          getTaskStatus : true,
        }));
      } catch (err) {}
    }
  }, [state, location.pathname]);
  const currentProject = (value)=>{
    setProjectId(value)
}
  return (
    <div className="content">
      <Helmet>
        <title>{`CRISP - ${state.team_name}`}</title>
      </Helmet>
      <Create
        ref={createRef}
        team={location.pathname.split(/[/]+/).pop()}
      ></Create>
      <Edit
        ref={editRef}
        projectId={projectId}
      ></Edit>
      <Delete
        ref={deleteRef}
      ></Delete>

      <div className="w-100 text-right">
       {(state.team_name!='Public')?(<Button onClick={() => createRef.current.toggle()} color="primary">
          Create project
        </Button>):''} 
      </div>
      {state.projects === false ? (
        <>
       <NewLoader />
        {/* <MyLoader /> */}
        </>
      ) : (
      state.projects.length === 0 ? (
        <h3 className="text-center">There is no projects here.</h3>
      ) : (
        <div>
          <Row>
            {state.projects.map((project) => {
              return (
                <ProjetCard history={history} project={project} editRef={editRef} deleteRef={deleteRef} token={resource_token.resource_token} currentProject={currentProject}></ProjetCard>
              );
            })}
          </Row>
        </div>
      )
      )}
    </div>
  );
}

const MyLoader = (props) => (
  <ContentLoader
    width={1500}
    height={800}
    viewBox="0 0 1200 575"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="350" height="350" />
    <rect x="400" y="0" rx="2" ry="2" width="350" height="350" />
    <rect x="800" y="0" rx="2" ry="2" width="350" height="350" />
    <rect x="0" y="425" rx="2" ry="2" width="350" height="350" />
    <rect x="400" y="425" rx="2" ry="2" width="350" height="350" />
    <rect x="800" y="425" rx="2" ry="2" width="350" height="350" />
  </ContentLoader>
);

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});

export default connect(mapStateToProps)(All);
