import React, { useState } from "react";

class ProgressModal extends React.Component {
    render() {
      const { fileteredReports, closeModal } = this.props;
      return (
        <div className="progress-modal">
          <div className="progress-modal-content">
            <h2>Report Generation Progress</h2>
            <ul className="report-list">
              {fileteredReports.map((report, index) => (
                <li key={index} className="report-item">
                  <span className="report-name">{report.displayName}</span>
                  <span className={`report-status ${report.status === 'done' ? 'completed' : 'in-progress'}`}>
                    {report.status === 'done' ? '✓ Completed' : 'In Progress...'}
                  </span>
                </li>
              ))}
            </ul>
            {fileteredReports.every(report => report.status === 'done') && (
              <button onClick={closeModal} className="close-modal">Close</button>
            )}
          </div>
        </div>
      );
    }
  }
  

  export default ProgressModal;
  