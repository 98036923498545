import React from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class ImageLabels extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Labels
          </Col>
          <Col xs="8">
            <Row>
              <Col xs="9" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SCORE
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3">
            {(this.props.data.results?.recommendation?.results?.reco_pos?.trim()) &&
              <FontAwesomeIcon
                className="info-icon"
                icon="info-circle"
                id="image-label-tooltip"
              ></FontAwesomeIcon>}
            <ReactTooltip
              anchorId="image-label-tooltip"
              place="bottom"
              variant="info"
            >{this.props.data.results?.recommendation?.results?.reco_pos}
            </ReactTooltip>
          </Col>
          <Col xs="8" className="py-3 dark-background">
            {this.props.data.results?.result?.results?.map(function (label) {
              return (
                <Row>
                  <Col xs="9">{label.label}</Col>
                  <Col xs="3">{Math.round(label.score * 100)}%</Col>
                </Row>
              );
            })}
            {this.props.data.results?.results?.map(function (label) {
              return (
                <Row>
                  <Col xs="9">{label.label}</Col>
                  <Col xs="3">{Math.round(label.score * 100)}%</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageLabels;
